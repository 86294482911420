import moment from 'moment'

export const statesHashMap = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
}

export type States = keyof typeof statesHashMap

export const STATES = Object.keys(statesHashMap)

export const STATE_TIMEZONES: Record<States, number> = {
  Alabama: -6,
  Alaska: -9,
  'American Samoa': 0,
  Arizona: -7,
  Arkansas: -6,
  California: -8,
  Colorado: -7,
  Connecticut: -5,
  Delaware: -5,
  'District Of Columbia': -5,
  'Federated States Of Micronesia': 0,
  Florida: -5,
  Georgia: -5,
  Guam: 0,
  Hawaii: -10,
  Idaho: -7,
  Illinois: -6,
  Indiana: -5,
  Iowa: -6,
  Kansas: -7,
  Kentucky: -6,
  Louisiana: -6,
  Maine: -5,
  'Marshall Islands': 0,
  Maryland: -5,
  Massachusetts: -5,
  Michigan: -6,
  Minnesota: -6,
  Mississippi: -6,
  Missouri: -6,
  Montana: -7,
  Nebraska: -6,
  Nevada: -8,
  'New Hampshire': -5,
  'New Jersey': -5,
  'New Mexico': -7,
  'New York': -5,
  'North Carolina': -5,
  'North Dakota': -6,
  'Northern Mariana Islands': 0,
  Ohio: -5,
  Oklahoma: -6,
  Oregon: -8,
  Palau: 0,
  Pennsylvania: -5,
  'Puerto Rico': 0,
  'Rhode Island': -5,
  'South Carolina': -5,
  'South Dakota': -5,
  Tennessee: -5,
  Texas: -6,
  Utah: -7,
  Vermont: -5,
  'Virgin Islands': -5,
  Virginia: -5,
  Washington: -8,
  'West Virginia': -5,
  Wisconsin: -6,
  Wyoming: -7,
}

export function getAbbreviatureFromState(stateFullName?: string): string {
  if (!stateFullName) return ''
  const stateAbbrev = statesHashMap[stateFullName as States] || ''
  return stateAbbrev
}

export function getStateTimeZone(state: States) {
  const tz = STATE_TIMEZONES[state]
  return moment().isDST() ? tz - 1 : tz
}
