import { get, post } from '../helpers/caller'

const domain = 'plaid'
export const plaid = {
  createLinkToken(highlightRountingNumber?: string, bankId?: string) {
    return get(domain, plaid.createLinkToken, {
      highlightRountingNumber,
      bankId,
    })
  },
  exchangePublicToken(publicToken: string) {
    return post(domain, plaid.exchangePublicToken, { publicToken })
  },
  searchInstitution(search: string) {
    return get(domain, plaid.searchInstitution, { search })
  },
  healthCheck(bankId: string) {
    return post(domain, plaid.healthCheck, { bankId })
  },
}
