import { Instance, types } from 'mobx-state-tree'

export enum RoleStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export const Role = types.model('Role', {
  name: '',
  rules: types.map(types.string),
})

export const UserRoleSettings = types.model('UserRoleSettings', {
  paymentEmailNotificationsEnabled: types.optional(types.boolean, true),
})

export const UserRole = types.model('UserRole', {
  sub: '',
  role: types.optional(
    types.union(
      types.literal('Owner'),
      types.literal('User'),
      types.literal('Admin'),
    ),
    'Owner',
  ),
  company_id: '',
  status: types.optional(
    types.enumeration<RoleStatus>(Object.values(RoleStatus)),
    RoleStatus.Active,
  ),
  settings: types.optional(UserRoleSettings, () => UserRoleSettings.create()),
})

export interface IRoleModel extends Instance<typeof Role> {}

export interface IUserRoleModel extends Instance<typeof UserRole> {}
