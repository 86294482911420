import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { useTheme } from 'react-native-paper'

export function useThemeStyle(style) {
  const theme = useTheme()
  return useMemo(
    () => StyleSheet.create(typeof style === 'function' ? style(theme) : style),
    [style, theme],
  )
}
