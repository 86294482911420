import { get, post } from '../helpers/caller'

export const finicity = {
  domain: 'finicity',

  createConnectLink(data: any) {
    return post(finicity.domain, finicity.createConnectLink, data)
  },

  getLinks() {
    return get(finicity.domain, finicity.getLinks)
  },

  syncAccounts() {
    return get(finicity.domain, finicity.syncAccounts)
  },

  getTransactions() {
    return get(finicity.domain, finicity.getTransactions)
  },

  removeAccount(accountId: string) {
    return get(finicity.domain, finicity.removeAccount, { accountId })
  },

  processWebhook(data: any) {
    return post(finicity.domain, finicity.processWebhook, data)
  },
}
