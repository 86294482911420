import { delete_, get, post } from '../helpers/caller'

export const projects = {
  domain: 'projects',
  list(params: any) {
    return get(projects.domain, projects.list, { ...params })
  },
  create(project: any, previousProjectId: string) {
    return post(projects.domain, projects.create, {
      project,
      previousProjectId,
    })
  },
  add(invoiceId: string, projectId: string, previousProjectId: string) {
    return post(projects.domain, projects.add, {
      invoiceId,
      projectId,
      previousProjectId,
    })
  },
  match(invoice: {
    address: string
    unitNumber: string
    city: string
    state: string
    zip: string
  }) {
    return get(projects.domain, projects.match, invoice)
  },
  deleteProject(projectId: string) {
    return delete_(projects.domain, projects.deleteProject, { projectId })
  },
}
