import React from 'react'
import { createEditable } from '../helpers'
import { appConverter } from '../helpers/converter'

export interface editablePropTypes {
  label: string
  model: any
  name: string
  inputMask?: string | boolean
  inputLength?: number
  converter?: typeof appConverter
}

export const withEditableConverter = <TProps,>(
  Component: React.FC<TProps>,
  changePropertyName = 'onChangeText',
) => {
  const Input = createEditable({ Component, changePropertyName })

  return React.forwardRef((props: editablePropTypes & TProps, ref) => {
    return <Input converter={appConverter} {...props} ref={ref} />
  })
}
