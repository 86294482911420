//  file with event names for Google Analytics
export const event_names = {
  forgot_password: 'forgot_password',
  signIn_with_phone: 'signIn_with_phone',
  signIn_wih_email: 'signIn_wih_email',
  add_invoice: 'add_invoice',
  add_invoice_to_batch_upload: 'add_invoice_to_batch_upload',
  submit_supplier_application: 'submit_supplier_application',
  submit_credit_application: 'submit_credit_application',
}
