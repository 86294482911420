import React from 'react'
import FontAwesome from 'react-native-vector-icons/MaterialCommunityIcons'
import { useTheme } from 'react-native-paper'

export const CloseCircle = ({
  value,
  disabled,
  onChangeText,
  ...props
}: {
  value?: string
  disabled?: boolean
  onChangeText?: (val: string) => void
}) => {
  const {
    colors: { primary },
  } = useTheme()
  return !!value && !disabled ? (
    <FontAwesome
      name="close-circle"
      size={20}
      style={{ marginRight: 10 }}
      onPress={() => onChangeText?.('')}
      color={primary}
      {...props}
    />
  ) : null
}
