export const findUs = [
  'Suppliers',
  'Contractors',
  'Online Search',
  'Manufacturer',
  'Articles',
  'Friends & Family',
  'Advertisement',
  'Podcast',
  'Trade Shows',
  'Referrals',
  'Associations',
  'Blog Post',
  'Other'
]

export const type = [
  'Sole Proprietorship',
  'LLC',
  'S Corporation',
  'C Corporation',
  'LLP',
  'Solo Member LLC',
  'Other'
]

export const companyTypesMap = {
  'Association/Estate/Trust': 'A',
  'Government (Federal/State/Local)': 'G',
  'Sole Proprietorship': 'S',
  'Financial Institution': 'F',
  'LLC': 'L',
  'Tax-Exempt Organization (501C)': 'E',
  'Partnership': 'P',
  'Private Corporation': 'V',
  'Public Corporation': 'B',
  'SEC-Registered Entity': 'R'
}

export const category = [
  'Material Supplier',
  'Online Retailer',
  'Manufacturer',
  'Regional Distributor',
  'Local Distributor',
  'Dealer',
  'Engineering Firm',
  'Architect Firm',
  'Interior Designer',
  'General Contractor / Builders',
  'GC / Sub-Contractor',
  'Sub-Contractor'
]


export const refundPolicyMap = {
  'No Refund': 'N',
  'Merchandise Exchange Only': 'E',
  '30 Days or Fewer': 'T',
  'Other': 'O',
}

export const refundPolicy = Object.keys(refundPolicyMap)

export const materialsOffer = [
  'Lumbers and Building Materials',
  'Windows and Doors',
  'Hardware and Fastening',
  'Millwork, Molding, Trim, Hardwood Floor',
  'Tile, Ceramic, Stone and Flooring',
  'Concrete',
  'Electrical Materials',
  'Plumbing Materials',
  'Sheetrock, Drywall, Plaster',
  'Fireplace',
  'Insulation',
  'Paint',
  'Metal and Steel',
  'Granite and Countertops',
  'Paver and Landscaping',
  'Roofing Materials',
  'Appliances',
  'Kitchen Cabinets and Vanities',
  'Closets',
  'Exterior Siding and Decking',
  'Masonry, Stone, and Brick',
  'Shade, Blinds, Rollers',
  'Tools and Machinery',
  'Other (comment box)'
]

export const saleVolumeMap = {
  'Less than $1M': '1000000',
  '$1M-$2M': '2000000',
  '$2M-$5M': '5000000',
  '$5M-$20M': '20000000',
  '$20M-$50M': '50000000',
  '$50M-$100M': '80000000',
  'More than $100M': '100000000'
}

export const saleVolume = Object.keys(saleVolumeMap)

export const orderVolumeMap = {
  'Less than $500': '500',
  '$501 to $2,000': '2000',
  '$2,001 to $5,000': '5000',
  '$5,001 to $10,000': '10000',
  '$10,001 to $30,000': '30000',
  'More than $30,000': '40000'
}

export const orderVolume = Object.keys(orderVolumeMap)

export const maxOrderVolumeMap = {
  'Less than $50,000': '50000',
  '$50,001 to $75,000': '75000',
  '$75,001 to $100,000': '100000',
  '$100,001 to $125,000': '125000',
  '$125,001 to $150,000': '150000',
  'More than $150,000': '200000'
}

export const maxOrderVolume = Object.keys(maxOrderVolumeMap)

export const titleOfOwner = [
  'CEO',
  'CFO',
  'COO',
  'Owner',
  'Partner',
  'Managing Partner',
  'Manager',
  'Accountant',
  'President',
  'Other',
]

export const mainCustomerBase = [
  'Only Contractors',
  'Mostly Contractors',
  'Both Contractors and Property Owners',
  'Mostly Property Owners',
  'Only Property Owners'
]
export const orderShipmentTime = ['1-2 days', '1 Week', '2 Weeks', '4 Weeks', '6 Weeks', 'More than 6 weeks']

export const paymentTerms = ['Cash & Credit Cards', 'Net 30', 'Net 45', 'Net 60', 'Other']

export const saleType = [
  'Only Direct Sales',
  'Mostly Direct Sales',
  'Both Direct Sales and Through Distributors',
  'Mostly Through Distributors',
  'Only Through Distributors'
]

export const software = [
  'Quickbooks',
  'Intuit',
  'Xero',
  'Sage',
  'Sage 300',
  'NetSuite',
  'FreshBooks',
  'SAP',
  'Wave'
]

export const accounting = [
  'Quickbooks',
  'Sage',
  'Sage (Intacct)',
  'NetSuite',
  'FreshBooks',
  'Wave',
  'Zoho Invoice',
  'ZipBooks',
  'SlickPie',
  'GnuCash',
  'CloudBooks',
  'TurboCASH',
  'Plot',
  'FreeAgent',
  'Bill.com',
  'Tipalti',
  'YayPay',
  'Other'
]

export const ecommerce = [
  'AmeriCommerce',
  'BigCommerce',
  'CoreCommerce',
  'CORESense',
  'Drupal Commerce',
  'Ecwid',
  'Engine Commerce',
  'Lightspeed eCommerce',
  'Magento',
  'Magento 2',
  'Magento Cloud',
  'Miva Merchant',
  'OpenCart',
  'osCommerce',
  'PrestaShop',
  'Salesforce Commerce Cloud',
  'Shopify',
  'Shopping Cart Elite',
  'SunShop',
  '3DCart',
  'Volusion',
  'Web Shop Manager',
  'WooCommerce',
  'Workarea',
  'Zoey',
  'Custom Platforms',
  'PHP',
  'ASP.NET',
  'Java',
  ' Python',
  'ASP Classic',
  'Other'
]
