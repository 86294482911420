import { SnapshotIn, types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'
import { validateUSPhoneNumber, validateEmail } from '../helpers/validations'

export const SupplierInvitationDetails = types.compose(
  types
    .model('SupplierInvitationDetails', {
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: types.optional(
        types.enumeration(['Material', 'Service', 'Both', '']),
        '',
      ),
      paymentMethodId: '',
      userId: '',
      role: '',
    })
    .views((self) => ({
      get validateEmail() {
        return validateEmail(self.email)
      },
      get validatePhone() {
        return validateUSPhoneNumber(self.phone)
      },
    }))
    .views((self) => ({
      get canProceed() {
        const phoneLength = self.phone.length
        const emailLength = self.email.length

        if (emailLength && phoneLength) {
          return (
            !!self.name &&
            !!validateEmail(self.email) &&
            !!validateUSPhoneNumber(self.phone)
          )
        }
        if (emailLength) {
          return !!self.name && !!validateEmail(self.email)
        }
        if (phoneLength) {
          return !!self.name && !!validateUSPhoneNumber(self.phone)
        }
        return !!self.name
      },
    })),
  editableModel(),
)

export interface ISupplierInvitationDetailsModel
  extends SnapshotIn<typeof SupplierInvitationDetails> {}
