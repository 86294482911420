export enum AutoCapitalize {
  Words = 'words',
  CHARACTERS = 'characters',
  SENTENCES = 'sentences',
  NONE = 'none',
}

export enum KeyboardType {
  PhonePad = 'phone-pad',
  EmailAddress = 'email-address',
  NumberPad = 'number-pad',
  Numeric = 'numeric',
  Default = 'default',
}
