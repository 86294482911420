import { get, post } from '../helpers/caller'

export const customer = {
  domain: 'customer',
  save(account: any) {
    return post(customer.domain, customer.save, account)
  },
  getForInvoiceSignup(id: string) {
    return get(customer.domain, customer.getForInvoiceSignup, { id })
  },
}
