import { CustomerAccount } from './CustomerAccount'
import { SnapshotIn, types } from 'mobx-state-tree'
import { Invoice } from './Invoice'

export const Project = types.model('Project', {
  _id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  company_id: types.maybeNull(types.string),
  customer_account_id: types.maybeNull(types.string),
  customer: types.maybeNull(CustomerAccount),
  contractValue: types.maybeNull(types.union(types.string, types.number)),
  address: types.frozen(),
  notes: types.maybeNull(types.string),
  isDeactivated: types.maybeNull(types.boolean),
  projectInvoices: types.array(Invoice.named('Invoice')),
  updatedAt: types.maybeNull(types.string),
  lastActivityDate: types.maybeNull(types.string),
  data: types.frozen(),
  connector: types.maybeNull(types.frozen()),
  invoices: types.maybeNull(types.array(types.string)),
})

export interface IProjectModel extends SnapshotIn<typeof Project> {}

export type ResponseType = {
  result: string
  data: IProjectModel
}
