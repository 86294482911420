import { useEffect, useRef } from 'react'

export function useDebouncedCallback(passedFunction, delay) {
  // track args & timeout handle between calls
  const argsRef = useRef<any>()
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>()

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, [])

  return function debouncedCallback(...args) {
    // capture latest args
    argsRef.current = args

    // clear debounce timer
    cleanup()

    // start waiting again
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        passedFunction(...argsRef.current)
      }
    }, delay)
  }
}
