import { SnapshotIn, types } from 'mobx-state-tree'
import { Address } from './Address'

const UserSettings = types.model('user.settings', {
  ip: types.maybeNull(types.string),
  ip_based_city: types.maybeNull(types.string),
  ip_based_state: types.maybeNull(types.string),
  ua: types.maybeNull(types.string),
  conversion: types.frozen(),
  opt_for_marketing_messages: types.maybeNull(types.boolean),
})

export const User = types
  .model('User', {
    sub: '',
    name: types.maybeNull(types.string),
    login: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    addresses: types.array(Address),
    lastName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    locale: types.maybeNull(types.string),
    nickname: types.maybeNull(types.string),
    picture: types.maybeNull(types.string),
    updated_at: types.maybeNull(types.string),
    settings: types.maybeNull(UserSettings),
  })
  .views((self) => ({
    /**
     * For Builder, to check if user has enter name and email.
     * @returns {*}
     */
    get hasCompletedSignUp() {
      return !!self.firstName && !!self.lastName && !!self.email
    },
  }))

export interface IUserModel extends SnapshotIn<typeof User> {}
