export const colors = {
  primary: {
    accent: '#DD4A2C',
    dark: '#2F4858',
    light: '#FFFFFF',
  },
  secondary: {
    dark: '#407899',
    light: '#68B4DC',
  },
  tertiary: {
    dark: '#5F788A',
    light: '#9BAEBC',
  },
  accent: {
    primary: '#EB9928',
    secondary: '#00A0F3',
    warning: '#FE8134',
  },
}

export const commonColors = {
  primary: colors.primary.dark,
  accent: colors.primary.accent,
  logo: colors.primary.dark,
  logoAccent: colors.primary.accent,
  surface: colors.primary.light,
  text: colors.primary.dark,
  warning: colors.accent.primary,
  success: '#00B396',
  supplierPrimary: '#4FAF9A',
  background: '#F2F2F2',
  disabled: '#E9EDEE',
  backdrop: '#303030',
  highlighted: '#d0dcec',
  borderBottomColor: '#F7F7F7',
  placeholder: '#94A3AB',
  textInactive: 'rgba(47,77,88, 0.7)',
  accentText: colors.accent.secondary,
  borderLight: colors.tertiary.light,
  secondaryDark: colors.secondary.dark,
  secondaryLight: colors.secondary.light,
  tertiaryDark: colors.tertiary.dark,
  tertiaryLight: colors.tertiary.light,
  tertiaryLighter: '#C4C4C4',
  backgroundColor: 'rgba(238,242,245,1)',
  textLight: '#668598',
  backgroundLight: '#F5F7F8',
  textDark: '#335C75',
  borderColor: '#E5E5E5',
  white: 'white',
  darkWarning: colors.accent.warning,
  statusActive: '#23BD86',
  statusActiveCredit: '#00A0F3',
  statusOther: '#3E4756',
  danger: '#D93400',
  unchecked: '#000000',
}
