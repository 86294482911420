import './icons'
import { AppRegistry } from 'react-native'
import App from '@linqpal/app/src/App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactGA from 'react-ga4'

Sentry.init({
  dsn: 'https://545a15507a8c484c8a3624e8eec2fff2@o782591.ingest.sentry.io/5798384',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_LINQPAL_MODE || 'dev',
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_LINQPAL_MODE === 'prod',
})

const app = Sentry.withProfiler(App)

AppRegistry.registerComponent('linqpal', () => app)
AppRegistry.runApplication('linqpal', {
  rootTag: document.getElementById('root'),
})

// Google Analytic Initialization start
ReactGA.initialize(process.env.REACT_APP_GA_KEY || 'G-LXXFMTX21C')
ReactGA.send(window.location.pathname + window.location.search)
// Google Analytic Initialization end
const pushState = window.history.pushState
const replaceState = window.history.replaceState

window.history.pushState = function (...args) {
  pushState.apply(window.history, args)
  window.dispatchEvent(new Event('locationchange'))
}

window.history.replaceState = function (...args) {
  replaceState.apply(window.history, args)
  window.dispatchEvent(new Event('locationchange'))
}

window.addEventListener('popstate', function () {
  window.dispatchEvent(new Event('locationchange'))
})

window.addEventListener('locationchange', function () {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.href,
  })
})
