import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputAndroidProps,
  TextInputKeyPressEventData,
} from 'react-native'
import { useBaseInputStyle } from '../ions/style'
import { InputText as AtomicInputText } from './InputText'
import {
  useFocusedErrorProps,
  useInputValidateInput,
  withEditableConverter,
  useInputValidateInputOnBlur,
  useAppTheme,
} from '../hooks'
import { InputContainer, InputOuterContainer } from './InputContainer'
import { CloseCircle } from './CloseCircle'
import { composeStyle } from '../helpers'
import RequiredLabel from './RequiredLabel'
import { removeEmojiRegex } from '../supplier/dictionaries/regEx'
import {
  TextInputMaskOptionProp,
  TextInputMaskTypeProp,
} from 'react-native-masked-text'

export interface AppInputBaseProps {
  style?: any
  error?: string | boolean
  onFocus?: () => void
  onBlur?: () => void
  siblingProps?: unknown
  onChangeText?: (val: string) => void
  toRemoveRegex?: string | RegExp
  highlight?: boolean
  mandatory?: boolean
  upperCaseLabel?: boolean
  onClearText?: () => void
  autoCompleteType?: TextInputAndroidProps['autoComplete']
  borderCustomColor?: string
  testID?: string
  showRequiredAlert?: unknown
  requiredLabelStyle?: any
  value?: string
  label?: string
  validateInput?: (a: string) => boolean | string
  validateInputOnBlur?: boolean
  showClearIcon?: boolean
  editable?: boolean
  disabled?: boolean
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  placeholder?: string
  maskType?: TextInputMaskTypeProp
  maskOptions?: TextInputMaskOptionProp
  maxLength?: number
  nativeProps?: any
  inputMask?: string | boolean
  innerBorderStyle?: any
  secureTextEntry?: boolean
  sibling?: React.ReactNode
}

function getLabelText(
  label: string | undefined,
  upperCaseLabel: boolean | undefined,
  focused: boolean,
  value: string | undefined,
  mandatory: boolean | undefined,
) {
  const labelCased = upperCaseLabel ? label?.toUpperCase() : label
  return `${focused || value ? labelCased : label}${(mandatory && '*') || ''}`
}

/**
 *
 * @param props
 * @param props.style
 * @param props.inputMask
 * @param props.maskType
 * @param props.maskOptions
 * @param props.nativeProps
 * @param props.error
 * @param { boolean } props.error - This will be the initial Error value overridden by validateInput function.
 * @returns {JSX.Element}
 * @constructor
 */
export const AppInputBase = forwardRef<unknown, AppInputBaseProps>(
  (
    {
      style,
      error: initialError,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
      siblingProps,
      onChangeText,
      toRemoveRegex,
      highlight,
      mandatory,
      upperCaseLabel,
      onClearText,
      autoCompleteType,
      borderCustomColor,
      testID,
      showRequiredAlert,
      requiredLabelStyle,
      ...props
    },
    ref,
  ) => {
    const theme = useAppTheme()
    const {
      colors: { accentText, accent },
    } = theme
    const { inputBackgroundColor } = useBaseInputStyle()
    const { onBlur, onFocus, focused } = useFocusedErrorProps()
    const [error, setError] = React.useState(initialError)

    const {
      value,
      label,
      validateInput,
      validateInputOnBlur,
      showClearIcon = true,
    } = props

    const [blur, setBlur] = useState(false)

    const finalOnFocus = () => {
      onInputFocus && onInputFocus()
      onFocus()
    }

    const newOnBlur = useInputValidateInputOnBlur({
      validateInput,
      validateInputOnBlur,
      onInputBlur,
      setError,
      toRemoveRegex,
    })
    const finalOnBlur = (e) => {
      newOnBlur(e.nativeEvent.text)
      onBlur()
      setBlur(true)
    }

    const newOnChangeText = useInputValidateInput({
      validateInput,
      validateInputOnBlur,
      onChangeText,
      setError,
      toRemoveRegex,
    })

    const valid = !!value && (!validateInput || !validateInput(value))
    useEffect(() => {
      blur && newOnChangeText && newOnChangeText(value || '')
      // todo fix lint error
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blur, valid])

    useEffect(() => {
      error && setBlur(true)
    }, [error])

    const isShoudBeHighlited = useMemo(
      () => highlight && (!value || (validateInput && !!validateInput(value))),
      [highlight, value, validateInput],
    )

    const onChange = useCallback(
      (text) => {
        const textWithoutEmojis = text?.replace(removeEmojiRegex, '')
        newOnChangeText?.(textWithoutEmojis)
      },
      [newOnChangeText],
    )

    const labelText = getLabelText(
      label,
      upperCaseLabel,
      focused,
      value,
      mandatory,
    )

    return (
      <>
        <InputOuterContainer
          focused={focused}
          style={[style, isShoudBeHighlited ? { borderColor: accent } : {}]}
          error={error}
          borderCustomColor={borderCustomColor}
          testID={label}
        >
          <AtomicInputText
            underlineColor="transparent"
            labelActiveColor={accentText}
            onFocus={finalOnFocus}
            onBlur={finalOnBlur}
            inputOuterStyle={composeStyle(inputBackgroundColor, { flex: 1 })}
            theme={{ roundness: 4 }}
            dense
            sibling={
              (props.editable === undefined ||
                props.editable ||
                showClearIcon) &&
              showClearIcon && (
                <CloseCircle
                  value={value}
                  disabled={props.disabled}
                  onChangeText={() => {
                    if (onClearText) {
                      return onClearText()
                    }
                    newOnChangeText?.('')
                    newOnBlur('')
                  }}
                  testID={`${
                    testID || `input_${new Date().getTime()}`
                  }_close_circle`}
                  {...({} as any)}
                />
              )
            }
            InputContainer={InputContainer}
            error={error}
            {...props}
            label={labelText}
            ref={ref}
            onChangeText={onChange}
            autoCompleteType={autoCompleteType}
          />
        </InputOuterContainer>
        {isShoudBeHighlited ? (
          <RequiredLabel
            style={requiredLabelStyle ? requiredLabelStyle : styles.label}
          />
        ) : null}
      </>
    )
  },
)
const styles = StyleSheet.create({
  label: {
    fontSize: 12,
    lineHeight: 24,
    color: '#DA515E',
  },
  mandatorySign: { color: '#DA515E' },
})
export const AppInput = withEditableConverter(AppInputBase)
