import { useMemo } from 'react'

/**
 * Returns a new onChangeText.
 *
 * @param props
 * @param props.toRemoveRegex - Regex to remove
 * @param props.validateInput
 * @param props.onChangeText
 * @param props.setError
 * @param props.handleMaskedStringChange
 * @returns {(function(*=): void)|*}
 */

interface Params {
  validateInput?: (a: string) => boolean | string
  validateInputOnBlur?: boolean
  onChangeText?: (a: string) => void
  setError: (a: any) => void
  handleMaskedStringChange?: (a) => void
  toRemoveRegex?: RegExp | string
}

interface OnBlurParams extends Params {
  onInputBlur?: () => void
}

export const useInputValidateInput = ({
  validateInput,
  validateInputOnBlur = false,
  onChangeText,
  setError,
  handleMaskedStringChange,
  toRemoveRegex = '',
}: Params) =>
  useMemo(() => {
    const getUnFormattedValue = (value: string) =>
      (value || '').replaceAll(toRemoveRegex, '')
    if (validateInput) {
      return (value) => {
        const unFormattedValue = getUnFormattedValue(value)
        onChangeText && onChangeText(unFormattedValue)
        const errorString = validateInput(unFormattedValue)
        if (errorString) {
          !validateInputOnBlur && setError(errorString)
        } else {
          setError(false)
        }

        if (handleMaskedStringChange) handleMaskedStringChange(unFormattedValue)
      }
    } else {
      if (handleMaskedStringChange) {
        return (value) => {
          const unFormattedValue = getUnFormattedValue(value)
          onChangeText && onChangeText(unFormattedValue)
          handleMaskedStringChange(unFormattedValue)
        }
      }
    }
    return onChangeText
  }, [
    validateInput,
    onChangeText,
    setError,
    handleMaskedStringChange,
    toRemoveRegex,
    validateInputOnBlur,
  ])

export const useInputValidateInputOnBlur = ({
  validateInput,
  validateInputOnBlur = false,
  onInputBlur,
  setError,
  toRemoveRegex = '',
}: OnBlurParams) =>
  useMemo(() => {
    const getUnFormattedValue = (value) =>
      (value || '').replaceAll(toRemoveRegex, '')

    if (validateInput && validateInputOnBlur) {
      return (value) => {
        const unFormattedValue = value && getUnFormattedValue(value)
        onInputBlur && onInputBlur()
        const errorString = validateInput(unFormattedValue)
        if (errorString) {
          setError(errorString)
        } else {
          setError(false)
        }
      }
    } else {
      return () => onInputBlur && onInputBlur()
    }
  }, [validateInput, onInputBlur, setError, validateInputOnBlur, toRemoveRegex])
