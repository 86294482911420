import { isValidNumberForRegion } from 'libphonenumber-js'
import moment from 'moment'

// previous version caused browser freeze (Chrome, Edge) after specific length
// taken from https://stackoverflow.com/a/1373724
const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
const DIGITS_REGEX = /^\d+$/
const PHONE_REGEX = /^[+]?\d*$/
const ALPHANUMERIC_REGEX = /^[ a-zA-Z0-9]+$/
const PDF_REGEX = /\.[pdf]+$/
const SSN_REGEX = /^\d{9}$/
const ZIP_REGEX = /^\d{5}$/

export function validateEmail(email = '') {
  return !!email && EMAIL_REGEX.test(email)
}

export function validateDigitsOnly(input = '') {
  return input === '' || DIGITS_REGEX.test(input)
}

export function validateAlphanumericOnly(input = '') {
  return input === '' || ALPHANUMERIC_REGEX.test(input)
}

export function validatePhoneNumberInput(input = '') {
  return input === '' || PHONE_REGEX.test(input)
}

export function validateUSPhoneNumber(input = '') {
  return !!input && isValidNumberForRegion(input, 'US')
}

export function validateAndExtractAlphanumeric(input = '') {
  const match = input.match(/^[ a-zA-Z0-9]+/)
  return match && Array.isArray(match) ? match[0] : ''
}

export function validatePercentage(input = '') {
  const match = input.match(/^\d+/)
  if (match && Array.isArray(match) && parseInt(match[0].slice(0, 3)) <= 100) {
    return match[0].slice(0, 3)
  } else {
    return ''
  }
}

export function validateInvoiceNumber(input = '') {
  const match = input.match(/^[ a-zA-Z0-9/-]+/)
  return match && Array.isArray(match) ? match[0] : ''
}

export function validateAndExtractAlphabets(input = '') {
  const match = input.match(/^[ a-zA-Z]+/)
  return match && Array.isArray(match) ? match[0] : ''
}

export function validateZip(input = '') {
  return !!input && ZIP_REGEX.test(input)
}

export function validateSsn(input = '') {
  return !!input && SSN_REGEX.test(input)
}

export const dateFormat = {
  full: 'MM/DD/YYYY',
  monthAndYear: 'MM/YYYY',
  year: 'YYYY',
}

/**
 * Checks if date is valid.
 * @param date
 * @param format
 * @returns {boolean}
 */
export const checkIfValidDate = (
  date: Date | string,
  format = dateFormat.full,
) => {
  if (!date) return false
  const dateMoment = moment(date, format, true)
  return dateMoment.isValid()
}

export const checkIfDateIsInFuture = (
  date: Date | string,
  toCheckDate: moment.Moment | string = moment(),
  format = dateFormat.full,
  granularity: moment.unitOfTime.StartOf = 'day',
) => {
  return (
    checkIfValidDate(date, format) &&
    !moment(date, format, true).isBefore(
      typeof toCheckDate === 'string'
        ? moment(toCheckDate, format)
        : toCheckDate,
      granularity,
    )
  )
}

export const checkIfDateIsInPast = (
  date: Date | string,
  toCheckDate: moment.Moment | string = moment(),
  format = dateFormat.full,
  granularity: moment.unitOfTime.StartOf = 'day',
) => {
  return (
    checkIfValidDate(date, format) &&
    moment(date, format, true).isBefore(
      typeof toCheckDate === 'string'
        ? moment(toCheckDate, format)
        : toCheckDate,
      granularity,
    )
  )
}

/**
 * Checks if Date is valid and not in future.
 * @param date
 * @param minimumYear
 * @returns {boolean}
 */
export const validateDate = (date = '', minimumYear = 1850) => {
  if (!date) return false
  const dateMoment = moment(date, dateFormat.full, true)

  return (
    dateMoment.isValid() &&
    !dateMoment.isAfter(moment(), 'day') &&
    (!minimumYear || dateMoment.year() >= minimumYear)
  )
}

/**
 * Checks if Date is valid and month not in future.
 * @param date
 * @param minimumYear
 * @returns {boolean}
 */
export const validateMonthAndYear = (date = '', minimumYear = 1850) => {
  if (!date) return false
  const dateMoment = moment(date, dateFormat.monthAndYear, true)

  return (
    dateMoment.isValid() &&
    !dateMoment.isAfter(moment(), 'month') &&
    (!minimumYear || dateMoment.year() >= minimumYear)
  )
}

export const validYear = (year: number, minimumYear = 1850) => {
  const dateMoment = moment(year, dateFormat.year, true)
  return (
    dateMoment.isValid() &&
    !dateMoment.isAfter(moment(), 'year') &&
    (!minimumYear || dateMoment.year() >= minimumYear)
  )
}

export const toDeleteRegexPhoneNumberMask = /(\+1)|[\D]/g

export const hasPdfExtension = (file: string) => !!file && PDF_REGEX.test(file)

export const validateLetterAndWhiteSpace = (text: string) => {
  // for validation inputs (name, last name etc..)
  const regName = /^[A-Za-z\s]+$/
  if (!regName.test(text)) return false
  return true
}
