import {
  IGetAllRequest,
  IGetAllResponse,
  IGetRequest,
  IGetResponse,
  RouteBase,
} from '../types'
import { IDashboardResponse } from './types'

export class Admin extends RouteBase {
  domain = 'admin'

  dashboard() {
    return this.buildGetRoute<IDashboardResponse>(this.dashboard)
  }

  heartBeat(params: IGetAllRequest<any>) {
    return this.buildGetRoute<IGetAllResponse<any>>(this.heartBeat, params)
  }

  isPrequalified(data: IGetRequest) {
    return this.buildGetRoute<IGetResponse<{ prequalified: boolean }>>(
      this.isPrequalified,
      data,
    )
  }

  isLoanAppDuplicated(data: IGetRequest) {
    return this.buildGetRoute<IGetResponse<{ duplicated: boolean }>>(
      this.isLoanAppDuplicated,
      data,
    )
  }
}

export default new Admin()
