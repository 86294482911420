import { configureFonts } from '../helpers'

export const fonts = configureFonts({
  default: {
    boldest: {
      fontWeight: '900',
    },
    ultraBold: {
      fontWeight: '700',
    },
    bold: {
      fontWeight: '600',
    },
    medium: {
      fontWeight: '500',
    },
    regular: {
      fontWeight: '400',
    },
    light: {
      fontWeight: '300',
    },
    thin: {
      fontWeight: '100',
    },
  },
  get web() {
    return this.default
  },
})

export const fontSizes = {
  extraLarge: 38,
  larger: 32,
  large: 24,
  medium: 19,
  regular: 16,
  small: 13,
}

export const lineHeights = {
  extraSmall: 18,
  small: 21.79,
  regular: 24,
  large: 28,
  larger: 37.5,
  extraLarge: 42,
  largest: 52,
}
