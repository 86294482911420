import { get, post } from '../helpers/caller'

export const invitation = {
  domain: 'invitation',
  check(id: string) {
    return get(invitation.domain, invitation.check, { id })
  },
  invite(data: any) {
    return post(invitation.domain, invitation.invite, data)
  },
  inviteSupplier(data: any) {
    return post(invitation.domain, invitation.inviteSupplier, data)
  },
  inviteContractor(data: any) {
    return post(invitation.domain, invitation.inviteContractor, data)
  },
  accept(data: any) {
    return post(invitation.domain, invitation.accept, data)
  },
  reject(id: string) {
    return post(invitation.domain, invitation.reject, { id })
  },
}
