import { get, post } from '../helpers/caller'

export const quickBooks = {
  domain: 'quickBooks',

  authorize() {
    return get(quickBooks.domain, quickBooks.authorize)
  },

  connect(code: string, realmId: string) {
    return get(quickBooks.domain, quickBooks.connect, { code, realmId })
  },

  disconnect() {
    return get(quickBooks.domain, quickBooks.disconnect)
  },

  isConnected() {
    return get(quickBooks.domain, quickBooks.isConnected)
  },

  shouldShowImportDoneMessage() {
    return get(quickBooks.domain, quickBooks.shouldShowImportDoneMessage)
  },

  switchCustomerImport(customerId: string, enabled: boolean) {
    return post(quickBooks.domain, quickBooks.switchCustomerImport, {
      customerId,
      enabled,
    })
  },
}
