import { Instance, types } from 'mobx-state-tree'
import { frequency, LOAN_PLAN_TYPE } from '../dictionaries'
import { PaymentDelayCode } from '../dictionaries/factoring'
import { PricingProduct } from '../dictionaries/pricingProduct'

export const LoanPaymentPlan = types.model('LoanPaymentPlan', {
  name: types.optional(types.string, ''),
  lmsTemplateId: '',
  days: 0,
  fee: 0,
  type: types.optional(
    types.enumeration(Object.values(LOAN_PLAN_TYPE)),
    LOAN_PLAN_TYPE.REGULAR,
  ),
  frequency: types.optional(
    types.enumeration(Object.values(frequency)),
    frequency.WEEKLY,
  ),
  term: 0,
  firstPaymentDelayDays: 0,
  product: types.optional(
    types.enumeration<PricingProduct>(Object.values(PricingProduct)),
    PricingProduct.LineOfCredit,
  ),
  paymentDelayCode: types.optional(
    types.enumeration(Object.values(PaymentDelayCode)),
    PaymentDelayCode.TD30,
  ),
})

export interface ILoanPaymentPlanModel
  extends Instance<typeof LoanPaymentPlan> {}
