import { RouteBase } from '../types'
import { loanPricingPackagesResponse } from './types'

export class Company extends RouteBase {
  domain = 'company'

  loanPricingPackages() {
    return this.buildGetRoute<loanPricingPackagesResponse>(
      this.loanPricingPackages,
    )
  }

  allPlans() {
    return this.buildGetRoute(this.allPlans)
  }

  getPlans(id: string, totalAmount: number) {
    return this.buildGetRoute(this.getPlans, { id, totalAmount })
  }
}

export default new Company()
