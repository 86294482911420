import React, { useEffect } from 'react'
import { View } from 'react-native'
import ExpandableInput from './ExpandableInput'
import RadioButton from './RadioButton'
import { Divider, Text } from 'react-native-paper'
import { TouchableOpacity } from 'react-native-web'
import { createEditable } from '../helpers'
import { commonColors } from '../theme'
import _ from 'lodash'
import CheckBox from './CheckBox'

const ExpandableSelect = ({
  multiSelect = false,
  valueKey = 'id',
  displayKey = 'name',
  options = [],
  value,
  onChange,
  extra,
  outerBoxStyle = {},
  testID = '',
  ...rest
}) => {
  const [selectedItems, setSelectedItems] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')
  const [opened, setOpened] = React.useState(false)
  useEffect(() => {
    if (!value) {
      setSelectedItems([])
    } else if (multiSelect) {
      setSelectedItems(_.isArray(value) ? value : [value])
    } else {
      setSelectedItems([value])
    }
  }, [value])

  React.useEffect(() => {
    let v = ''
    if (selectedItems.length > 0) {
      v = selectedItems
        .map((item) => {
          if (typeof options[0] === 'object') {
            const selectedOption = options.find(
              (option) => option[valueKey] === item,
            )
            if (selectedOption) {
              return selectedOption[displayKey]
            }
            return ''
          }
          return item
        })
        .join(', ')
    }
    setInputValue(v)
  }, [selectedItems])

  const onSelect = (option) => {
    if (multiSelect) {
      const value = typeof option === 'object' ? option[valueKey] : option
      const items = [...selectedItems]
      const index = items.indexOf(value)
      if (index > -1) {
        items.splice(index, 1)
      } else {
        items.push(value)
      }
      setSelectedItems(items)
      if (onChange) {
        onChange(items)
      }
    } else {
      const value = typeof option === 'object' ? option[valueKey] : option
      setSelectedItems([value])
      if (onChange) {
        onChange(value)
      }
      setOpened(false)
    }
  }

  return (
    <ExpandableInput
      value={inputValue}
      opened={opened}
      onExpanded={(expanded) => setOpened(expanded)}
      outerStyle={outerBoxStyle}
      {...rest}
    >
      <Divider style={{ marginTop: 10 }} />
      <View style={{ padding: 10 }} testID={testID}>
        {options.map((option) => {
          if (typeof option === 'object') {
            const itemIndex = selectedItems.findIndex(
              (item) => item[valueKey] === option[valueKey],
            )
            return multiSelect ? (
              <CheckBox
                key={option[valueKey]}
                checked={itemIndex > -1}
                label={option[displayKey]}
                focusable={opened}
                onPress={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onSelect(option)
                }}
              />
            ) : (
              <RadioButton
                key={option[valueKey]}
                checked={itemIndex > -1}
                focusable={opened}
                label={option[displayKey]}
                onPress={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onSelect(option)
                }}
              />
            )
          } else {
            const itemIndex = selectedItems.indexOf(option)
            return multiSelect ? (
              <CheckBox
                key={option}
                checked={itemIndex > -1}
                focusable={opened}
                label={option}
                onPress={(e) => {
                  e && e.preventDefault()
                  e && e.stopPropagation()
                  onSelect(option)
                }}
              />
            ) : (
              <RadioButton
                key={option}
                checked={itemIndex > -1}
                focusable={opened}
                label={option}
                onPress={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onSelect(option)
                }}
              />
            )
          }
        })}
        {extra}
      </View>

      {multiSelect && (
        <TouchableOpacity
          onPress={() => setOpened(false)}
          focusable={opened}
          style={{
            backgroundColor: commonColors.accentText,
            padding: 15,
            marginBottom: -10,
          }}
        >
          <Text
            style={{
              color: 'white',
              fontSize: 22,
              textAlign: 'center',
              width: '100%',
            }}
          >
            Done
          </Text>
        </TouchableOpacity>
      )}
      <View style={{ height: 10 }} />
    </ExpandableInput>
  )
}

export const Picker = createEditable({ Component: ExpandableSelect })
