import { types } from 'mobx-state-tree'
import moment from 'moment'

const Moment = types.custom({
  name: 'Moment',
  fromSnapshot(snapshot: string | Date) {
    return moment(snapshot)
  },
  toSnapshot(value: moment.Moment) {
    return ''.concat(value.format('YYYY-MM-DD HH:mm:ss.SSSZZ'))
  },
  isTargetType(value) {
    return moment.isMoment(value)
  },
  getValidationMessage(snapshot: string | Date) {
    return moment(snapshot).isValid()
      ? ''
      : `Value ${snapshot} provided seems not valid Moment instance`
  },
})
export default Moment

export const MomentDate = types.custom({
  name: 'MomentDate',
  fromSnapshot(snapshot: string | Date) {
    return moment(snapshot, 'YYYY-MM-DD')
  },
  toSnapshot(value: moment.Moment) {
    return ''.concat(value.format('YYYY-MM-DD'))
  },
  isTargetType(value) {
    return moment.isMoment(value)
  },
  getValidationMessage(snapshot: string | Date) {
    return moment(snapshot, 'YYYY-MM-DD').isValid()
      ? ''
      : `Value ${snapshot} provided seems not valid MomentDate instance`
  },
})
