import axios from 'axios'
import { getFnName, toKebabCase } from './utils'

export interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void
}

export type FunctionNaming = string | ((...args: any[]) => void)

function naming(domain: string, fn: FunctionNaming, version = 1) {
  const path = toKebabCase(getFnName(fn))
  return `/v${version}/${domain}/${path}`
}

/**
 * @param domain {string}
 * @param fn {Function}
 * @param [params] {*}
 * @param silent
 * @param [version] {number}
 */
export function get<T = any>(
  domain: string,
  fn: FunctionNaming,
  params = {},
  silent = false,
  version = 1,
) {
  const { token, cancel } = axios.CancelToken.source()
  const request: CancellablePromise<T> = axios.get<any, T>(
    naming(domain, fn, version),
    { params: { ...params, silent }, cancelToken: token },
  )
  request.cancel = cancel
  return request
}

/**
 * @param domain {string}
 * @param fn {Function}
 * @param data
 * @param [params] {*}
 * @param silent
 * @param [version] {number}
 */
export function put<T = any>(
  domain: string,
  fn: FunctionNaming,
  data = {},
  params = {},
  silent = false,
  version = 1,
) {
  const { token, cancel } = axios.CancelToken.source()
  const request: CancellablePromise<T> = axios.put<any, T>(
    naming(domain, fn, version),
    data,
    { params: { ...params, silent }, cancelToken: token },
  )
  request.cancel = cancel
  return request
}
/**
 * @param domain {string}
 * @param fn {Function}
 * @param data
 * @param [params] {*}
 * @param silent
 * @param [version] {number}
 */
export function patch<T = any>(
  domain: string,
  fn: FunctionNaming,
  data = {},
  params = {},
  silent = false,
  version = 1,
) {
  const { token, cancel } = axios.CancelToken.source()
  const request: CancellablePromise<T> = axios.patch<any, T>(
    naming(domain, fn, version),
    data,
    { params: { ...params, silent }, cancelToken: token },
  )
  request.cancel = cancel
  return request
}

/**
 * @param domain {string}
 * @param fn {Function}
 * @param data {*}
 * @param [params] {*}
 * @param silent
 * @param [version] {number}
 */
export function post<T = any>(
  domain: string,
  fn: FunctionNaming,
  data = {},
  params = {},
  silent = false,
  version = 1,
) {
  const { token, cancel } = axios.CancelToken.source()
  const request: CancellablePromise<T> = axios.post<any, T>(
    naming(domain, fn, version),
    data,
    { params: { ...params, silent }, cancelToken: token },
  )
  request.cancel = cancel
  return request
}

/**
 * @param domain {string}
 * @param fn {Function}
 * @param [params] {*}
 * @param silent
 * @param [version] {number}
 */
export function delete_<T = any>(
  domain: string,
  fn: FunctionNaming,
  params = {},
  silent = false,
  version = 1,
) {
  const { token, cancel } = axios.CancelToken.source()
  const request: CancellablePromise<T> = axios.delete<any, T>(
    naming(domain, fn, version),
    { params: { ...params, silent }, cancelToken: token },
  )
  request.cancel = cancel
  return request
}
