import { Instance, types } from 'mobx-state-tree'
import moment from 'moment'
import { validators } from '../..'
import { PROPERTY_OWNER_TYPE, statesHashMap } from '../dictionaries'
import { editableModel } from '../helpers/editable'

const {
  validateUSPhoneNumber,
  validateEmail,
  checkIfDateIsInFuture,
  checkIfDateIsInPast,
  checkIfValidDate,
  validateZip,
} = validators

export const AddressModel = types.optional(
  types.model('Address', {
    address: '',
    city: '',
    state: '',
    zip: '',
  }),
  {},
)

export const TimePeriodModel = types.compose(
  types
    .model('TimePeriod', {
      firstDayOnJob: types.optional(types.string, ''),
      expectedLastDayOnJob: types.optional(types.string, ''),
    })
    .actions(() => ({
      isValid(date: string) {
        return !!date && checkIfValidDate(date, 'MM/DD/YYYY')
      },
    }))
    .views((self) => ({
      get firstFilled() {
        const validFirst = self.isValid(self.firstDayOnJob)
        const validLast = self.isValid(self.expectedLastDayOnJob)
        if (validFirst && validLast)
          return (
            checkIfDateIsInPast(
              self.firstDayOnJob,
              self.expectedLastDayOnJob,
              'MM/DD/YYYY',
            ) || self.firstDayOnJob === self.expectedLastDayOnJob
          )
        else return validFirst
      },
      get lastFilled() {
        const validLast = self.isValid(self.expectedLastDayOnJob)
        const validFirst = self.isValid(self.firstDayOnJob)
        if (validLast && validFirst) {
          return (
            (checkIfDateIsInFuture(
              self.expectedLastDayOnJob,
              self.firstDayOnJob,
              'MM/DD/YYYY',
            ) ||
              self.firstDayOnJob === self.expectedLastDayOnJob) &&
            checkIfDateIsInFuture(
              self.expectedLastDayOnJob,
              moment().format('MM/DD/YYYY'),
              'MM/DD/YYYY',
            )
          )
        } else return validLast
      },
      get filled() {
        return this.firstFilled && this.lastFilled
      },
    })),
  editableModel(),
)
export interface ITimePeriod extends Instance<typeof TimePeriodModel> {}

export const ContractorModel = types.compose(
  types
    .model('Contractor', {
      businessName: types.optional(types.string, ''),
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      phone: types.optional(types.string, ''),
      email: types.optional(types.string, ''),
      businessAddress: AddressModel,
    })
    .views((self) => ({
      get addressFilled() {
        const { address, city, state, zip } = self.businessAddress
        return (
          !!address.trim() &&
          !!city.trim() &&
          !!state.trim() &&
          validateZip(zip)
        )
      },
      get emailFilled() {
        return validateEmail(self.email)
      },
      get phoneFilled() {
        return validateUSPhoneNumber(self.phone)
      },
      get filled() {
        return (
          !!self.businessName.trim() &&
          !!self.firstName.trim() &&
          !!self.lastName.trim() &&
          this.emailFilled &&
          this.phoneFilled &&
          this.addressFilled
        )
      },
    })),
  editableModel(),
)
export interface IContractor extends Instance<typeof ContractorModel> {}

export const FullAddressModel = types
  .compose(
    types
      .model({
        streetAddress: types.optional(types.string, ''),
        unitNum: types.optional(types.string, ''),
        state: types.optional(types.string, ''),
        city: types.optional(types.string, ''),
        zip: types.optional(types.string, ''),
        houseNum: types.optional(types.string, ''),
        subdivision: types.optional(types.string, ''),
        lotNum: types.optional(types.string, ''),
        blockNum: types.optional(types.string, ''),
      })
      .views((self) => ({
        get filled() {
          return (
            !!self.streetAddress.trim() &&
            !!self.state.trim() &&
            !!Object.keys(statesHashMap).find(
              (state) =>
                state.toLowerCase() === self.state.trim().toLowerCase(),
            ) &&
            validateZip(self.zip) &&
            !!self.city.trim()
          )
        },
      })),
    editableModel(),
  )
  .named('FullAddress')

export const PropertyOwnerModel = types
  .model('PropertyOwner', {
    id: types.identifier,
    type: types.optional(types.string, PROPERTY_OWNER_TYPE.INDIVIDUAL),
    businessName: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    address: AddressModel,
  })
  .views((self) => ({
    get addressFilled() {
      const { address, city, state, zip } = self.address
      return (
        !!address.trim() && !!city.trim() && !!state.trim() && validateZip(zip)
      )
    },
    get filled() {
      if (self.type === PROPERTY_OWNER_TYPE.BUSINESS)
        return (
          !!self.businessName &&
          !!self.firstName &&
          !!self.lastName &&
          validateUSPhoneNumber(self.phone) &&
          this.addressFilled
        )
      else if (self.type === PROPERTY_OWNER_TYPE.INDIVIDUAL)
        return (
          !!self.firstName &&
          !!self.lastName &&
          validateUSPhoneNumber(self.phone) &&
          this.addressFilled
        )
      else return false
    },
  }))

export interface IPropertyOwner extends Instance<typeof PropertyOwnerModel> {}

export interface IModel {
  setValue: (arg1: string) => void
  setModelValue: (arg1: string, arg2: string) => void
  setMultipleValue: (arg1: string, arg2: string, arg3: string) => void
}

export interface IQuestionInfo {
  identifier: string
  title: string
  subtitle?: string
  component: React.FC<{
    model: IModel
    value: string | ITimePeriod | IContractor
  }>
  Model?: typeof TimePeriodModel | typeof ContractorModel
  shouldProcessAnswer?: boolean
  multipleAnswers?: boolean
  optional?: boolean
}
