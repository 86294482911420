import { getParent, types, castToSnapshot, destroy } from 'mobx-state-tree'
import { ErrorCode, LogicalError } from '@linqpal/models/src/types/exceptions'

const errorTypes = ['info', 'warn', 'error', 'success']
export type ErrorType = typeof errorTypes[number]
export const errorHolder = (
  errorTargets: string[] = [],
  fading = true,
  timeout = 5000,
) => {
  type Targets = typeof errorTargets[number]
  let handler
  const Message = types
    .model({
      target: types.identifier,
      type: types.enumeration(errorTypes),
      message: types.frozen(),
    })
    .actions((self) => {
      return {
        afterCreate() {
          if (fading) handler = setTimeout(this.remove, timeout)
        },
        beforeDestroy() {
          clearTimeout(handler)
        },
        remove() {
          try {
            getParent<any>(getParent(self)).removeError(self.target)
            destroy(self)
          } catch (e) {
            castToSnapshot(self)
          }
        },
      }
    })
  return types
    .model({
      errors: types.map(Message),
    })
    .actions((self) => ({
      setError(target: Targets, type: ErrorType, message: ErrorCode) {
        if (errorTargets.includes(target)) {
          self.errors.set(target, Message.create({ target, type, message }))
        } else {
          throw new LogicalError(
            `Declare error targets afterCreate "${target}"`,
          )
        }
      },
      getError(target: Targets) {
        return self.errors.get(target)
      },
      removeError(target: Targets) {
        self.errors.delete(target)
      },
    }))
}
