import { types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'

// TODO: Move to another file for reuse ?
const BillingAddress = types.model('BillingAddress', {
  streetAddress: '',
  addressLine: '',
  state: '',
  zipCode: '',
})

// TODO: maybe optional type ? and ID ?
export const CreditDebitCard = types.compose(
  types.model('CreditDebitCard', {
    _id: '',
    cardType: types.maybeNull(types.enumeration(['creditCard', 'debitCard'])),
    // Such as visa, mastercard, american express
    type: '',
    number: '',
    billingAddress: types.maybeNull(
      types.compose(BillingAddress, editableModel()),
    ),
    expiration: '',
    csc: '',
  }),
  editableModel(),
)
