import { types, Instance } from 'mobx-state-tree'

const configType = types.map(
  types.union(
    types.string,
    types.integer,
    types.number,
    types.null,
    types.array(types.string),
  ),
)
export const RemoteConfig = types
  .model({
    map: types.optional(configType, {}),
  })
  .actions((self) => ({
    setMap(map: Instance<typeof configType>) {
      self.map = map
    },
    get(key: string) {
      return self.map.get(key)
    },
    checkIfRoutingNumberIsBlocked(routiungNumber: string) {
      if (!routiungNumber) {
        return false
      }

      const blockedRoutingNumbers =
        (self.map.get('blocked_routing_numbers') as string[]) || []
      return blockedRoutingNumbers.includes(routiungNumber)
    },
  }))
