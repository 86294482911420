export const invoiceSchemaStatus = {
  cancelled: 'CANCELLED',
  placed: 'PLACED',
  dismissed: 'DISMISSED',
  draft: 'DRAFT',
  paid: 'PAID',
  expired: 'EXPIRED', // for now introduced for quotes only
  // quote statuses
  authorizationInReview: 'AUTHORIZATIONINREVIEW',
  authorized: 'AUTHORIZED',
  invoiced: 'INVOICED',
  rejected: 'REJECTED',
} as const

export type InvoiceSchemaStatusType =
  typeof invoiceSchemaStatus[keyof typeof invoiceSchemaStatus]

export const invoiceStatus = {
  ...invoiceSchemaStatus,
  due: 'DUE',
  refunded: 'REFUNDED',
  disputed: 'DISPUTED',
  pastDue: 'PASTDUE',
  paymentPosted: 'PAYMENTPOSTED',
  paymentError: 'PAYMENTERROR',
  paymentFailed: 'PAYMENTFAILED',
  requested: 'REQUESTED',
  paymentProcessing: 'PAYMENTPROCESSING',
  seen: 'SEEN',
  applicationProcessing: 'APPLICATIONPROCESSING',
  pendingDisbursement: 'PENDINGDISBURSEMENT',
  applicationRejected: 'APPLICATIONREJECTED',
  applicationCancelled: 'APPLICATIONCANCELLED',
  collected: 'COLLECTED',
  creditApplied: 'CREDITAPPLIED',
}

export const statusBadge = {
  [invoiceStatus.placed]: {
    label: 'Placed',
    textColor: '#33B3F5',
    bgColor: '#F4F9FD',
  },
  [invoiceStatus.seen]: {
    label: 'Seen',
    textColor: '#33B3F5',
    bgColor: '#F4F9FD',
  },
  [invoiceStatus.dismissed]: {
    label: 'Dismissed',
    textColor: '#8367C7',
    bgColor: 'rgba(131, 103, 199, 0.1)',
  },
  [invoiceStatus.due]: {
    label: 'Due',
    textColor: '#33B3F5',
    bgColor: '#F4F9FD',
  },
  [invoiceStatus.paid]: {
    label: 'Paid',
    textColor: '#25B883',
    bgColor: 'rgba(37, 184, 131, 0.1)',
  },
  [invoiceStatus.expired]: {
    label: 'Expired',
    textColor: '#668598',
    bgColor: 'rgba(230, 235, 238, 0.65)',
  },
  [invoiceStatus.draft]: {
    label: 'Draft',
    textColor: '#668598',
    bgColor: 'rgba(230, 235, 238, 0.65)',
  },
  [invoiceStatus.cancelled]: {
    label: 'Cancelled',
    textColor: '#F25A68',
    bgColor: 'rgba(242, 90, 104, 0.08)',
  }, // Color not specified in figma
  [invoiceStatus.rejected]: {
    label: 'Rejected',
    textColor: '#F25A68',
    bgColor: 'rgba(242, 90, 104, 0.08)',
  }, // Color not specified in figma
  [invoiceStatus.pastDue]: {
    label: 'Past Due',
    textColor: '#F6AE2D',
    bgColor: 'rgba(246, 174, 45, 0.1)',
  },
  [invoiceStatus.disputed]: {
    label: 'Disputed',
    textColor: '#F6AE2D',
    bgColor: 'rgba(246, 174, 45, 0.1)',
  },
  [invoiceStatus.requested]: {
    label: 'Requested',
    textColor: '#668598',
    bgColor: 'rgba(230, 235, 238, 0.65)',
  },
  [invoiceStatus.paymentPosted]: {
    label: 'Payment Processing',
    textColor: '#33B3F5',
    bgColor: '#F4F9FD',
  }, // Color not specified in figma
  [invoiceStatus.paymentProcessing]: {
    label: 'Payment Processing',
    textColor: '#33B3F5',
    bgColor: '#F4F9FD',
  },
  [invoiceStatus.creditApplied]: {
    label: 'Credit Applied',
    textColor: '#25B883',
    bgColor: 'rgba(37, 184, 131, 0.1)',
  },
  [invoiceStatus.paymentFailed]: {
    label: 'Failed',
    textColor: '#A93F49',
    bgColor: 'rgba(169, 63, 73, 0.08)',
  },
  [invoiceStatus.paymentError]: {
    label: 'Payment Error',
    textColor: '#A93F49',
    bgColor: 'rgba(169, 63, 73, 0.08)',
  },
  [invoiceStatus.applicationCancelled]: {
    label: 'Application Cancelled',
    textColor: '#F25A68',
    bgColor: 'rgba(242, 90, 104, 0.08)',
  },
  [invoiceStatus.applicationRejected]: {
    label: 'Application Rejected',
    textColor: '#F25A68',
    bgColor: 'rgba(242, 90, 104, 0.08)',
  },
  [invoiceStatus.applicationProcessing]: {
    label: 'Application Processing',
    textColor: '#D4792F',
    bgColor: 'rgba(212, 121, 47, 0.08)',
  },
  [invoiceStatus.authorizationInReview]: {
    label: 'Authorization in Review',
    textColor: '#D4792F',
    bgColor: 'rgba(212, 121, 47, 0.08)',
  },
  [invoiceStatus.pendingDisbursement]: {
    label: 'Pending Disbursement',
    textColor: '#D4792F',
    bgColor: 'rgba(212, 121, 47, 0.08)',
  },
  [invoiceStatus.refunded]: {
    label: 'Refunded',
    textColor: '#25B883',
    bgColor: 'rgba(37, 184, 131, 0.1)',
  },
  [invoiceStatus.collected]: {
    label: 'Collected',
    textColor: '#25B883',
    bgColor: 'rgba(37, 184, 131, 0.1)',
  },
  // quote statuses
  [invoiceStatus.authorized]: {
    label: 'Authorized',
    textColor: '#33B3F5',
    bgColor: '#F4F9FD',
  },
  [invoiceStatus.invoiced]: {
    label: 'Invoiced',
    textColor: '#25B883',
    bgColor: 'rgba(37, 184, 131, 0.1)',
  },
}

export enum InvoiceDecision {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}
