import React, { forwardRef } from 'react'
import {
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  TextInput as NativeInput,
  TextInputAndroidProps,
  TextInputKeyPressEventData,
} from 'react-native'
import { HelperText, TextInput } from 'react-native-paper'
import {
  TextInputMask,
  TextInputMaskOptionProp,
  TextInputMaskTypeProp,
} from 'react-native-masked-text'
import { useDetachPaddingMargin } from '../hooks'
import { useBaseInputStyle } from '../ions/style'
import { composeStyle, webSpecificStyle } from '../helpers'
import { commonColors } from '../theme'
import CurrencyInput from './CurrencyInput'

const BaseDummyContainer = ({ children }) => <> {children} </>

/**
 * @param props
 * @param props.maskOptions
 * @param props.alwaysKeepSpace - For making sure there is some space after the input for message, which prevents jagged animation.
 * @param props.innerBorderStyle - For Force setting the border style, which will be removed due to useDetachPaddingMargin.
 * @param props.inputOuterStyle - Style directly to NativeComponent.
 * @param props.innerBorderStyle - For Force setting the border style, which will be removed due to useDetachPaddingMargin.
 * @param props.sibling - Used for Icons -- Eye Icon in Password, etc.
 * @param props.InputContainer - Container for Input component and Sibling.
 * @param props.inputContainerProps - Props for Input Container.
 * @typedef {TextInput} inputTextElement
 */

type PropTypes = {
  style?: any
  disabled?: boolean
  sibling?: React.ReactNode
  editable?: boolean
  maskOptions?: TextInputMaskOptionProp
  nativeProps?: any
  inputMask?: string | boolean
  error?: string | boolean
  autoCompleteType?: TextInputAndroidProps['autoComplete']
  disabledStyle?: any
  inputContainerProps?: any
  maskType?: TextInputMaskTypeProp
  InputContainer?: React.FunctionComponent<any>
  inputOuterStyle?: any
  innerBorderStyle?: any
  alwaysKeepSpace?: boolean
  paddingBottom?: number
  onChangeText?: (val: string) => void
  underlineColor?: string
  onFocus?: () => void
  onBlur?: (e) => void
  focused?: boolean
  theme?: any
  dense?: boolean
  testID?: any
  label?: string
  labelActiveColor?: string
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  placeholder?: string
  value?: string
  keyboardType?: KeyboardTypeOptions
  secureTextEntry?: boolean
}

export const InputText = forwardRef(
  (
    {
      style = {},
      inputMask,
      maskType,
      maskOptions = {},
      nativeProps,
      inputOuterStyle,
      innerBorderStyle,
      alwaysKeepSpace,
      sibling,
      InputContainer = BaseDummyContainer,
      inputContainerProps,
      disabledStyle,
      autoCompleteType = 'off',
      ...props
    }: PropTypes,
    ref,
  ) => {
    const { input, inputError, inputDisabled } = useBaseInputStyle()
    const { accentText } = commonColors
    const { paddingsAndMargins, restOfStyle } = useDetachPaddingMargin(style)

    const innerStyle = composeStyle(
      input,
      webSpecificStyle({
        outline: 'none',
        caretColor: accentText,
      }),
      restOfStyle,
      props.error ? inputError : {},
      props.editable === false || props.disabled
        ? disabledStyle || inputDisabled
        : {},
      innerBorderStyle,
    )
    return (
      <>
        <TextInput
          render={(shadowProps) => {
            return (
              <InputContainer {...inputContainerProps}>
                {maskType === 'money' ? (
                  <CurrencyInput
                    underlineColorAndroid="transparent"
                    {...shadowProps}
                    style={innerStyle}
                    {...nativeProps}
                    ref={ref}
                    autoComplete={autoCompleteType}
                    autoCorrect={false}
                  />
                ) : inputMask || maskType ? (
                  <TextInputMask
                    underlineColorAndroid="transparent"
                    {...shadowProps}
                    type={maskType || 'custom'}
                    options={{ mask: inputMask, ...maskOptions }}
                    style={innerStyle}
                    {...nativeProps}
                    ref={ref}
                    refInput={ref}
                  />
                ) : (
                  <NativeInput
                    underlineColorAndroid="transparent"
                    {...shadowProps}
                    style={innerStyle}
                    {...nativeProps}
                    ref={ref}
                    autoComplete={autoCompleteType}
                    autoCorrect={false}
                  />
                )}
                {sibling}
              </InputContainer>
            )
          }}
          {...props}
          error={!!props.error}
          theme={{ colors: { primary: '#00A0F3' } }}
          style={composeStyle(
            {
              // backgroundColor: undefined,
              fontSize: style.fontSize,
              fontFamily: style.fontFamily,
              paddingBottom: props.error ? 0 : props.paddingBottom,
            },
            paddingsAndMargins,
            inputOuterStyle,
            props.error && inputError,
          )}
          autoComplete={autoCompleteType}
          autoCorrect={false}
        />
        {(props.error || alwaysKeepSpace) && (
          <HelperText
            type="error"
            style={{ marginTop: 0 }}
            visible={!!props.error || alwaysKeepSpace}
            testID={
              props.testID ? `${props.testID}_helper_text` : 'input_helper_text'
            }
          >
            {props.error || (alwaysKeepSpace ? ' ' : '')}
          </HelperText>
        )}
      </>
    )
  },
)
