import fb from 'firebase/compat/app'
import 'firebase/compat/auth'

try {
  const config = require('../../../firebase.config.json')
  fb.initializeApp(config)
} catch (e) {
  console.log(e)
}
fb.auth()
  .setPersistence(fb.auth.Auth.Persistence.NONE)
  .catch((e) => console.log(e))
export default fb
