import { Instance, types } from 'mobx-state-tree'

export const CardPricingPackage = types.model('CardPricingPackage', {
  name: '',
  title: '',
  color: types.optional(types.string, ''),
  description: '',
  tabapayMID: '',
  metadata: types.frozen(),
  deactivated: false,
})

export interface ICardPricingPackageModel
  extends Instance<typeof CardPricingPackage> {}
