import { Instance, types } from 'mobx-state-tree'

export const Address = types
  .model('Address', {
    address: '',
    city: '',
    state: '',
    zip: '',
  })
  .views((self) => ({
    get allFilled() {
      return (
        self.address.trim() &&
        self.city.trim() &&
        self.state.trim() &&
        self.zip.trim().length === 5
      )
    },
  }))

export type IAddressModel = Instance<typeof Address>
