import { types } from 'mobx-state-tree'
import { MESSAGE_TYPE } from '../../utils/helpers/MessageProvider'

export const MessageModel = types.model(
  {
    type: types.enumeration(Object.values(MESSAGE_TYPE)),
    message: ''
  })

export const MessageSupport = types.model({
  message: types.maybeNull(MessageModel)
}).actions((self => ({
  removeMessage () {
    self.message = null
  },
  setSuccessMessage (message) {
    self.message = MessageModel.create({
      type: MESSAGE_TYPE.SUCCESS,
      message
    })
  },
  setErrorMessage (error) {
    self.message = MessageModel.create({
      type: MESSAGE_TYPE.ERROR,
      message: error?.message || error
    })
  },
  showNotification (content, alertType) {
    self.message = MessageModel.create({
      type: alertType,
      message: content
    })
  }
})))
