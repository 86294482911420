import { Instance, types } from 'mobx-state-tree'
import {
  BankAccountPaymentMethodTypes,
  bankAccountPaymentMethodTypes,
  bankAccountStatuses,
  BankAccountStatuses,
  BankConnectionType,
  PlaidStatus,
} from '../dictionaries'
import { editableModel } from '../helpers/editable'
import { ClearModel } from '../helpers/types'

export const BankAccount = types
  .compose(
    types.model('BankAccount', {
      id: '',
      _id: '',
      name: '',
      voidedCheck: types.optional(types.union(types.string, types.null), ''),
      accountholderName: types.optional(
        types.union(types.string, types.null),
        '',
      ),
      routingNumber: '',
      accountNumber: types.optional(types.frozen(), ''),
      accountName: '',
      finicity: types.optional(types.frozen(), {}),
      plaid: types.optional(types.frozen(), {}),
      cardMetadata: types.maybeNull(types.frozen()),
      billingAddress: types.maybeNull(types.frozen()),
      paymentMethodType: types.optional(
        types.enumeration<BankAccountPaymentMethodTypes>([
          ...bankAccountPaymentMethodTypes,
        ]),
        'bank',
      ),
      accountType: types.optional(types.string, 'savings'),
      isPrimary: types.optional(types.boolean, false), // For get paid
      isPrimaryForCredit: types.optional(types.maybeNull(types.boolean), false), // For credit
      status: types.optional(
        types.enumeration<BankAccountStatuses>([...bankAccountStatuses]),
        'notverified',
      ),
      isManualEntry: types.optional(types.boolean, false),
      isDeactivated: types.optional(
        types.union(types.boolean, types.null),
        false,
      ),
    }),
    editableModel(),
  )
  .views((self) => ({
    get allFilled() {
      let isAllFilled =
        self.name.trim() &&
        self.routingNumber.trim() &&
        self.routingNumber.length === 9 &&
        self.accountNumber.trim() &&
        self.accountNumber.length >= 6

      if (self.isManualEntry) {
        isAllFilled = isAllFilled && self.accountholderName?.trim()
      }

      return !!isAllFilled
    },
  }))

export type IBankAccountModel = ClearModel<typeof BankAccount>

export const UIBankAccountModel = types
  .compose(
    BankAccount,
    types.model({
      connectionType: types.maybeNull(
        types.enumeration(Object.values(BankConnectionType)),
      ),
    }),
  )
  .views((self) => ({
    get isFilled() {
      return self.allFilled && !this.isReconnectionRequired
    },
    get isFinicityConnectedBank() {
      return (
        self.connectionType === BankConnectionType.FINICITY &&
        !self.isManualEntry
      )
    },
    get isExpiredPlaidBank() {
      return self.plaid?.status === PlaidStatus.EXPIRED
    },
    get isDisconnectedPlaidBank() {
      return self.plaid?.status === PlaidStatus.DISCONNECTED
    },
    get isReconnectionRequired() {
      return (
        this.isFinicityConnectedBank ||
        this.isDisconnectedPlaidBank ||
        this.isExpiredPlaidBank
      )
    },
  }))

export type IUIBankAccount = Instance<typeof UIBankAccountModel>
