export enum LoanStatusIds {
  Application = 1,
  Open = 2,
  Closed = 3,
  PaidOff = 4,
}

export enum LoanSubStatusIds {
  New = 1,
  Repaying = 9,
  PendingPayoff = 38,
  Active = 46,
  Settled = 16,
  PaidInFull = 45,
}

export enum PaymentTypeIds {
  Regular = 1,
  Payoff = 13,
}

export enum VirtualCardStatus {
  ACTIVE = 'ACTIVATED',
  EXPIRED = 'EXPIRED_CARD',
  TEMPORARY_BLOCKED_BY_CLIENT = 'TEMPORARY_BLOCKED_BY_CLIENT',
}

export const frequency = {
  SINGLE: 'single',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
}

export const LOAN_PLAN_TYPE = {
  REGULAR: 'regular',
  VIRTUAL_CARD: 'virtualcard',
  CUSTOM: 'custom',
  NO_SUPPLIER: 'nosupplier',
}
