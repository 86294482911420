export const PROJECT_SECTIONS = {
  ADDRESS: 'propertyAddress',
  INFORMATION: 'projectInformation',
  TYPE: 'projectType',
  PROPERTY_OWNER: 'propertyOwner',
} as const

export type ProjectSections =
  typeof PROJECT_SECTIONS[keyof typeof PROJECT_SECTIONS]

export const CATEGORIES = {
  CURRENT: 'current',
  PENDING: 'pending',
  FILLED: 'filled',
} as const
export type Categories = typeof CATEGORIES[keyof typeof CATEGORIES]

export const PROPERTY_OWNER_TYPE = {
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
}

export const PROJECT_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  FEDERAL: 'federal',
} as const

export type ProjectType = typeof PROJECT_TYPE[keyof typeof PROJECT_TYPE]

export const statesWithNoticeOfCommencement = [
  'florida',
  'ohio',
  'georgia',
  'north carolina',
  'south carolina',
]

export const ROLES = {
  PRIME_OR_GENERAL: 'Prime Contractor/General Contractor',
  SUBCONTRACTOR: 'Subcontractor',
  SUBCONTRACTOR_FOR_ANOTHER_SUBCONTRACTOR:
    'Subcontractor for another Subcontractor',
  MATERIAL_SUPPLIER: 'Material Supplier',
  PROPERTY_DEVELOPER: 'Property Developer',
  OWNER: 'Owner',
  TENANT: 'Tenant',
}
