import { types, flow, Instance } from 'mobx-state-tree'
import UserStore from './UserStore'
import ScreensStore from './ScreensStore'
import { Branding, routes2 } from '@linqpal/models'
import persistModel from './persistable'
import { errorHolder } from './errorholder'
import makeInspectable from 'mobx-devtools-mst'
import { initLocalization } from '../i18n'
import RouteParams from './types/RouteParams'
import { RemoteConfig } from './types/RemoteConfig'
import {
  IBrandingResponse,
  IRemoteConfigResponse,
} from '@linqpal/models/src/routes2/user/types'

const errorTargets = ['general']
const properties = {
  userStore: types.optional(UserStore, () => UserStore.create({})),
  screensStore: types.optional(ScreensStore, () => ScreensStore.create({})),
  routeParams: types.optional(RouteParams, () => RouteParams.create()),
  remoteConfig: types.optional(RemoteConfig, () => RemoteConfig.create()),
  branding: types.maybeNull(Branding),
}

function volatile() {
  return {
    busyCount: 0,
  }
}

function views(self) {
  return {
    get isBusy() {
      return self.busyCount > 0
    },
  }
}

function actions(self) {
  return {
    afterCreate() {
      self.initApp()
    },
    beforeDestroy() {},
    setBusy(state) {
      let count = self.busyCount
      if (!state) {
        count = count > 0 ? count - 1 : 0
      } else {
        count = count + 1
      }
      self.busyCount = count
    },
    async initApp() {
      await initLocalization()
      const remoteConfigRequest = routes2.user
        .remoteConfig()
        .then((data: IRemoteConfigResponse) =>
          self.setRemoteConfig(data.config),
        )
      const brandingRequest = routes2.user
        .branding()
        .then((data: IBrandingResponse) => self.setBranding(data.branding))
      Promise.all([remoteConfigRequest, brandingRequest])
        .catch((e) => console.log(e))
        .finally(() => {
          self.setReady(true)
        })
    },
    setBranding(value) {
      self.branding = value
    },
    setRemoteConfig(config) {
      self.remoteConfig.setMap(config)
    },
    logOut: flow(function* logOut() {
      try {
        yield self.userStore.logOut()
      } finally {
        self.screensStore = ScreensStore.create({})
        console.log('Logged out')
      }
    }),
    initRouteParams(params?) {
      if (RouteParams.is(params)) {
        self.routeParams = RouteParams.create(params)
      } else {
        self.routeParams = RouteParams.create()
      }
    },
  }
}

export const RootStoreModel = types
  .compose(
    types.model(properties).volatile(volatile).views(views).actions(actions),
    persistModel('BT_ROOT_STORE', false),
    errorHolder(errorTargets, true),
  )
  .named('RootStore')

export interface IRootStore extends Instance<typeof RootStoreModel> {}

const store = RootStoreModel.create()
makeInspectable(store)
export default store
