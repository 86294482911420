import {
  applySnapshot,
  flow,
  getType,
  onSnapshot,
  types,
} from 'mobx-state-tree'
import LocalStorage from './LocalStorage'

export default function persistModel(
  key,
  markAsReady = false,
  shouldRestore = true,
) {
  const actions = (self) => {
    let snapshotDispose

    return {
      afterCreate() {
        if (shouldRestore) {
          self.restorePersisted()
        }
      },
      beforeDestroy() {
        snapshotDispose && snapshotDispose()
      },
      setReady(ready) {
        self.isReady = ready
      },
      restorePersisted: flow(function* restorePersisted() {
        try {
          const snapshot = yield LocalStorage.get(key)
          if (snapshot) {
            if (getType(self).is(snapshot)) {
              applySnapshot(self, snapshot)
            }
          }
        } finally {
          snapshotDispose = onSnapshot(self, (snapshot) => {
            LocalStorage.set(key, snapshot)
          })
          if (markAsReady) {
            self.setReady(true)
          }
        }
      }),
    }
  }
  return types
    .model()
    .volatile(() => ({ isReady: false }))
    .actions(actions)
}
