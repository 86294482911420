export const MAIN_ID = {
  SUB_CONTRACTOR: 'subContractor',
  GENERAL_CONTRACTOR: 'generalContractor',
  DEALER_RETAILER_SUPPLIER: 'dealerRetailerSupplier',
  MANUFACTURER_DISTRIBUTOR: 'manufacturerDistributor',
  ARCHITECT_INTERIOR_DESIGNER: 'architectInteriorDesigner',
  ENGINEER_CONSULTANT: 'engineerConsultant',
  DEVELOPER_PROPERTY_OWNER: 'developerPropertyOwner',
  DEVELOPER_PROPERTY_OWNER_YES: 'developerPropertyOwneryes',
  DEVELOPER_PROPERTY_OWNER_NO: 'developerPropertyOwnerno',
  OTHER: 'other',
  OTHER_YES: 'otheryes',
  OTHER_NO: 'otherno',
}

export type OnboardingCategories = typeof MAIN_ID[keyof typeof MAIN_ID]

export const ID = {
  GET_PAID_FAST: 'getPaidFast',
  GET_PREQUALIFIED: 'getPrequalified',
  UPLOAD_INVOICE_SUP: 'uploadInvoiceSupplierContractor',
  UPLOAD_INVOICE_VENDORS: 'uploadInvoiceVendors',
  GET_PAID_FAST_ACH: 'getPaidFastACHCard',
}
