import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Text, TextInput, useTheme } from 'react-native-paper'
import FontAwesome from 'react-native-vector-icons/MaterialCommunityIcons'
import CollapsableView from './CollapsableView'
import { useBaseInputStyle } from '../ions/style'
import { InputOuterContainer } from './InputContainer'
import { composeStyle } from '../helpers'

export default ({
  label,
  value,
  disabled,
  children,
  opened = false,
  onExpanded,
  highlight,
  outerStyle = {},
  textInputStyle = {},
  chevronStyle = {},
  mandatory
}) => {
  const { input, inputBackgroundColor } = useBaseInputStyle()
  const { colors: { accentText, primary } } = useTheme()
  const [focused, setFocused] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    setExpanded(opened)
  }, [opened])

  React.useEffect(() => {
    if (onExpanded) {
      onExpanded(expanded)
    }
  }, [expanded])

  let lbl = (focused || value) ? label?.toUpperCase() : label
  lbl = mandatory ? lbl + ' *' : lbl

  return (
    <InputOuterContainer style={composeStyle(highlight && !value ? { borderColor: '#DD4A2C' } : {}, outerStyle)}>
      <TextInput
        underlineColor={'transparent'}
        labelActiveColor={accentText}
        onFocus={() => setFocused(true)}
        theme={{ roundness: 4 }}
        dense
        onBlur={(event, realBlur) => {
          if (!opened) {
            setFocused(false)
            setExpanded(false)
          }
        }}
        value={value}
        style={composeStyle(inputBackgroundColor, textInputStyle, { flex: 1 })}
        render={(shadowProps) => (
          <TouchableOpacity onPress={() => setExpanded(true)}
                            onBlur={(event) => shadowProps.onBlur(event, true)}
                            onFocus={shadowProps.onFocus}
                            disabled={disabled}>
            <View style={{ flexDirection: 'row', alignItems: 'center', minHeight: 52, marginTop: 3 }}>
              <Text style={input} onPress={() => setExpanded(!expanded)}>{value}</Text>
              <FontAwesome name={expanded ? 'chevron-up' : 'chevron-down'}
                           size={25}
                           color={primary}
                           style={{ marginRight: 10, marginTop: 10, ...chevronStyle }}
                           onPress={() => setExpanded(!expanded)}/>
            </View>
            <CollapsableView collapsed={!expanded}>
              {children}
            </CollapsableView>
          </TouchableOpacity>
        )}
        label={lbl}
      />
    </InputOuterContainer>
  )
}

