import { Platform, StyleSheet } from 'react-native'

export const composeStyle = (...args) => StyleSheet.flatten([args])

const sizes = ['xs', 'sm', 'md', 'lg', 'xl']

/** Combines styles in a cascading manner, with dimension taken into consideration.  */
export const composeResponsiveStyles = (style, styles, landscape, dimensions) =>
  sizes.reduce(
    (accumulatedStyle, size) =>
      composeStyle(
        accumulatedStyle,
        dimensions[size] ? styles?.[size] ?? {} : {},
        dimensions.landscape ? landscape?.[size] ?? {} : {},
      ),
    style,
  )

export const webSpecificStyle = (style) => Platform.select({ web: style })

/**
 * Converts to rrggbbaa hash format
 * @param color
 * @param opacity
 * @returns {string}
 */
export const colorWithOpacity = (color, opacity = 1) => {
  if (opacity === 1) {
    return color
  }

  const opacityString =
    opacity < 1 ? (opacity * 100).toString() : opacity.toString()

  return `${color}${opacityString}`
}
