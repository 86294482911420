import React from 'react'
import { Snackbar, useTheme } from 'react-native-paper'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { composeStyle } from '@linqpal/common-frontend/src/helpers'

export default ({ visible, onDismiss, message, type, wrapperStyle }) => {
  const { colors } = useTheme()
  let color = colors.accentText
  if (type === 'success') {
    color = colors.success
  } else if (type === 'warning') {
    color = colors.warning
  } else if (type === 'error') {
    color = colors.accent
  }
  if (!visible) return null
  return (
    <Snackbar
      visible
      onDismiss={onDismiss}
      wrapperStyle={composeStyle(
        {
          alignItems: 'flex-end',
          marginRight: 20,
          marginBottom: 74,
          zIndex: 9999,
        },
        wrapperStyle,
      )}
      style={{ backgroundColor: color }}
      duration={7000}
      action={{
        label: (
          <MaterialCommunityIcons
            size={25}
            color={'white'}
            name={'close-circle-outline'}
          />
        ),
        onPress: () => {
          if (onDismiss) {
            onDismiss()
          }
        },
      }}
      testID={message}
    >
      {message}
    </Snackbar>
  )
}
