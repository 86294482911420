import { Instance, types } from 'mobx-state-tree'

export const Branding = types.model('Branding', {
  company_id: '',
  name: '',
  subdomain: '',
  logoUrl: '',
  settings: types.optional(types.frozen(), {}),
})

export interface IBrandingModel extends Instance<typeof Branding> {}
