import {
  onSnapshot,
  types,
  getSnapshot,
  getType,
  IDisposer,
  IAnyStateTreeNode,
} from 'mobx-state-tree'
import { debounce } from 'lodash'

const createLogger = (rootScope: string, self: IAnyStateTreeNode) => ({
  log: (scope: string, ...args: any[]) =>
    console.log(
      `${rootScope}:${scope} /${getType(self).name} [${self.id}]`,
      ...args,
    ),
})

const storableSave = (
  self: any,
  saver: (d: any) => Promise<any>,
  logger: ReturnType<typeof createLogger>,
) =>
  debounce(
    async () => {
      const snapshot = getSnapshot(self)
      const ret = await saver(snapshot).catch((e) =>
        logger.log('storableSave:failed', e),
      )
      if (ret && ret._id && self._id !== ret._id) {
        self.setId(ret._id)
      }
    },
    1000,
    {
      leading: false,
      trailing: true,
    },
  )

export function createStorable(route: (data: any) => Promise<any>) {
  const actions = (self: any) => {
    let snapshotDisposer: IDisposer | undefined
    const logger = createLogger(`storable:${route}`, self)
    return {
      storableSave: storableSave(self, route, logger),
      afterCreate() {
        snapshotDisposer && snapshotDisposer()
        snapshotDisposer = onSnapshot(self, self.storableSave)
      },
      beforeDestroy() {
        snapshotDisposer && snapshotDisposer()
        snapshotDisposer = undefined
      },
      setId(id: string) {
        self._id = id
      },
    }
  }
  return types.model({ _id: '' }).actions(actions)
}
