import { RouteBase } from '../types'
import { IFixConnectionRequest, IFixConnectionResponse } from './types'

export class Finicity extends RouteBase {
  domain = 'finicity'

  fixConnection(data: IFixConnectionRequest) {
    return this.buildGetRoute<IFixConnectionResponse>(this.fixConnection, data)
  }
}

export default new Finicity()
