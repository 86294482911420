import { Instance, SnapshotIn, types } from 'mobx-state-tree'

enum Pulse {
  ACH = 'ACH',
  VISA = 'VISA',
  Amex = 'AMEX',
  BNPL = 'BNPL',
}

enum Step {
  First = 'first',
  Second = 'second',
}

enum Status {
  Pending = 'pending',
  Success = 'success',
  Failure = 'fail',
}

export {
  Pulse as HeartBeatPulse,
  Step as HeartBeatStep,
  Status as HeartBeatStatus,
}

export const HeartBeat = types
  .model({
    id: types.optional(types.string, ''),
    __v: types.optional(types.integer, 0),
    pulse: types.optional(
      types.enumeration<Pulse>(Object.values(Pulse)),
      Pulse.ACH,
    ),
    step: types.optional(
      types.enumeration<Step>(Object.values(Step)),
      Step.First,
    ),
    status: types.optional(
      types.enumeration<Status>(Object.values(Status)),
      Status.Pending,
    ),
    amount: 1,
    failureMessage: '',
    metadata: types.frozen<any>({}),
  })
  .named('HeartBeat')

export interface IHeartBeat extends Instance<typeof HeartBeat> {}
export interface SHeartBeat extends SnapshotIn<typeof HeartBeat> {
  createdAt: Date
}
