import { View } from 'react-native'
import React from 'react'
import { composeStyle } from '../helpers'
import { useBaseInputStyle } from '../ions/style'
import { commonColors } from '../theme'

export const InputContainer = (props) => (
  <View
    style={{ flexDirection: 'row', alignItems: 'center', height: '100%' }}
    {...props}
  />
)

interface InputOuterContainerProps {
  [x: string]: any
  focused?: boolean
  style: any
  error?: any
  borderCustomColor?: string
}

export const InputOuterContainer = ({
  focused = false,
  style,
  error,
  borderCustomColor,
  ...props
}: InputOuterContainerProps) => {
  const { inputBackgroundColor } = useBaseInputStyle()
  const { accentText, tertiaryLight, accent } = commonColors

  return (
    <View
      style={composeStyle(
        inputBackgroundColor,
        {
          borderColor: focused ? accentText : tertiaryLight,
          borderWidth: 1,
          borderRadius: 4,
          marginTop: 15,
          paddingBottom: 1,
        },
        style,
        error ? { borderColor: borderCustomColor || accent } : {},
      )}
      {...props}
    />
  )
}
