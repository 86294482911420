import { get, post } from '../helpers/caller'

export const file = {
  domain: 'file',
  upload(files: any) {
    return post(file.domain, file.upload, { files })
  },
  download(key: string) {
    return get(file.domain, file.download, { key })
  },
  recognize(key: string) {
    return get(file.domain, file.recognize, { key })
  },
  recresult(key: string) {
    return get(file.domain, file.recresult, { key })
  },
  uploadProjectDocument(filename: string, folder?: string) {
    return post(file.domain, file.uploadProjectDocument.name, {
      filename,
      folder,
    })
  },
}
