import { CancellablePromise } from './caller'

/**
 * Creates a wrapper for asynchronous functions, ensuring their sequential execution.
 * If a wrapped function is called while another is still executing, it will wait for the previous one to finish before starting.
 * This is useful when you need to serialize operations that might otherwise run concurrently,
 * such as network requests, file operations, or any tasks that should not overlap.
 *
 * For example, if you have multiple functions that write to the same file or modify shared data,
 * you can use this utility to prevent race conditions by executing them one after another.
 *
 * @returns A wrapper function that takes an asynchronous function and returns a new function with sequential execution.
 *
 * @example
 * // Create a sequential executor
 * const wrapFunction = createSequentialExecutor();
 *
 * // Wrap an asynchronous function
 * const saveData = wrapFunction(async function(data: any) {
 *     await writeFileAsync('data.txt', data);
 * });
 *
 * // When calling saveData multiple times, each call will wait for the previous one to complete
 * saveData('First write');
 * saveData('Second write');
 * saveData('Third write');
 *
 * // Even though saveData is called twice in quick succession, the writes will happen sequentially.
 */
export function createSequentialExecutor() {
  let pendingPromise: Promise<any> | null = null

  const waitForPendingPromiseIsEmpty = async (
    state: CancelState,
    fn: () => CancellablePromise<any>,
  ) => {
    while (pendingPromise) {
      try {
        if (state.isCancelled) break

        await pendingPromise
      } catch {}
    }

    const promise = fn()

    pendingPromise = promise.finally(() => {
      pendingPromise = null
    })

    if (state.isCancelled) {
      promise.cancel?.()
    }

    state.originalPromise = promise

    return promise
  }

  /**
   * Wraps an asynchronous function, guaranteeing that it will execute after the previous one has completed.
   *
   * @param fn - The asynchronous function to wrap.
   * @returns The wrapped function with sequential execution.
   */
  return function wrapFunction<
    T extends (...args: any[]) => CancellablePromise<any> | Promise<any>,
  >(fn: T): T {
    return function (...args: Parameters<T>): Promise<ReturnType<T>> {
      const state: CancelState = {
        isCancelled: false,
        originalPromise: null,
      }

      const promise = waitForPendingPromiseIsEmpty(state, () =>
        fn(...args),
      ) as CancellablePromise<any>

      promise.cancel = () => {
        state.isCancelled = true
        state.originalPromise?.cancel?.()
      }

      return promise
    } as T
  }
}

interface CancelState {
  isCancelled: boolean
  originalPromise: CancellablePromise<any> | null
}
