import React, { forwardRef, useMemo, useState } from 'react'
import { useTheme } from 'react-native-paper'
import FontAwesome from 'react-native-vector-icons/MaterialCommunityIcons'
import { useInputValidateInput, withEditableConverter } from '../hooks'
import { AppInputBase } from './AppInput'
import { CloseCircle } from './CloseCircle'

const Sibling: React.FC<{
  secureTextEntry: boolean
  setSecureTextEntry: (val: boolean) => void
  onChangeText?: (val: string) => void
  value?: string
  disabled?: boolean
  editable?: boolean
}> = ({
  secureTextEntry,
  setSecureTextEntry,
  onChangeText,
  value,
  disabled,
  editable = true,
}) => {
  const {
    colors: { primary },
  } = useTheme()

  return editable ? (
    <>
      <FontAwesome
        name={secureTextEntry ? 'eye' : 'eye-off'}
        size={20}
        style={{ marginRight: 10 }}
        onPress={() => setSecureTextEntry(!secureTextEntry)}
        color={primary}
      />
      <CloseCircle
        onChangeText={onChangeText}
        disabled={disabled}
        value={value}
      />
    </>
  ) : (
    <></>
  )
}

/**
 * @param props
 * @param props.style
 * @param props.inputMask
 * @param props.inputLength
 * @param props.maskType
 * @param props.maskOptions
 * @param props.nativeProps
 * @param props.initialError
 * @param { boolean } props.error - This will be the initial Error value overridden by validateInput function.
 * @returns {JSX.Element}
 * @constructor
 */
export const MaskedInputBase = forwardRef(
  (
    {
      style,
      inputMask,
      maskOptions,
      inputLength = 11,
      nativeProps,
      onChangeText,
      error: initialError,
      toRemoveRegex,
      ...props
    }: React.ComponentProps<typeof AppInputBase> & {
      inputLength?: number
    },
    ref,
  ) => {
    const [error, setError] = React.useState(initialError)
    const { value, validateInput } = props
    const [secureTextEntry, setSecureTextEntry] = useState(false)

    const [mask, setMask] = useState<string | boolean | undefined>(
      !value && inputMask ? inputMask : false,
    )

    const handleMaskedStringChange = useMemo(
      () => (newValue) => {
        if (newValue.includes('*')) {
          onChangeText?.(newValue.replace('*', ''))
          setMask(inputMask ?? false)
        }

        // Sometime when state reloads etc with value it might not mask properly.
        if (newValue && !mask) setMask(inputMask)
      },
      // todo fix lint issue
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChangeText, setMask, inputMask],
    )

    const newOnChangeText = useInputValidateInput({
      validateInput,
      onChangeText,
      setError,
      handleMaskedStringChange,
      toRemoveRegex,
    })

    const sibling = (
      <Sibling
        onChangeText={newOnChangeText}
        secureTextEntry={secureTextEntry}
        disabled={props.disabled}
        editable={props.editable || true}
        value={value}
        setSecureTextEntry={setSecureTextEntry}
      />
    )

    return (
      <AppInputBase
        maskOptions={maskOptions}
        secureTextEntry={secureTextEntry}
        sibling={sibling}
        onFocus={() => setSecureTextEntry(false)}
        onBlur={() => setSecureTextEntry(true)}
        error={error}
        inputMask={mask || new Array(inputLength).join('*')}
        ref={ref}
        mandatory={props.mandatory}
        {...props}
        onChangeText={newOnChangeText}
      />
    )
  },
)

export const MaskedInput = withEditableConverter(MaskedInputBase)
