import { useToggleState } from './useToggleState'
import { useEffect, useRef } from 'react'

/**
 * For Input Fields.
 * @param toListen
 * @returns {{onBlur: (function(): *), ref: React.MutableRefObject<any>, onFocus: (function(): *)}}
 */
export const useManualFocus = (...toListen) => {
  const [focused, onFocus, onBlur] = useToggleState()
  const inputRef = useRef<any>()

  useEffect(() => {
    if (focused && inputRef?.current?._inputElement?.focus) {
      inputRef.current._inputElement.focus()
    }
  }, [focused])

  useEffect(() => {
    if (
      toListen.some(
        (item) =>
          item !== null && item !== '' && inputRef?.current?._inputElement,
      )
    ) {
      if (focused && inputRef.current._inputElement.focus) {
        inputRef.current._inputElement.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toListen])

  return {
    ref: inputRef,
    onBlur,
    onFocus,
  }
}
