import { Instance, types } from 'mobx-state-tree'
import { Address } from './Address'
import { PurchaseTypes } from '../dictionaries'
import { CustomerAccountType } from '../dictionaries/customerAccountType'

// TODO: VK: Add held amount / company credit info to company model
export interface IHeldAmount {
  amount: number
  loanApplicationId: any // mongoose.ObjectId
}

export interface ICompanyCreditInfo {
  limit: number
  balance: number
  pastDueAmount: number
  processingAmount: number
  LoCnumber?: any // mongoose.ObjectId
  LoCnumCreatedAt?: Date
  purchaseType?: PurchaseTypes
  heldAmounts?: IHeldAmount[]
}

export enum CompanyStatus {
  NotStarted = 'notStarted',
  New = 'new',
  Applied = 'applied',
  Rejected = 'rejected',
  Approved = 'approved',
  Validated = 'validated',
}

export const Company = types.model('Company', {
  _id: '',
  id: '',
  sub: '',
  name: '',
  entity: '',
  legalName: '',
  website: '',
  address: types.maybeNull(Address),
  phone: '',
  email: '',
  license: '',
  ein: types.optional(types.frozen(), ''),
  year: '',
  draft: types.maybeNull(types.frozen()),
  isBusiness: types.maybeNull(types.boolean),
  status: types.optional(
    types.maybeNull(
      types.enumeration<CompanyStatus>(Object.values(CompanyStatus)),
    ),
    CompanyStatus.NotStarted,
  ),
  credit: types.optional(types.frozen(), {}),
  settings: types.optional(types.frozen(), {}),
})

export const SupplierInfo = types.compose(
  Company,
  types.model({
    customerType: types.optional(
      types.enumeration<CustomerAccountType>(
        Object.values(CustomerAccountType),
      ),
      CustomerAccountType.TradeCredit, // Default value or make it optional
    ),
  }),
)

export interface ICompanyModel extends Instance<typeof Company> {}
