import { Instance, types } from 'mobx-state-tree'
import { ProjectSections, PROJECT_SECTIONS } from '../dictionaries'

export const Question = types
  .model({
    identifier: types.string,
    title: types.optional(types.string, ''),
    content: types.optional(types.frozen(), {}),
    filled: false,
  })
  .actions((self) => ({
    setContent(val: any) {
      self.content = val
    },
    setTitle(val: any) {
      self.title = val
    },
    setFilled(val: any) {
      self.filled = val
    },
  }))
export interface IQuestion extends Instance<typeof Question> {}

const Section = types
  .model({
    group: types.string,
    items: types.array(Question),
  })
  .views((self) => ({
    filled: () => self.items.every((i) => i.filled),
  }))
  .actions((self) => ({
    setItems(val: any) {
      self.items = val
    },
  }))

export const Form = types
  .model('Form', {
    data: types.map(Section),
    currentSection: types.enumeration(Object.values(PROJECT_SECTIONS)),
  })
  .actions((self) => ({
    setCurrent(
      current: ProjectSections,
      initialQuestions: {
        identifier: string
        title?: string
        content: any
        filled: boolean
      }[],
    ) {
      self.currentSection = current
      const item = self.data.get(current)
      if (!item) {
        this.addSection(current, initialQuestions)
      }
    },
    resetSectionQuestions(list: IQuestion[], section: string) {
      const item = self.data.get(section)
      item?.items.replace(list)
    },
    addSection(
      section: string,
      list: {
        identifier: string
        title?: string
        content: any
        filled: boolean
      }[],
    ) {
      self.data.set(section, {
        group: section,
        items: [],
      })
      const item = self.data.get(section)
      list.forEach((l) => {
        item?.items.push(Question.create(l))
      })
    },
    setData(
      section: string,
      questionId: string,
      answer: any,
      filled: boolean,
      questionTitle?: string,
    ) {
      const item = self.data.get(section)
      if (item) {
        const q = item.items.find((i) => i.identifier === questionId)
        if (q) {
          q.setContent(answer)
          q.setTitle(questionTitle)
          q.setFilled(filled)
        } else {
          item.items.push(
            Question.create({
              identifier: questionId,
              title: questionTitle,
              content: answer,
              filled: filled,
            }),
          )
        }
      } else {
        self.data.set(section, {
          group: section,
          items: [
            Question.create({
              identifier: questionId,
              title: questionTitle,
              content: answer,
              filled: filled,
            }),
          ],
        })
      }
    },
  }))
