import AsyncStorage from '@react-native-community/async-storage'
import isEmpty from 'lodash/isEmpty'

const set = async (key, value) => {
  if (!key || key === 'null' || key === 'undefined') {
    console.warn('LocalStorage not set ', {key}, {value});
    return;
  }

  if (!value || (typeof value === 'object' && isEmpty(value))) {
    console.warn('LocalStorage not set ', {key}, {value});
    return
  }

  try {
    return await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log("Storage:set", e);
  }
  return null;
};

const get = async (key, defaultValue = null) => {
  try {
    let value = await AsyncStorage.getItem(key);
    if (value === undefined || value === null) return defaultValue;
    return JSON.parse(value);
  } catch (e) {
    console.log("Storage:get", e);
  }
  return null;
};

const remove = async key => {
  try {
    return await AsyncStorage.removeItem(key);
  } catch (e) {
    console.log("Storage:remove", e);
  }
  return false;
};

const clear = async () => {
  try {
    await AsyncStorage.clear();
  } catch (e) {
    console.log("Storage:clear", e);
  }
};

export default {
  set,
  get,
  remove,
  clear
};
