import { flow, types } from 'mobx-state-tree'
import { editableModel, routes } from '@linqpal/models'
import { MessageSupport } from '../types'
import { customerStatus } from '@linqpal/models/src/dictionaries'

const excelImportColumns = [
  'contact',
  'first_name',
  'last_name',
  'phone',
  'name',
  'email',
  'business_phone',
  'address',
]

const excelImportColumnsMapDefault = {
  0: 'contact',
  1: 'phone',
  2: 'name',
  3: 'email',
  4: 'business_phone',
  5: 'address',
  contact: '0',
  phone: '1',
  name: '2',
  email: '3',
  business_phone: '4',
  address: '5',
}

const excelColumn = types
  .model({ name: types.string, key: types.string })
  .named('excelColumn')

const properties = {
  id: types.optional(types.identifier, 'SupplierAccountsScreenStore'),
  excelImportVisible: false,
  excelImporting: false,
  excelImportStartIndex: types.maybeNull(
    types.union(types.string, types.number),
  ),
  excelImportData: types.array(types.array(types.string)),
  excelImportColumns: types.array(excelColumn),
  excelImportColumnsMap: types.optional(
    types.map(types.string),
    excelImportColumnsMapDefault,
  ),
  excelImportSkipDuplicates: false,
  excelImportErrorMessage: '',
  excelImportDataDuplicatePhoneNumbers: types.array(types.string),
  fileName: '',
  rows: '',
  columns: '',
}

function views(self) {
  return {
    get excelImportDataSliced() {
      const data = self.excelImportData
      const index = parseInt(self.excelImportStartIndex)
      return isFinite(index) && index > 1 && data.length >= index
        ? data.slice(index - 1)
        : data.slice()
    },
    get excelImportDataSorted() {
      const data = self.excelImportDataSliced
      const duplicates = self.excelImportDataDuplicatePhoneNumbers
      const phoneColKey = self.excelImportColumnsMap.get('phone')
      const skip = self.excelImportSkipDuplicates
      if (duplicates.length === 0 || data.length === 0 || !phoneColKey)
        return data
      if (skip)
        return data.filter(
          (item) => duplicates.indexOf(item[phoneColKey]) === -1,
        )
      return data.sort((a, b) => {
        const ia = duplicates.indexOf(a[phoneColKey])
        const ib = duplicates.indexOf(b[phoneColKey])
        return ia !== -1 && ib !== -1
          ? ia - ib
          : ia === -1 && ib === -1
          ? 0
          : ib
      })
    },
    get excelImportCanSubmit() {
      const cols = self.excelImportColumns
      const colsMap = self.excelImportColumnsMap
      return (
        !self.excelImporting &&
        cols.length > 1 &&
        self.excelImportDataSorted.length > 0 &&
        colsMap.has('phone') &&
        cols.some((col) => col.key === colsMap.get('phone')) &&
        colsMap.has('name') &&
        cols.some((col) => col.key === colsMap.get('name'))
      )
    },
  }
}

function mapExcelDataItem(value) {
  if (typeof value === 'string') return value.trim()
  if (typeof value === 'number' && isFinite(value)) return value.toString()
  return ''
}

function reduceExcelData(items, item) {
  item = item.map(mapExcelDataItem)
  return item.some(Boolean) ? [...items, item] : items
}

function actions(self) {
  return {
    resetExcelImport() {
      self.excelImportData.clear()
      self.excelImportColumns.clear()
      self.excelImportColumnsMap.replace(excelImportColumnsMapDefault)
      self.excelImportStartIndex = '1'
      self.excelImportSkipDuplicates = false
      self.excelImportErrorMessage = ''
      self.excelImportDataDuplicatePhoneNumbers.clear()
      self.fileName = ''
      self.rows = ''
      self.columns = ''
    },
    showExcelImport() {
      self.resetExcelImport()
      self.excelImportVisible = true
    },
    hideExcelImport() {
      self.excelImportVisible = false
      self.resetExcelImport()
    },
    setExcelImporting(value = false) {
      self.excelImporting = value
    },
    setFileDetails(fileName, rows, columns) {
      ;[self.fileName, self.rows, self.columns] = [fileName, rows, columns]
    },
    setExcelImportDataAndColumns(data, columns) {
      self.excelImportData.replace(data.reduce(reduceExcelData, []))
      self.excelImportColumns.replace(columns)
    },
    setExcelImportColumnsMap(key, value) {
      const map = self.excelImportColumnsMap
      const prevKey = map.get(value)
      const prevValue = map.get(key)
      if (key && value) {
        map.set(key, value)
        map.set(value, key)
      } else if (key) {
        map.delete(key)
      } else if (value) {
        map.delete(value)
      }
      if (prevKey && prevValue) {
        map.set(prevKey, prevValue)
        map.set(prevValue, prevKey)
      } else if (prevKey) {
        map.delete(prevKey)
      } else if (prevValue) {
        map.delete(prevValue)
      }
      if (value === 'contact') {
        if (map.has('first_name'))
          self.setExcelImportColumnsMap(map.get('first_name'), '')
        if (map.has('last_name'))
          self.setExcelImportColumnsMap(map.get('last_name'), '')
      } else if (value === 'first_name' || value === 'last_name') {
        if (map.has('contact'))
          self.setExcelImportColumnsMap(map.get('contact'), '')
      }
    },
    skipExcelImportDuplicatedPhoneNumbers() {
      self.excelImportSkipDuplicates = true
    },
    sendExcelImportData: flow(function* sendExcelImportData(invite = false) {
      if (self.excelImporting) return
      self.excelImporting = true
      try {
        const colsMap = self.excelImportColumnsMap
        const isFullName =
          colsMap.has('contact') &&
          self.excelImportColumns.length > parseInt(colsMap.get('contact'))
        const items = self.excelImportDataSorted.reduce((its, values) => {
          const item = excelImportColumns.reduce((i, col) => {
            if (colsMap.has(col)) {
              const key = parseInt(colsMap.get(col))
              const value = values.length > key ? values[key] : undefined
              if (value) i[col] = value
            }
            return i
          }, {})
          if (isFullName) {
            ;[item.first_name, item.last_name = ''] = item.contact.split(' ')
            delete item.contact
          }
          item.isImported = true
          item.status = invite ? customerStatus.invited : customerStatus.saved
          if (Object.keys(item).length > 0) its.push(item)
          return its
        }, [])
        yield routes.supplier.accountsImport(items, invite)
        self.setSuccessMessage('CSV file has been uploaded')
        self.hideExcelImport()
      } catch (e) {
        self.excelImportErrorMessage =
          e?.response?.data?.message ||
          e.data?.message ||
          e.message ||
          e.toString()
        self.excelImportDataDuplicatePhoneNumbers.replace(
          e?.response?.data?.duplicatePhoneNumbers || [],
        )
        self.setErrorMessage('CSV file could not be uploaded. Please try again')
        // throw e
      } finally {
        self.excelImporting = false
      }
    }),
  }
}

const SupplierAccountsScreenStore = types
  .model('SupplierAccountsScreenStore', properties)
  .views(views)
  .actions(actions)

export default types.compose(
  SupplierAccountsScreenStore,
  MessageSupport,
  editableModel(),
)
