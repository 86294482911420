import { flow, types } from 'mobx-state-tree'
import { UIBankAccountModel, routes, routes2 } from '@linqpal/models'
import {
  AccountType,
  BankAccountType,
  PAYMENT_METHOD_TYPE,
} from '@linqpal/models/src/dictionaries'
import partition from 'lodash/partition'
import { IFixConnectionResponse } from '@linqpal/models/src/routes2/finicity/types'

const properties = {
  id: types.optional(types.identifier, 'PaymentMethodsStore'),
  paymentMethods: types.optional(types.array(UIBankAccountModel), () => []),
  manuallyAddedBanks: types.array(UIBankAccountModel),
  onlineConnectedBanks: types.array(UIBankAccountModel), // via Finicity / Plaid
  cards: types.array(UIBankAccountModel),
  loading: types.optional(types.boolean, false),
}

function actions(self) {
  return {
    setLoading: function (val) {
      self.loading = val
    },
    fetchPaymentMethods: flow(function* () {
      self.setLoading(true)
      const resp = yield routes.company.bankAccounts()
      self.paymentMethods = resp?.bankAccounts
      const [cards, banks] = partition(resp?.bankAccounts, {
        paymentMethodType: PAYMENT_METHOD_TYPE.CARD,
      })
      self.cards = cards
      const [manual, online] = partition(banks, { isManualEntry: true })
      self.manuallyAddedBanks = manual
      self.onlineConnectedBanks = online
      self.setLoading(false)
    }),
    setPaymentMethod: flow(function* ({ action, ...rest }) {
      let response: any
      if (action === 'delete') {
        response = yield routes.company.removeBankAccount(rest.id)
      } else {
        response = yield routes.company.addBankAccount(rest)
      }
      if (response.result === 'ok') {
        yield self.fetchPaymentMethods()
      }
    }),
    fixConnection: flow(function* (accountId) {
      const resp: IFixConnectionResponse = yield routes2.finicity.fixConnection(
        { accountId },
      )
      console.log(resp)
      return resp
    }),
    getPaymentMethodSubtitle: function (t, acc) {
      if (acc.paymentMethodType === 'bank') {
        const accNum = acc.accountNumber?.slice(-3) || ''
        return `${
          acc.accountType === 'savings'
            ? t('PaymentMethodsListing.business-saving', { accNum })
            : t('PaymentMethodsListing.business-checking', { accNum })
        }`
      } else {
        const accNum = acc.accountNumber?.substring(9) || ''
        return `${
          acc.cardMetadata?.type === 'Debit'
            ? t('PaymentMethodsListing.debit-card', { accNum })
            : t('PaymentMethodsListing.credit-card', { accNum })
        }`
      }
    },
    getAccTypeAndNumber: function (
      accountType: AccountType,
      accountNumber: string,
      t,
    ) {
      return `${
        accountType === BankAccountType.SAVINGS
          ? t('Wallet.saving')
          : t('Wallet.checking')
      } ...${accountNumber.substring(accountNumber.length - 4)}`
    },
  }
}

function views(self) {
  return {
    get isBanksEmpty() {
      return !(
        self.onlineConnectedBanks.length || self.manuallyAddedBanks.length
      )
    },
    get cardsEmpty() {
      return !self.cards.length
    },
  }
}

export default types
  .model('PaymentMethodsStore', properties)
  .views(views)
  .actions(actions)
