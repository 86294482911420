import { Instance, types } from 'mobx-state-tree'
import { LOAN_APPLICATION_STATUS } from '../dictionaries'
import { LOAN_STATUS } from '../dictionaries/loanStatuses'
import { OwnerTypes } from '../dictionaries/UnifiedApplication'
import { IEncrypted } from '../dtos'
import { LOAN_APPLICATION_TYPE } from '../dictionaries/loanApplicationTypes'

export const InvoiceDetails = types.model('InvoiceDetails', {
  invoiceId: types.maybeNull(
    types.union(types.string, types.array(types.string)),
  ),
  paymentPlan: '',
  cardId: types.maybe(types.string),
})

const Progress = types.model('Progress', {
  step: '',
  error: '',
  action: '',
})

export interface IExperianBusinessData {
  owner: IOwnerHeader
  reliabilityCode?: number
  bankruptcyIndicator?: string | boolean
  judgmentBalance?: number
  lienBalance?: number
  accountBalanceDebt?: number
  tradelinesBalance?: number
  tradelinesPercentage?: number
  tradelinesDebt?: number
  yearsOnFile?: number
}

export interface IExperianOwnersData {
  owner: IOwnerHeader
  pastDueAmount?: number
  inquiriesDuringLast6Months?: number
  score?: string | number
  lastBankruptcyDate?: string
}

export interface IFraud {
  owner: IOwnerHeader
  fraudPoint?: {
    fraudPointScoreRiskLevel: string
  }
  emailAge?: {
    emailAgeEaScoreRiskLevel: string
    emailAgeIpRiskLevel?: string
    emailAgeDomainRiskLevel?: string
  }
}

export interface IOwnerHeader {
  firstName?: string
  lastName?: string
  businessName?: string
  isPrincipal?: boolean // true for the main business in loan application
  type: typeof OwnerTypes.INDIVIDUAL | typeof OwnerTypes.ENTITY
  key: string
}

export interface IKYC {
  owner: IOwnerHeader
  CVI?: string
  CRI?: string[]
  B2E?: string
}

export interface IKYB {
  owner: IOwnerHeader
  BVI?: string
  BRI?: string[]
}

export interface ISupplierScores {
  owner: IOwnerHeader
  fraud?: Omit<IFraud, 'owner'>
  kyb?: Omit<IKYB, 'owner'>
  kyc?: Omit<IKYC, 'owner'>
}

export interface ICreditStatus {
  businessData?: IExperianBusinessData[]
  ownersData?: IExperianOwnersData[]
}

export enum ScoringResult {
  Pass = 1,
  Review = 0,
  Reject = -1,
}

export interface IScore {
  name?: string
  score: any
  pass: ScoringResult
}

export interface IOwnerScore extends IScore {
  owner: IOwnerHeader
}

export interface ICoOwnerKnockoutScores {
  owner: IOwnerHeader
  scores: IScore[]
}

export interface IKnockoutScores {
  scores: IScore[]
  owners: ICoOwnerKnockoutScores[]
}

export interface OutputData {
  knockout?: IKnockoutScores | IScore[]
  decision?: {
    loan_revenue?: number
    annual_revenue?: number
    business_outstanding_balance?: number
    available_credit_limit?: number
    approved_amount?: number
    businessAge?: string
    loan_debt?: number
    debt_amount?: number
    debt_experian?: number
    total_acceptable_debt_amount?: number
    debt_adjustor?: number
    acceptable_debt_of_revenue?: number
    revenue_est_provided_in_app?: number
    debt_estimate_provided_in_app?: number
  }
  cashFlow?: {
    date?: string
    balance: number
    cashFlow: number
    credit: number
    debit: number
  }[]
  company_specific_approval_amount?: number
  business_outstanding_balance?: number
  loanApproved?: boolean
  // legacy Fraud
  fraudPoint?: {
    fraudPointScoreRiskLevel: string
  }
  emailAge?: {
    emailAgeEaScoreRiskLevel: string
    emailAgeIpRiskLevel?: string
    emailAgeDomainRiskLevel?: string
  }
  // legacy KYB
  BVI?: string
  BRI?: string[]
  CVI?: string
  CRI?: string[]
  BusinessToExecLinkIndex?: string
  // co-owners Fraud / KYB
  fraud?: IFraud[]
  KYB?: IKYB[]
  KYC?: IKYC[]
  // legacy creditScore
  reliabilityCode?: number
  bankruptcyIndicator?: string
  judgmentBalance?: number
  lienBalance?: number
  accountBalanceDebt?: number
  tradelinesBalance?: number
  tradelinesPercentage?: number
  tradelinesDebt?: number
  // co-owners creditStore
  businessData?: IExperianBusinessData[]
  ownersData?: IExperianOwnersData[]
  status?: string
  advanceAmount?: number
  discount?: number
  firstPaymentDate?: string
  transactionReference?: string
  einMatches?: any[]
  ssnMatches?: any[]
  rejected_applications_matching_ssn_ein?: number
  lastUpdated?: string | Date
  accounts?: any[]
  plaid?: {
    reportRequestId?: string
    error?: string
  }
  isInvoiceAssigned?: boolean
}

const Output = types.model('Output', {
  step: '',
  data: types.maybeNull(types.frozen<OutputData>()),
})

export const LoanAppNote = types
  .model({
    login: '',
    name: '',
    message: '',
  })
  .named('note')

export interface ILoanAppNote extends Instance<typeof LoanAppNote> {}

interface Address {
  city: string
  state: string
  zip: string
  address: string
}

interface BusinessName {
  legalName: string
  dba: string
}

export interface IOwnerInfo {
  id?: string
  type: string
  lastName: string
  firstName: string
  phone: string
  email: string
  ssn: string | IEncrypted
  address: string
  city: string
  state: string
  zip: string
  birthday: string
  entityName: string
  ein: string | IEncrypted
}

export interface ISubmitter {
  id: any
  firstName: string
  lastName: string
}

export interface IUnifiedApplicationDraft {
  normalized?: boolean
  businessInfo_businessName: BusinessName
  businessInfo_businessPhone: string
  businessInfo_businessAddress: Address
  businessInfo_ein?: string | IEncrypted
  businessInfo_startDate: string
  businessOwner_id?: string
  businessOwner_ssn?: string | IEncrypted
  businessOwner_address?: Address
  businessOwner_birthdate: string
  businessOwner_firstName: string
  businessOwner_lastName: string
  businessOwner_phone: string
  businessOwner_email: string
  finance_revenue: string
  finance_debt: string
  bank_details: {
    _id: string
    accountNumber: string
    isManualEntry: boolean
  }
  owners: IOwnerInfo[]
  data: any
}

export const LoanApplication = types.model('LoanApplication', {
  company_id: '',
  status: types.optional(
    types.enumeration(Object.values(LOAN_APPLICATION_STATUS)),
    LOAN_APPLICATION_STATUS.NEW,
  ),
  lmsLoanStatus: types.optional(
    types.enumeration(Object.values(LOAN_STATUS)),
    LOAN_STATUS.CREATED,
  ),
  type: types.maybe(types.enumeration(Object.values(LOAN_APPLICATION_TYPE))),
  isOverdue: types.maybe(types.boolean),
  approvedAmount: 0,
  usedAmount: types.maybe(types.number),
  stateMachineArn: '',
  executionArn: '',
  newDECreditApplicationExecutionArn: '',
  newDEDrawApprovalExecutionArn: '',
  invoiceDetails: types.optional(InvoiceDetails, () => InvoiceDetails.create()),
  isSentBack: types.optional(types.boolean, false),
  progress: types.optional(Progress, () => Progress.create()),
  lms_id: '',
  loanpro_id: 0,
  submitDate: types.maybe(types.Date),
  issueDate: types.maybe(types.Date),
  closeDate: types.maybe(types.Date),
  decisionDate: types.maybe(types.Date),
  nextPaymentAmount: types.maybe(types.number),
  nextPaymentDate: types.maybe(types.string),
  amountDue: 0,
  lastPaymentDate: types.maybe(types.string),
  remainingAmount: types.maybeNull(types.number),
  processingAmount: types.maybeNull(types.number),
  pastDueAmount: types.maybeNull(types.number),
  outputs: types.array(Output),
  draft: types.maybeNull(types.frozen<IUnifiedApplicationDraft>()),
  submitter: types.maybeNull(types.frozen<ISubmitter>()),
  notes: types.array(LoanAppNote),
  approvedBy: types.optional(types.string, 'unknown'),
  drawApprovalId: types.maybe(types.string),
  creditApplicationId: types.maybe(types.string),
})

export interface ILoanApplicationModel
  extends Instance<typeof LoanApplication> {}

export interface IOutputModel extends Instance<typeof Output> {}

export interface IProgressModel extends Instance<typeof Progress> {}

export interface IInvoiceDetailsModel extends Instance<typeof InvoiceDetails> {}
