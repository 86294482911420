import { types } from 'mobx-state-tree'
import Applications from './ApplicationsScreenStore'
import PaymentMethods from './PaymentMethodsStore'
import CreditDebitCardManagement from './CreditDebitCardManagementStore'
import SupplierAccounts from './SupplierAccountsScreenStore'
import TransactionStore from './TransactionStore'
import NotificationStore from './NotificationStore'
import RequiredActionStore from './RequiredActionStore'

const properties = {
  id: types.optional(types.identifier, 'ScreensStore'),
  applicationsStore: types.optional(Applications, () =>
    Applications.create({}),
  ),
  paymentMethodsStore: types.optional(PaymentMethods, () =>
    PaymentMethods.create({}),
  ),
  creditDebitCardManagementStore: types.optional(
    CreditDebitCardManagement,
    () => CreditDebitCardManagement.create({}),
  ),
  supplierAccountsStore: types.optional(SupplierAccounts, () =>
    SupplierAccounts.create({}),
  ),
  transactionStore: types.optional(TransactionStore, () =>
    TransactionStore.create({}),
  ),
  notificationStore: types.optional(NotificationStore, () =>
    NotificationStore.create({}),
  ),
  requiredActionStore: types.optional(RequiredActionStore, () =>
    RequiredActionStore.create({}),
  ),
  screenMenuTop: types.optional(types.boolean, false),
}

const actions = (self) => ({
  updateScreenMenuTop(value) {
    self.screenMenuTop = value
  },
})

export default types.model('ScreensStore', properties).actions(actions)
