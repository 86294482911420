import { dictionaries } from '@linqpal/models'
import { theme } from '@linqpal/common-frontend'
const { commonColors } = theme

export const transactionStatusOptions = [
  { value: '', label: 'All statuses' },
  {
    value: dictionaries.TRANSACTION_STATUS.SUCCESS,
    label: 'Success',
    color: commonColors.success,
  },
  {
    value: dictionaries.TRANSACTION_STATUS.PROCESSING,
    label: 'In Progress',
    color: commonColors.warning,
  },
  {
    value: dictionaries.TRANSACTION_STATUS.CANCELED,
    label: 'Failed',
    color: '#DB2424',
  },
]

export const TRANSACTION_STATUSES = {
  [dictionaries.TRANSACTION_STATUS.PENDING]: {
    label: 'In Progress',
    color: commonColors.warning,
  },
  [dictionaries.TRANSACTION_STATUS.PROCESSING]: {
    label: 'In Progress',
    color: commonColors.warning,
  },
  [dictionaries.TRANSACTION_STATUS.SUCCESS]: {
    label: 'Success',
    color: commonColors.success,
  },
  [dictionaries.TRANSACTION_STATUS.ERROR]: {
    label: 'Failed',
    color: '#DB2424',
  },
  [dictionaries.TRANSACTION_STATUS.CANCELED]: {
    label: 'Failed',
    color: '#DD4A2C',
  },
  [dictionaries.TRANSACTION_STATUS.SCHEDULED]: {
    label: 'Scheduled',
    color: '#6E7788',
  },
}
