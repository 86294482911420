import { Cookies } from 'react-cookie'
import type { CookieSetOptions } from 'universal-cookie/cjs/types'

const cookies = new Cookies()
cookies.addChangeListener((e) => console.log(e))
export default {
  getProvider() {
    return cookies
  },
  get(name) {
    return cookies.get(name)
  },
  set(name, value, options?: CookieSetOptions) {
    return cookies.set(name, value, { path: '/', ...options })
  },
  remove(name) {
    return cookies.remove(name)
  },
}
