import {
  validateAndExtractAlphabets,
  validateAndExtractAlphanumeric,
} from '@linqpal/models/src/helpers/validations'

export * from './Input'
export * from '../../ui/Picker'

export const alphanumericConverter = {
  to: (value) => {
    return validateAndExtractAlphanumeric(value || '')
  },
  from: (value) => {
    return validateAndExtractAlphanumeric(value || '')
  },
}

export const dateConverter = {
  to: (value) => {
    const valueLength = value.length
    if (valueLength === 2 || valueLength === 5) {
      return value + '/'
    }
    return value
  },
  from: (value) => {
    const valueLength = value?.length
    if (valueLength === 2 || valueLength === 5) {
      return value + '/'
    }
    return value
  },
}

export const alphabetsConverter = {
  to: (value) => {
    return validateAndExtractAlphabets(value || '')
  },
  from: (value) => {
    return validateAndExtractAlphabets(value || '')
  },
}
