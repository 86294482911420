export enum LoanStatusIds {
  Application = 1,
  Open = 2,
  Closed = 3,
  PaidOff = 4,
}

export enum LoanSubStatusIds {
  New = 1,
  Repaying = 9,
  PendingPayoff = 38,
  Active = 46,
  Settled = 16,
  PaidInFull = 45,
}

export const LOAN_REPAYMENT_STATUS = {
  NONE: 'None',
  LATE: 'Late',
  PAID: 'Paid',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  CANCELED: 'Canceled',
  PASTDUE: 'PastDue',
  DUENOW: 'DueNow',
  DUENEXT: 'DueNext',
  FAILED: 'Failed',
  RESCHEDULED: 'Rescheduled',
}

export type InstallmentType =
  typeof LOAN_REPAYMENT_STATUS[keyof typeof LOAN_REPAYMENT_STATUS]

export const LOAN_REPAYMENT_SCHEDULE_STATUS = {
  CURRENT: 'Current',
  RESCHEDULED: 'Rescheduled',
  POSTPONED: 'Postponed',
  REPLANNED: 'Replanned',
}

export const LOAN_REPAYMENT_TYPE = {
  LOAN_FEE: 'LoanFee',
  LATE_FEE: 'LatePaymentFee',
  INSTALLMENT: 'Installment',
  MANUAL_LATE_FEE: 'ManualLatePaymentFee',
  EXTENSION_FEE: 'ExtensionFee',
  PENALTY_INTEREST_FEE: 'PenaltyInterestFee',
  PAYABLE: 'Payable',
}

export const LOAN_REPAYMENT_NAME = {
  [LOAN_REPAYMENT_TYPE.LOAN_FEE]: 'Loan Fee',
  [LOAN_REPAYMENT_TYPE.LATE_FEE]: 'Late Fee',
  [LOAN_REPAYMENT_TYPE.INSTALLMENT]: 'Installment',
  [LOAN_REPAYMENT_TYPE.MANUAL_LATE_FEE]: 'Manual Late Fee',
  [LOAN_REPAYMENT_TYPE.EXTENSION_FEE]: 'Extension Fee',
  [LOAN_REPAYMENT_TYPE.PENALTY_INTEREST_FEE]: 'Penalty Interest',
  [LOAN_REPAYMENT_TYPE.PAYABLE]: 'Payable',
}

export const LOAN_REPAYMENT_SUB_TYPE = {
  REPAYMENT: 'Repayment',
  REFUND: 'Refund',
}

export const LOAN_TIMELINE_ITEM_TYPE = {
  INSTALLMENT: 'Installment',
  EXTENSION_FEE: 'ExtensionFee',
  LATE_FEE: 'LateFee',
  PAYMENT: 'Payment',
}

export const LOAN_PARAMETERS_CHANGE_TYPE = {
  INITIAL: 'Initial',
  RESCHEDULE: 'Reschedule',
  REPLAN: 'Replan',
}

export const LOAN_STATUS = {
  CREATED: 'Created',
  STARTED: 'Started',
  PENDING: 'Pending',
  CANCELED: 'Canceled',
  CLOSED: 'Closed',
  DEFAULTED: 'Defaulted',
  RECOVERED: 'Recovered',
  REFINANCED: 'Refinanced',
} as const

export const UI_LOAN_STATUS = {
  DUE_NEXT: 'dueNext',
  PAST_DUE: 'pastDue',
  PAST_DUE_LATE: 'pastDueLate',
  PAST_DUE_PENALTY: 'pastDuePenalty',
  PAST_DUE_LATE_AND_INTEREST: 'pastDueLateAndInterest',
}

export const UI_LOAN_STATUSES_PRIORITY = {
  [UI_LOAN_STATUS.PAST_DUE]: 0,
  [UI_LOAN_STATUS.PAST_DUE_LATE]: 1,
  [UI_LOAN_STATUS.PAST_DUE_LATE_AND_INTEREST]: 2,
  [UI_LOAN_STATUS.PAST_DUE_PENALTY]: 2,
}

export const UI_LOAN_STATUS_INFO = {
  [UI_LOAN_STATUS.DUE_NEXT]: {
    status: 'DUE_NEXT',
    label: 'Due Next',
    color: '#33B3F5',
  },
  [UI_LOAN_STATUS.PAST_DUE]: {
    status: 'PAST_DUE',
    label: 'Past Due',
    color: '#DA515E',
  },
  [UI_LOAN_STATUS.PAST_DUE_LATE]: {
    status: 'PAST_DUE_LATE',
    label: 'Past Due & Late Fee',
    color: '#DA515E',
  },
  [UI_LOAN_STATUS.PAST_DUE_PENALTY]: {
    status: 'PAST_DUE_PENALTY',
    label: 'Past Due & Interest*',
    color: '#DA515E',
  },
  [UI_LOAN_STATUS.PAST_DUE_LATE_AND_INTEREST]: {
    status: 'PAST_DUE_LATE_AND_INTEREST',
    label: 'Past Due',
    color: '#DA515E',
  },
}

export const PAYMENT_SUBTYPE_STATUS = {
  REPAYMENT: 'Repayment',
  REFUND: 'Refund',
}

export enum TimelineItemType {
  payment = 'payment',
  installment = 'installment',
  lateFee = 'lateFee',
  extensionFee = 'extensionFee',
}
