import { flow, types } from 'mobx-state-tree'
import { routes } from '@linqpal/models'

const properties = {
  items: types.optional(types.array(types.frozen()), () => []),
}

function actions(self) {
  return {
    fetchRequiredActionItems: flow(function* () {
      self.items =
        (yield routes.invoices.list({ actionRequired: true }))?.invoices || []
    }),
  }
}

export default types.model('RequiredActionStore', properties).actions(actions)
