import { SnapshotIn, types } from 'mobx-state-tree'

export const KycMemo = types.model('KycMemo', {
  companyId: '',
  userId: '',
  comment: '',
  metadata: types.frozen(),
})

export interface IKycMemoModel extends SnapshotIn<typeof KycMemo> {}
