import { RouteBase } from '../types'
import { IGetVirtualCardRequest, IGetVirtualCardResponse } from './types'

export class Card extends RouteBase {
  domain = 'card'

  getVirtualCard(data: IGetVirtualCardRequest) {
    return this.buildGetRoute<IGetVirtualCardResponse>(
      this.getVirtualCard,
      data,
    )
  }
}

export default new Card()
