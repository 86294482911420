import { flow, types } from 'mobx-state-tree';
import { routes, User } from '@linqpal/models';

const properties = {
  id: types.optional(types.identifier, 'ApplicationsScreenStore'),
  users: types.optional(types.array(User), () => []),
};

function actions(self) {
  return {
    fetchApplications: flow(function* fetchApplications() {
      const { users } = yield routes.admin.applications();
      self.users = users;
    }),
  };
}

export default types.model('ApplicationsScreenStore', properties).actions(actions);
