import { IProjectModel } from '../../mst'
import {
  RouteBase,
  IGetAllRequest,
  IGetAllResponse,
  IGetResponse,
  ICreateResponse,
  IUpdateResponse,
  IDeleteResponse,
} from '../types'
import {
  ICreateProjectDTO,
  IProjectFilters,
  IProjectInvoicesRequest,
  IProjectStatResponse,
  IUpdateProjectDTO,
} from './types'

export class Project extends RouteBase {
  domain = 'project'

  findAll(
    params: IGetAllRequest<IProjectFilters>,
  ): Promise<IGetAllResponse<any>> {
    return this?.buildGetRoute<IGetAllResponse<any>>(
      this.findAll,
      params,
    ) as Promise<IGetAllResponse<any>>
  }

  findById(id: string) {
    return this.buildGetRoute<IGetResponse<any>>(this.findById, { id })
  }

  create(data: ICreateProjectDTO) {
    return this.buildPostRoute<ICreateResponse<IProjectModel>>(
      this.create,
      data,
    )
  }

  update(id: string, data: IUpdateProjectDTO) {
    return this.buildPutRoute<IUpdateResponse<IProjectModel>>(
      this.update,
      data,
      { id },
    )
  }

  del(id: string) {
    return this.buildDelRoute<IDeleteResponse>(this.del, { id })
  }

  addInvoices(id: string, data: { invoices: Array<string> }) {
    return this.buildPutRoute<IUpdateResponse<any>>(this.addInvoices, data, {
      id,
    })
  }

  removeInvoices(id: string, data: { invoices: Array<string> }) {
    return this.buildPutRoute<IUpdateResponse<any>>(this.removeInvoices, data, {
      id,
    })
  }

  getInvoices(params: IGetAllRequest<IProjectInvoicesRequest>) {
    return this?.buildGetRoute<IGetAllResponse<any>>(
      this.getInvoices,
      params,
    ) as Promise<IGetAllResponse<any>>
  }

  getStatistics(id: string) {
    return this.buildGetRoute<IProjectStatResponse>(this.getStatistics, { id })
  }

  getNotes(projectId: string) {
    return this.buildGetRoute<any>(this.getNotes, { projectId })
  }

  createNote(data: { projectId: string; message: string }) {
    return this.buildPostRoute<ICreateResponse<any>>(this.createNote, data)
  }

  updateNote(noteId: string, data: { message: string }) {
    return this.buildPutRoute<ICreateResponse<any>>(this.updateNote, data, {
      noteId,
    })
  }

  deleteNote(noteId: string) {
    return this.buildDelRoute<IDeleteResponse>(this.deleteNote, { noteId })
  }
}

export default new Project()
