import { Instance, types } from 'mobx-state-tree'

export const Invitation = types.model('Invitation', {
  sub: '',
  company_id: types.maybeNull(types.string),
  businessName: '',
  role: '',
  fullName: '',
  firstName: '',
  lastName: '',
  login: '',
  email: '',
  type: '',
  phone: '',
  metadata: types.frozen(),
})

export interface IInvitationModel extends Instance<typeof Invitation> {}
