export const reasons = [
  'The invoice amount is wrong',
  'The invoice attached is incorrect',
  'I didn’t request this invoice ',
  'I don’t need the material anymore',
  'The material is incorrect',
  'Delivery address is incorrect',
  'I don’t want to pay with BlueTape',
  'Other',
]
