import { Dimensions, Platform } from 'react-native'
import * as uuid from 'uuid'

const dimensions = Dimensions.get('window')

const { OS } = Platform
export const { v4: UUID } = uuid
export const screenHeight = Math.round(dimensions.height)
export const screenWidth = Math.round(dimensions.width)

export const isIos = OS === 'ios'
export const isAndroid = OS === 'android'
export const isMobile = isIos || isAndroid
export const isWeb = OS === 'web'

export const headerMode = Platform.OS !== 'android' ? 'float' : 'screen'
export const SIDE_NAVIGATION_DRAWER_WIDTH = 260
export const screenOptions = {
  animationEnabled: process.env.NODE_ENV !== 'test',
  headerShown: false,
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Fore creating step by step function call, that waits for the event loop to finish before each function call.
 * Useful for UI setState etc.
 *
 * @param firstFunction
 * @param functions
 */
export const steppedFunctions = (firstFunction, ...functions) => {
  firstFunction()
  setTimeout(() => {
    functions.length > 1
      ? steppedFunctions(functions.pop(), ...functions)
      : functions[0]()
  }, 0)
}

/**
 * Simple helper to create steppedFunction.
 *
 * @param firstFunction
 * @param functions
 * @returns {function(): void}
 */
export const steppedFunctionMaker =
  (firstFunction, ...functions) =>
  () =>
    steppedFunctions(firstFunction, ...functions)

export const isSafari = () =>
  window.navigator &&
  window.navigator.vendor &&
  window.navigator.vendor.indexOf('Apple') > -1 &&
  window.navigator.userAgent &&
  window.navigator.userAgent.indexOf('CriOS') === -1 &&
  window.navigator.userAgent.indexOf('FxiOS') === -1
