import global from './en/global.json'
import auth from './en/auth.json'
import errors from './en/errors.json'
import onboarding from './en/onboarding.json'
import application from './en/application.json'
import tradeCredit from './en/tradeCredit.json'
import payables from './en/payables.json'
import I18NextLocizeBackend from 'i18next-locize-backend'

export const backend: I18NextLocizeBackend.BackendOptions = {
  projectId: '7a008225-6d02-4757-acaf-3ac2f58ab41c',
  version: process.env.REACT_APP_LINQPAL_MODE === 'prod' ? 'prod' : 'latest',
  apiKey: '',
  referenceLng: 'en',
  allowedAddOrUpdateHosts: [
    'dev.bluetape.com',
    'qa.bluetape.com',
    'beta.bluetape.com',
  ],
}
export const resources = {
  en: {
    global,
    auth,
    errors,
    onboarding,
    application,
    tradeCredit,
    payables,
  },
} as const
export const defaultNS = 'global'
export const namespaces = [
  'global',
  'auth',
  'errors',
  'onboarding',
  'application',
  'tradeCredit',
  'payables',
]
