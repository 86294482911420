/* eslint-disable i18next/no-literal-string */
import './config'
import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { Text, ActivityIndicator, View } from 'react-native'
import './polyfills'

const Main = React.lazy(() => import('./Main'))

export default function App() {
  return (
    <ErrorBoundary
      fallback={
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'red' }}>
            Something went wrong
          </Text>

          <Text
            onPress={() => window.location.replace('/')}
            style={{ fontSize: 20, padding: 20, color: '#389FF3' }}
          >
            Go to Home
          </Text>
        </View>
      }
      onError={(e) => console.log(e, 'error')}
    >
      <React.Suspense fallback={<ActivityIndicator />}>
        <Main />
      </React.Suspense>
    </ErrorBoundary>
  )
}
