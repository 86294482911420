/**
 * @param fn {Function}
 * @returns {string}
 */
export function getFnName(fn: string | ((...args: any[]) => void)): string {
  if (typeof fn === 'string') return fn
  if (typeof fn !== 'function') {
    return 'not a function'
  }
  if (fn.name) {
    return fn.name
  }
  const [, name] = fn.toString().match(/function\s+([^\s]+)/) || []
  return name || 'anonymous'
}

/**
 * @param inputString {string}
 * @returns {string}
 */
export function toKebabCase(inputString: string) {
  if (inputString === inputString.toLowerCase()) {
    return inputString
  }
  return inputString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const randomString = () => Math.random().toString(36)

export const generateFileKey = ({
  fileKeyPrimary,
  companyId,
  fileName,
}: {
  fileKeyPrimary: string
  companyId: string
  fileName: string
}) => {
  const extension = fileName.split('.').pop()
  return `${fileKeyPrimary}/${companyId}/${randomString()}.${extension}`
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
