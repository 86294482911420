import { editableModel, routes, validators } from '@linqpal/models'
import { Instance, types } from 'mobx-state-tree'

const { validateUSPhoneNumber, validateMonthAndYear } = validators

export const BusinessDetails = types
  .compose(
    types.model({
      name: '',
      dba: '',
      website: '',
      type: '',
      startDate: '',
      category: types.array(types.string),
      refundPolicy: types.array(types.string),
      saleVolume: '',
      orderVolume: '',
      maxOrderVolume: '',
      ein: '',
    }),
    editableModel(),
  )
  .views((self) => ({
    get validEIN() {
      return self.ein && self.ein.length === 9
    },
    get validStartDate() {
      return self.startDate && validateMonthAndYear(self.startDate)
    },
  }))
  .views((self) => ({
    isFilled(field) {
      switch (field) {
        case 'dba':
        case 'website':
          return true
        case 'ein':
          return self.validEIN
        case 'startDate':
          return self.validStartDate
        case 'refundPolicy':
        case 'category':
          return self[field]?.length > 0
        default:
          return self[field]
      }
    },
  }))
  .views((self) => ({
    get filled() {
      return Object.keys(self).every((k) => self.isFilled(k))
    },
  }))

export const RegisterDetails = types
  .compose(
    types.model({
      address: '',
      unitNumber: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
    }),
    editableModel(),
  )
  .views((self) => ({
    get validPhone() {
      return self.phone && validateUSPhoneNumber(self.phone)
    },
    get validZip() {
      return self.zip && self.zip.length === 5
    },
  }))
  .views((self) => ({
    isFilled(field) {
      switch (field) {
        case 'unitNumber':
          return true
        case 'phone':
          return self.validPhone
        case 'zip':
          return self.validZip
        case 'lat':
          return self[field]
        case 'lng':
          return self[field]
        default:
          return self[field].trim()
      }
    },
  }))
  .views((self) => ({
    get filled() {
      return Object.keys(self).every((k) => self.isFilled(k))
    },
  }))
  .named('registered.details')

export const BankDetails = types
  .compose(
    types.model({
      account: '',
      institutionName: '',
      accountHolderName: '',
      routing: '',
      finicityAccountAdded: false,
      existingManualBankSelected: types.optional(types.boolean, false),
    }),
    editableModel(),
  )
  .views((self) => ({
    get validAccount() {
      return !!self.account && self.account.length >= 6
    },
    get validRouting() {
      return !!self.routing && self.routing.length === 9
    },
  }))
  .views((self) => ({
    isFilled(field) {
      switch (field) {
        case 'existingManualBankSelected':
          return true
        case 'finicityAccountAdded':
          return true
        case 'account':
          return self.validAccount
        case 'routing':
          return self.validRouting
        default:
          return self[field]
      }
    },
  }))
  .views((self) => ({
    get filled() {
      return (
        self.finicityAccountAdded ||
        self.existingManualBankSelected ||
        Object.keys(self).every((k) => self.isFilled(k))
      )
    },
  }))
  .actions((self) => ({
    reset() {
      self.account = ''
      self.routing = ''
      self.accountHolderName = ''
      self.institutionName = ''
      self.finicityAccountAdded = false
      self.existingManualBankSelected = false
    },
    setData(data) {
      self.institutionName = data.name
      self.accountHolderName = data.accountholderName
      self.routing = data.routingNumber
      self.account = ''
    },
    saveInSupplierApplication(bank) {
      const {
        accountHolderName,
        routing,
        finicityAccountAdded,
        institutionName,
      } = bank
      self.institutionName = institutionName
      self.accountHolderName = accountHolderName
      self.routing = routing
      self.account = ''
      self.finicityAccountAdded = finicityAccountAdded
      self.existingManualBankSelected = true
    },
    submit(onSuccess) {
      const bankObject = {
        name: self.institutionName,
        accountholderName: self.accountHolderName,
        finicity: {},
        routingNumber: self.routing,
        accountNumber: self.account,
        isManualEntry: true,
        isPrimary: true,
        accountType: 'savings',
        status: 'notverified',
        paymentMethodType: 'bank',
      }
      routes.company.addBankAccount(bankObject).then(() => {
        onSuccess()
      })
    },
  }))

export interface IBankDetails extends Instance<typeof BankDetails> {}

export const OperationDetails = types
  .compose(
    types.model({
      software: '',
      manageAccounting: '',
      ecommercePlatform: '',
      find: '',
    }),
    editableModel(),
  )
  .views((self) => ({
    get filled() {
      return self.find
    },
  }))
