import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

export function createEditable ({
  Component,
  valuePropertyName = 'value',
  changePropertyName = 'onChange',
  changeOverride = null
}) {
  function createObserver (props) {
    const { model, name, converter, forwardRef, store, onChange, ...rest } = props
    const _onChange = (value) => {
      const { model, name, converter } = props
      if (converter) {
        const { to } = converter
        if (value && to) {
          value = to(value)
        }
      }
      if (model && model.setValue)
        model.setValue(name, value)
      if (onChange) {
        onChange(value)
      }
    }
    return observer(() => {
      let value
      if (model) {
        value = model[name]
        if (converter) {
          const { from } = converter
          if (from) {
            value = from(model[name])
          }
        }
      }
      rest[valuePropertyName] = value
      rest[changePropertyName] = changeOverride ? changeOverride(props, _onChange) : _onChange
      return <Component {...rest} ref={forwardRef}/>
    })
  }

  function Editable (props) {
    let ObserverComponent = useMemo(() => createObserver(props))
    return (
      <ObserverComponent/>
    )
  }

  Editable.displayName = `Editable(${Component.displayName || Component.name})`

  const forwardRef = (props, ref) => (<Editable {...props} forwardRef={ref}/>)

  return React.forwardRef(forwardRef)
}
