import { Instance, types } from 'mobx-state-tree'
import { editableModel, validators } from '../..'

const { validateUSPhoneNumber, validateEmail, validateDate } = validators

interface OwnerBase {
  titleOfOwner: string
  otherTitleOfOwner: string
  firstName: string
  lastName: string
  address: string
  unitNumber: string
  city: string
  state: string
  zip: string
  birthday: string
  ssn: string
  email: string
  phone: string
  find: string
  findComment: string
}

interface CoOwnerBase extends OwnerBase {
  authorized: boolean | null
  owner: boolean | null
  percentOwned: number | string | null
  coOwner: boolean | null
  ownerEmail: string
}

const addValidation = (self: OwnerBase) => ({
  get validPhone() {
    return validateUSPhoneNumber(self.phone)
  },
  get validEmail() {
    return validateEmail(self.email)
  },
  get validBirthday() {
    return !!self.birthday && validateDate(self.birthday, 1900)
  },
  get validSSN() {
    return !!self.ssn && self.ssn.length === 9
  },
  get validZip() {
    return !!self.zip && self.zip.length === 5
  },
})

const addIsFilled = (self: CoOwnerBase & ReturnType<typeof addValidation>) => ({
  isFilled(field: keyof CoOwnerBase | keyof ReturnType<typeof addValidation>) {
    switch (field) {
      case 'authorized':
        return (
          self.authorized === true ||
          self.authorized === false ||
          self.owner === true
        )
      case 'owner':
        return self.owner === true || self.owner === false
      case 'percentOwned':
        return self.owner === true ? !!self.percentOwned : true
      case 'coOwner':
        return true
      case 'ownerEmail':
        return true
      case 'unitNumber':
        return true
      case 'ssn':
        return self.validSSN
      case 'zip':
        return self.validZip
      case 'birthday':
        return self.validBirthday
      case 'email': {
        return self.coOwner
          ? self.validEmail && self.email !== self.ownerEmail
          : self.validEmail
      }
      case 'phone':
        return self.validPhone
      case 'otherTitleOfOwner':
        return self.titleOfOwner !== 'Other' || self.otherTitleOfOwner
      case 'find':
        return self.authorized !== true || self.find
      case 'findComment':
        return (
          self.authorized !== true ||
          !['Trade Shows', 'Other'].includes(self.find) ||
          self.findComment
        )
      case 'address':
        return self[field].trim()
      case 'city':
        return self[field].trim()
      default:
        return self[field]
    }
  },
})

const views = (
  self: CoOwnerBase &
    ReturnType<typeof addIsFilled> &
    ReturnType<typeof addValidation>,
) => ({
  get filled() {
    return (
      (self.authorized === false && self.owner === false) ||
      (Object.keys(self) as Array<keyof OwnerBase>).every((k) =>
        self.isFilled(k),
      )
    )
  },

  get canSave() {
    return (
      self.isFilled('phone') &&
      !!self.firstName &&
      !!self.lastName &&
      self.isFilled('email')
    )
  },
  get allMandatoryFilled() {
    return (
      !!self.percentOwned &&
      !!self.firstName &&
      !!self.lastName &&
      !!self.titleOfOwner &&
      self.isFilled('ssn') &&
      self.isFilled('birthday') &&
      !!self.address &&
      self.validEmail &&
      self.validPhone
    )
  },
})

export const Owner = types
  .compose(
    types.model({
      titleOfOwner: '',
      otherTitleOfOwner: '',
      firstName: '',
      lastName: '',
      address: '',
      unitNumber: '',
      city: '',
      state: '',
      zip: '',
      birthday: '',
      ssn: '',
      email: '',
      phone: '',
      find: '',
      findComment: '',
    }),
    editableModel(),
  )
  .views(addValidation)
  .views((self) =>
    addIsFilled(
      self as unknown as CoOwnerBase & ReturnType<typeof addValidation>,
    ),
  )
  .views((self) =>
    views(
      self as unknown as CoOwnerBase &
        ReturnType<typeof addValidation> &
        ReturnType<typeof addIsFilled>,
    ),
  )

export const CoOwner = types
  .compose(
    types.model({
      authorized: types.maybeNull(types.boolean),
      owner: types.maybeNull(types.boolean),
      percentOwned: types.maybeNull(types.union(types.number, types.string)),
      titleOfOwner: '',
      otherTitleOfOwner: '',
      firstName: '',
      lastName: '',
      address: '',
      unitNumber: '',
      city: '',
      state: '',
      zip: '',
      birthday: '',
      ssn: '',
      email: '',
      phone: '',
      find: '',
      findComment: '',
      coOwner: types.optional(types.boolean, false),
      ownerEmail: '',
    }),
    editableModel(),
  )
  .views(addValidation)
  .views(addIsFilled)
  .views(views)

const CoOwners = types
  .model({
    owners: types.array(CoOwner),
    current: types.maybeNull(types.reference(CoOwner)),
  })
  .actions((self) => ({
    addOwner(owner: Instance<typeof CoOwner>) {
      self.owners.push(owner)
    },
    setCurrent(current: Instance<typeof CoOwner>) {
      self.current = current
    },
    removeOwner(owner: Instance<typeof CoOwner>) {
      self.current = null
      self.owners.remove(owner)
    },
  }))
  .views((self) => ({
    get filled() {
      return self.owners.length > 0 && self.owners.every((co) => co.filled)
    },
  }))

export default CoOwners
