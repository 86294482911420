import { types } from 'mobx-state-tree';
import moment from 'moment';

export const Moment = types.custom({
  name: 'Moment',
  fromSnapshot(snapshot) {
    return moment(snapshot);
  },
  toSnapshot(value) {
    return value.format('YYYY-MM-DD HH:mm:ss.SSSZZ');
  },
  isTargetType(value) {
    return moment.isMoment(value);
  },
  getValidationMessage(snapshot) {
    return moment(snapshot).isValid() ? ''
      : `Value ${ snapshot } provided seems not valid Moment instance`;
  },
});

export const MomentDate = types.custom({
  name: 'MomentDate',
  fromSnapshot(snapshot) {
    return moment(snapshot, 'YYYY-MM-DD');
  },
  toSnapshot(value) {
    return value.format('YYYY-MM-DD');
  },
  isTargetType(value) {
    return moment.isMoment(value);
  },
  getValidationMessage(snapshot) {
    return moment(snapshot, 'YYYY-MM-DD').isValid() ? ''
      : `Value ${ snapshot } provided seems not valid MomentDate instance`;
  },
});
