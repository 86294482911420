import { post, get } from '../helpers/caller'

const domain = 'accounting'
export const accounting = {
  settingsAdd(body: any) {
    return post(domain, accounting.settingsAdd, body)
  },
  settingsGet(companyId: string) {
    return get(domain, accounting.settingsGet, companyId)
  },
}
