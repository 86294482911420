import React from 'react'
import { Snackbar } from 'react-native-paper'
import { Text, View, StyleSheet } from 'react-native'
import { GreenCheck } from '../../../../../../assets/icons'

const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
}

type MessageType = typeof MESSAGE_TYPE[keyof typeof MESSAGE_TYPE]

interface SnackBarProps {
  visible: boolean
  onDismiss: () => void
  message: string
  type: MessageType
}

export const SnackBar: React.FC<SnackBarProps> = ({
  visible,
  onDismiss,
  message,
  type,
}) => {
  let icon
  if (type === MESSAGE_TYPE.SUCCESS) {
    icon = <GreenCheck />
  }
  if (!visible) return null
  return (
    <Snackbar
      testID="snack-bar"
      visible
      onDismiss={onDismiss}
      wrapperStyle={styles.wrapper}
      style={styles.snackStyle}
      duration={7000}
    >
      <View style={styles.view}>
        {icon}
        <Text style={styles.message}>{message}</Text>
      </View>
    </Snackbar>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'flex-end',
    marginRight: 33,
    marginBottom: 74,
    zIndex: 9999,
  },
  snackStyle: {
    backgroundColor: 'white',
    width: 343,
    height: 86,
    borderRadius: 8,
  },
  view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  message: {
    color: '#003353',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    marginLeft: 16,
  },
})
