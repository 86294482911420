import React from 'react'
import PopupMessage from '../atoms/PopupMessage'
import { MESSAGE_TYPE, useMessage } from '../../utils/helpers/MessageProvider'

export const PopupMessageContained = (props) => {
  const messageProps = useMessage()
  if (!messageProps?.hasOwnProperty('setMessage')) return null
  const { setMessage, msgObject } = messageProps
  return <PopupMessage
    visible={!!msgObject.message}
    type={msgObject.type}
    message={msgObject.message}
    onDismiss={() => setMessage({ type: MESSAGE_TYPE.INFO, message: '' })} {...props}/>
}
