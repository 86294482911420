import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Checkbox, Text, useTheme } from 'react-native-paper'

export default ({
  checked,
  onPress,
  label,
  labelLeft = false,
  disabled = false,
  focusable = true,
}) => {
  return (
    <TouchableOpacity
      style={{ flexDirection: 'row', alignItems: 'center' }}
      onPress={onPress}
      focusable={focusable}
    >
      {labelLeft && <Text>{label}</Text>}
      <Check
        checked={checked}
        onPress={onPress}
        disabled={disabled}
        focusable={focusable}
      />
      {!labelLeft}
      <Text>{label}</Text>
    </TouchableOpacity>
  )
}

export const Check = ({
  checked,
  onPress,
  disabled = false,
  focusable = true,
}) => {
  const { colors } = useTheme()
  return (
    <Checkbox
      focusable={focusable}
      color={colors.primary}
      uncheckedColor={'black'}
      status={checked ? 'checked' : 'unchecked'}
      onPress={onPress}
      disabled={disabled}
    />
  )
}
