import axios from 'axios'
import { exceptions, routes2 } from '@linqpal/models'
import numbro from 'numbro'
import fb from './utils/service/fb'
import store from './store'
import { isMobile } from './utils/helpers/commonUtils'
import './i18n'
import cooker from './utils/service/cooker'

numbro.defaultCurrencyFormat('0.00')

const url = process.env.REACT_APP_API_URL || 'http://localhost:5000/dev'

const buildHeaders = async (headers) => {
  if (isMobile) {
    const user = fb.auth().currentUser
    if (!user) {
      return headers
    }
    const idToken = await user.getIdToken()
    const Authorization = `Bearer ${idToken}`
    return { ...headers, Authorization }
  } else {
    const session = cooker.get('session') || ''
    const challenge = cooker.get('auth-challenge') || ''
    return { ...headers, session, challenge }
  }
}

axios.defaults.baseURL = url

axios.interceptors.request.use(async (request) => {
  request.baseURL = url
  store.setBusy(!request.params.silent)
  request.headers = await buildHeaders(request.headers)
  return request
})

axios.interceptors.response.use(
  async (response) => {
    store.setBusy(false)
    return response?.data
  },
  (error) => {
    const { response, config } = error
    store.setBusy(false)
    if (axios.isCancel(error)) {
      return Promise.reject(new exceptions.LogicalError('request-cancelled'))
    }
    if (!response) {
      return Promise.reject(new exceptions.LogicalError('network-error'))
    }
    // Retry Later Error
    if (
      response.status === 412 &&
      response.data?.result === 'RetryLaterError'
    ) {
      config.params.retry = config.params.retry ? config.params.retry + 1 : 1
      if (config.params.retry < 5) {
        return axios.request(config)
      }
    }
    // Auth error
    if (response.status === 401) {
      store.logOut()
    }
    if (response.status === 460) {
      return fb
        .auth()
        .signInWithCustomToken(response?.data.refreshToken)
        .then((result) => result.user?.getIdToken())
        .then((idToken) =>
          routes2.user.webAuth({
            idToken,
          }),
        )
        .then(({ session, challenge }) => {
          cooker.set('session', session, { maxAge: 60 * 60 })
          cooker.set('auth-challenge', challenge, {
            maxAge: 80 * 24 * 60 * 60,
          })
          return axios.request(config)
        })
    }
    if (response.data?.code && exceptions.isKnownError(response.data?.code)) {
      return Promise.reject({ ...response.data, silent: config.params.silent })
    }
    return Promise.reject(response.data || error)
  },
)
