import { flow, types } from 'mobx-state-tree'
import { NotificationWithInvoice, routes } from '@linqpal/models'

const properties = {
  id: types.optional(types.identifier, 'NotificationStore'),
  notifications: types.optional(types.array(NotificationWithInvoice), () => [])
}

function actions (self) {
  return {
    fetchNotifications: flow(function * () {
      self.notifications = (yield routes.notification.list({ isRead: false }))?.notifications || []
    }),
    markAllAsRead: flow(function * (filter) {
      const response = yield routes.notification.markAllAsRead(filter)
      if (response.result === 'ok') {
        self.fetchNotifications()
      }
    }),
    markAllAsViewed: flow(function * (filter) {
      const response = yield routes.notification.markAllAsViewed(filter)
      if (response.result === 'ok') {
        self.fetchNotifications()
      }
    })
  }
}

export default types.model('NotificationStore', properties).actions(actions)
