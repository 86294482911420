export const InHouseCreditStatus = {
  NotApplied: 'Not Applied',
  IncompleteApplication: 'Incomplete Application',
  GoodStanding: 'Good Standing',
  PendingReview: 'Pending Review',
  AccountOnHold: 'On Hold',
  InCollection: 'In Collection',
  Closed: 'Closed',
  PastDue: 'Past Due',
  Inactive: 'Account Set Up Required',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
} as const

export type InHouseCreditStatusType =
  typeof InHouseCreditStatus[keyof typeof InHouseCreditStatus]
