export const notificationTypes = {
  invoice: 'INVOICES',
  quotePlaced: 'QUOTE_PLACED',
  quoteAuthorized: 'QUOTE_AUTHORIZED',
  quoteInvoiced: 'QUOTE_INVOICED',
  quoteRejected: 'QUOTE_REJECTED',
  quoteExpired: 'QUOTE_EXPIRED',
  quoteCanceled: 'QUOTE_CANCELED',
  transaction: 'TRANSACTION',
  loan: 'LOAN',
  autoTradeCreditApproved: 'AUTO_TRADE_CREDIT_APPROVED',
  payment: 'PAYMENT',
  virtualcard: 'VIRTUALCARD',
} as const

export type NotificationTypes =
  typeof notificationTypes[keyof typeof notificationTypes]

export const notificationText = {
  invoicePaid: 'An invoice has been paid',
  invoiceDismissed: 'An invoice has been dismissed',
  loanIssued: 'Loan has started',
  cardIssued: 'One time card is ready',
} as const

export const alertTypes = {
  sent: 'sent',
  expiring: 'expiring',
  due: 'due',
  fail: 'fail',
  dismissed: 'dismissed',
  cancelled: 'cancelled',
  success: 'success',
  error: 'error',
} as const

export type NotificationAlertTypes = typeof alertTypes[keyof typeof alertTypes]
