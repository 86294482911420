import { useEffect } from 'react'

/**
 *
 * @param {null | ref } ref - set as null or false to attach to 'root' div.
 * @param script
 */
export const useIframe = ({ ref, src, id }) => {
  if (!ref) ref = { current: document.getElementById('root') }

  useEffect(() => {
    if (!ref.current) return

    const iframeElement = document.createElement('iframe')
    iframeElement.src = src
    iframeElement.setAttribute('id', id)
    iframeElement.setAttribute('frameborder', '0')
    iframeElement.setAttribute('scrolling', 'yes')
    // iframeElement.setAttribute('height', '600px')
    // iframeElement.setAttribute('width', '600px')
    iframeElement.setAttribute(
      'style',
      `position: absolute; top: 0; left: 0; width: 100%; height: 100%;`,
    )

    ref.current.appendChild(iframeElement)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current?.removeChild(iframeElement)
    }
    // todo fix lint issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, src])
}
