import { flow, types } from 'mobx-state-tree'
import { CreditDebitCard } from '@linqpal/models'

const name = 'CreditDebitCardManagementStore'

const properties = {
  id: types.optional(types.identifier, name),
  cards: types.optional(types.array(CreditDebitCard), () => [])
}

function actions (self) {
  return {
    fetchCards: flow(function * () {
      //TODO: API integration
      this.cards = [
        {
          '_id': '600e2aa5fc13ae11c9000000',
          'type': 'americanExpress',
          'number': '************0134',
          'billingAddress': {
            'address': '58 Village Point',
            'city': 'Charlotte',
            'state': 'North Carolina',
            'zip': '28284'
          },
          'expiration': {
            'month': 5,
            'year': 2029
          }
        },
        {
          '_id': '600e2aa5fc13ae11c9000001',
          'type': 'visa',
          'number': '***********4477',
          'billingAddress': {
            'address': '37424 Sachs Lane',
            'city': 'Oakland',
            'state': 'California',
            'zip': '94611'
          },
          'expiration': {
            'month': 8,
            'year': 2028
          }
        }
      ]
    }),
    setCard: flow(function * (data) {
      const cardIndex = self.cards.findIndex(card => card._id === data._id)
      self.cards[cardIndex] = { ...self.cards[cardIndex], ...data }
    }),
    deleteCard: flow(function * (data) {
      self.cards = self.cards.filter(card => card._id !== data._id)
    }),
    addCard: flow(function * (data) {
      self.cards.push({ _id: self.cards.length, ...data })
    })
  }
}

export default types.model(name, properties).actions(actions)

