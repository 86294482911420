import { delete_, FunctionNaming, get, post, put } from '../helpers/caller'

export interface IResponse {
  result?: 'ok' | 'fail'
  code?: string
  message?: string
}

export type TMiddleware =
  | ((req?: any, res?: any, next?: any) => Promise<void>)
  | ((err?: any, req?: any, res?: any, next?: any) => Promise<void>)

export interface TMiddlewares {
  pre?: TMiddleware[]
  post?: TMiddleware[]
}

export type TFrontendRoute<T, R> = (data?: T) => Promise<R>

export type TSimpleRoute<T, R> = (
  data: T,
  req?: any,
  res?: any,
  next?: any,
) => Promise<R>

export interface TComplexRoute<T, R> {
  middlewares?: TMiddlewares
  get?: TSimpleRoute<T, R>
  post?: TSimpleRoute<T, R>
  put?: TSimpleRoute<T, R>
  delete?: TSimpleRoute<T, R>
  patch?: TSimpleRoute<T, R>
}

export type TCompoundRoute<T, R> =
  | TComplexRoute<T, R>
  | TFrontendRoute<T, R>
  | TSimpleRoute<T, R>

export const isSimpleRoute = <T, R>(x: any): x is TSimpleRoute<T, R> =>
  typeof x === 'function'

export const isComplexRoute = <T, R>(x: any): x is TComplexRoute<T, R> =>
  typeof x === 'object' &&
  (x.hasOwnProperty('middlewares') ||
    x.hasOwnProperty('get') ||
    x.hasOwnProperty('post') ||
    x.hasOwnProperty('put') ||
    x.hasOwnProperty('patch') ||
    x.hasOwnProperty('delete'))

export abstract class RouteBase {
  abstract domain: string

  buildGetRoute<T>(
    name: FunctionNaming,
    params = {},
    silent = false,
    version = 2,
  ) {
    return get<T>(this.domain, name, params, silent, version)
  }

  buildPostRoute<T>(
    name: FunctionNaming,
    data = {},
    params = {},
    silent = false,
    version = 2,
  ) {
    return post<T>(this.domain, name, data, params, silent, version)
  }

  buildPutRoute<T>(
    name: FunctionNaming,
    data: any,
    params = {},
    silent = false,
    version = 2,
  ) {
    return put<T>(this.domain, name, data, params, silent, version)
  }

  buildDelRoute<T>(
    name: FunctionNaming,
    params: any,
    silent = false,
    version = 2,
  ) {
    return delete_<T>(this.domain, name, params, silent, version)
  }
}

export interface PageParams {
  page: number
  pageSize: number
  search?: string
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
}

export type IGetAllRequest<T> = PageParams & Pick<T, keyof T>

export interface IGetAllResponse<T> extends IResponse {
  items: Array<T>
  count: number
}

export interface IGetRequest {
  id: string
}

export interface IGetResponse<T> extends IResponse {
  data: T | null
}

export interface ICreateResponse<T> extends IResponse {
  data: T
}

export interface IUpdateResponse<T> extends IResponse {
  data: T
}

export interface IDeleteRequest {
  id: string
}

export interface IDeleteResponse extends IResponse {}
