import { dictionaries, editableModel, routes } from '@linqpal/models'
import { flow, types } from 'mobx-state-tree'
import moment from 'moment'
import numbro from 'numbro'
import xlsx from 'xlsx'
import { TRANSACTION_STATUSES } from '../../screens/Supplier/Transaction/transactionStatusOptions'
import { UUID } from '../../utils/helpers/commonUtils'

const Refund = types.compose(
  types
    .model('Refund', {
      id: types.optional(types.identifier, UUID()),
      amount: types.maybeNull(types.union(types.number, types.string)),
      refund_total_amount: false,
      note: '',
      errorMessage: '',
      done: false,
    })
    .views((self) => ({
      get amount_str() {
        const { amount } = self
        if (amount === null || amount === '') return '$'
        const amount_str = amount.toString()
        return '$' + amount_str.replace(/[^\d.]/g, '')
      },
      set amount_str(value) {
        const amount =
          typeof value === 'string' ? value.replace(/[^\d+.]/g, '') : ''
        const [, left = '', right = ''] =
          /(0|[1-9]\d*)(\.?\d*)?/.exec(amount) || []
        if (left.length === 0) {
          self.amount = ''
        } else if (right.length === 0 || /^\d+$/.test(right)) {
          self.amount = parseInt(left)
        } else if (right === '.' || /^\.\d*0+$/.test(right)) {
          self.amount = `${left}${right}`
        } else {
          self.amount = parseFloat(`${left}${right}`)
        }
      },
      get canSubmit() {
        const amount = parseFloat(self.amount)
        return self.refund_total_amount || (isFinite(amount) && amount > 0)
      },
    }))
    .actions((self) => ({
      setSuccess() {
        self.done = true
      },
      setError(e) {
        self.errorMessage =
          e.response?.data?.message ||
          e.data?.message ||
          e.message ||
          e.toString()
      },
    })),
  editableModel(),
)

export default types
  .model('TransactionStore', {
    fetchParams: types.maybeNull(types.frozen()),
    items: types.array(types.frozen()),
    current: types.maybeNull(types.frozen()),
    currentInvoice: types.maybeNull(types.frozen()),
    count: types.optional(types.number, 0),
    refundCurrent: false,
    refund: types.optional(Refund, () => Refund.create()),
  })
  .volatile((self) => ({
    fetchTime: moment.now(),
  }))
  .views((self) => ({
    get transactions() {
      return self.items
        .map((item) => {
          return item.refund_operations.length === 0
            ? [item]
            : [
                item,
                ...item.refund_operations.map((operation) => ({
                  ...item,
                  operation,
                })),
              ]
        })
        .flat(1)
    },
    get isCurrentRefundable() {
      const { current } = self
      return (
        current?.operation?.type ===
          dictionaries.OPERATION_TYPES.INVOICE.PAYMENT &&
        current?.operation?.status === dictionaries.OPERATION_STATUS.SUCCESS
      )
    },
    get currentRefundableAmount() {
      if (!self.isCurrentRefundable) return 0
      const { total_amount = 0, refund_operations = [] } = self.current
      const refunded_amount =
        refund_operations
          ?.filter((o) => o.status !== dictionaries.OPERATION_STATUS.FAIL)
          .reduce((a, o) => a + o.amount, 0) || 0
      return parseFloat((total_amount - refunded_amount).toFixed(4))
    },
    get currentRefundableAmountStr() {
      if (!self.currentRefundableAmount) return '$'
      return numbro(self.currentRefundableAmount).formatCurrency('0,0.00')
    },
    get canRefundCurrent() {
      return self.isCurrentRefundable && self.currentRefundableAmount > 0
    },
  }))
  .actions((self) => ({
    fetchTransactions(params) {
      self.fetchParams = params
      self.fetchTime = moment.now()
      return routes.transactions.allTransactions({ ...self.fetchParams })
    },
    fetchAllTransactions() {
      return routes.transactions.allTransactions({
        ...self.fetchParams,
        pageSize: -1,
      })
    },
    refreshTransactions() {
      const params = self.fetchParams
      const r = self.fetchTransactions(params)
      self.fetchTime = moment.now()
      r.then((data) => {
        params === self.fetchParams && self.setData(data)
      })
      return r
    },
    clear() {
      self.fetchParams = null
      self.refundCurrent = false
      self.refund = Refund.create()
      self.current = null
      self.currentInvoice = null
      self.items.clear()
    },
    setData({ items, count }) {
      self.items.replace(items)
      self.count = count
      if (self.current) {
        const item = self.transactions.find((i) => i._id === self.current._id)
        if (item) {
          self.current = item
        } else {
          self.setCurrent(null)
        }
      }
    },
    refreshAndReset: flow(function* () {
      const response = yield self.refreshTransactions()
      self.setData(response)
    }),
    setCurrent(item) {
      self.refundCurrent = false
      self.refund = Refund.create()
      self.current = item
    },
    setInvoice(item) {
      self.currentInvoice = item
    },
    setRefundCurrent(value) {
      self.refund = Refund.create()
      self.refundCurrent = self.canRefundCurrent && !!value
    },
    refundPartial() {
      self.refund.refund_total_amount = false
    },
    refundTotal() {
      self.refund.refund_total_amount = true
    },
    submitRefund() {
      const { current, canRefundCurrent, refundCurrent } = self
      const canRefund =
        current && canRefundCurrent && refundCurrent && self.refund.canSubmit
      if (canRefund) {
        const amount = self.refund.refund_total_amount
          ? self.currentRefundableAmount
          : parseFloat(self.refund.amount)
        routes.invoices
          .refundAmount(current._id, current.operation._id, amount)
          .then(self.refund.setSuccess)
          .then(self.refreshTransactions)
          .catch(self.refund.setError)
      }
    },
    export(items) {
      const book = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(
        book,
        xlsx.utils.json_to_sheet(
          items.map((item) => ({
            Status: TRANSACTION_STATUSES[item.transactionStatus]?.label || '',
            Amount: `${numbro(item.operation.amount).formatCurrency('0.00')}`,
            'Transaction number': `${item.pullTransaction.metadata.transactionNumber}`,
            Business: `${item.customer?.name} \n ${item.customer?.phone}`,
            Action: [
              dictionaries.OPERATION_TYPES.INVOICE.PAYMENT,
              dictionaries.OPERATION_TYPES.INVOICE.FINAL_PAYMENT,
            ].includes(item.operation.type)
              ? 'Payment'
              : 'Refund',
            Date: item.transaction_date
              ? moment(item.transaction_date).format('MM/DD/YYYY')
              : '-',
            Invoice: item.invoice_number,
          })),
        ),
      )
      xlsx.writeFile(book, 'transactions.xlsx', {
        bookType: 'xlsx',
      })
    },
    exportTransactions() {
      if (!self.items || self.items.length === 0) return
      const req = self.fetchAllTransactions()
      req.then((resp) => {
        if (resp.items?.length !== 0) {
          self.export(resp.items)
        }
      })
    },
  }))

export function transactionMetaInfo(item) {
  let txnNumber = '-'
  let txnDate = ''

  const { allTransactions = [] } = item?.operation || {}
  const _transaction =
    allTransactions.find(
      (transaction) =>
        transaction.type === 'transfer' &&
        transaction.status === dictionaries.TRANSACTION_STATUS.SUCCESS &&
        (transaction.metadata.transactionNumber ||
          transaction.metadata.transactionID),
    ) ||
    allTransactions.find(
      (transaction) =>
        transaction.type === 'transfer' &&
        (transaction.metadata.transactionNumber ||
          transaction.metadata.transactionID),
    ) ||
    allTransactions.find((transaction) => transaction.type === 'transfer')

  if (_transaction) {
    txnNumber =
      _transaction.metadata.transactionNumber ||
      _transaction.metadata.transactionID
    txnDate = _transaction.date || _transaction.createdAt
  }
  return { txnNumber, txnDate }
}
