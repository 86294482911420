import { Instance, types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'

export const InvoicePayerInformation = types.compose(
  types.model('InvoicePayerInformation', {
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
    cellPhoneNumber: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
  }),
  editableModel(),
)

export interface IInvoicePayerInformationModel
  extends Instance<typeof InvoicePayerInformation> {}
