import { Instance, types } from 'mobx-state-tree'
import { EAccountType } from '../dictionaries'
import { editableModel } from '../helpers/editable'

export const CardEntryModel = types.compose(
  types
    .model({
      binNumber: '',
      expirationDate: '',
      cardToken: '',
      accountType: types.optional(
        types.enumeration<EAccountType>(Object.values(EAccountType)),
        EAccountType.PERSONAL,
      ),
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      zipCode: '',
      isSending: false,
    })
    .actions((self) => ({
      startSending: () => (self.isSending = true),
      stopSending: () => (self.isSending = false),
    }))
    .views((self) => ({
      get nameFilled() {
        return !!(self.firstName.trim() && self.lastName.trim())
      },
      get canSave() {
        return (
          self.binNumber &&
          self.expirationDate &&
          self.cardToken &&
          self.firstName &&
          self.lastName &&
          self.accountType &&
          self.addressLine1 &&
          self.city &&
          self.stateCode &&
          self.zipCode &&
          self.zipCode.length === 5
        )
      },
    })),
  editableModel(),
)

export interface ICardEntryModel extends Instance<typeof CardEntryModel> {}
