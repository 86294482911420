import { useMemo } from 'react'

export const useDetachPaddingMargin = (style) =>
  useMemo(() => {
    const styleReducer = (accumulator, key) => ({
      ...accumulator,
      [key]: style[key],
    })

    // TODO: Refactor

    const tester = (key) => /(padding)|(margin)|(([wW])idth)/.test(key)

    const paddingsAndMargins = Object.keys(style)
      .filter(tester)
      .reduce(styleReducer, {})

    const rest = Object.keys(style)
      .filter((key) => !tester(key))
      .reduce(styleReducer, {})

    return { paddingsAndMargins, restOfStyle: rest }
  }, [style])
