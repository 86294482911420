import { useTheme } from 'react-native-paper'

export function useBaseInputStyle() {
  const theme = useTheme()
  return {
    input: {
      color: theme.colors.primary,
      fontSize: 16,
      fontWeight: '400',
      paddingLeft: 10,
      paddingRight: 5,
      paddingTop: 26,
      width: '100%',
    },
    inputError: {
      color: theme.colors.accent,
      borderColor: theme.colors.accent,
    },
    inputDisabled: {
      color: '#A7B1C3',
      borderColor: '#e4e5ef',
    },
    inputBackgroundColor: {
      backgroundColor: 'white',
    },
  }
}
