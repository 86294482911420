import React, { FC } from 'react'
import { Text, TextStyle } from 'react-native'

type RequiredLabelProps = {
  style: TextStyle
}

const RequiredLabel: FC<RequiredLabelProps> = ({ style }) => {
  return <Text style={style}>*Required field</Text>
}

export default RequiredLabel
