export const LOAN_APPLICATION_STATUS = {
  NOTSTARTED: 'notStarted',
  NEW: 'new',
  PENDING: 'pending',
  ERROR: 'error',
  PROCESSING: 'processing',
  AUTHORIZED: 'authorized',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  CLOSED: 'closed',
  EXPIRED: 'expired',
} as const

// TODO: VK: Introduce new loan app's PENDING_DISBURSEMENT status for ATC version #2
// Be aware that pending disbursement is partially approved, partially processing state (see APPROVED_STATUSES / PROCESSING_STATUSES)
export const PENDING_DISBURSEMENT_STATUS = 'pending_disbursement'

export type LoanApplicationStatuses =
  typeof LOAN_APPLICATION_STATUS[keyof typeof LOAN_APPLICATION_STATUS]

export const APPROVED_STATUSES: LoanApplicationStatuses[] = [
  LOAN_APPLICATION_STATUS.APPROVED,
  LOAN_APPLICATION_STATUS.AUTHORIZED,
  LOAN_APPLICATION_STATUS.EXPIRED,
  LOAN_APPLICATION_STATUS.CLOSED,
]

export const PROCESSING_STATUSES: LoanApplicationStatuses[] = [
  LOAN_APPLICATION_STATUS.PENDING,
  LOAN_APPLICATION_STATUS.PROCESSING,
  LOAN_APPLICATION_STATUS.ERROR,
]

export const LOAN_APPLICATION_STATUS_LABEL: Record<
  Exclude<LoanApplicationStatuses, 'expired' | 'notStarted'>,
  string
> = {
  [LOAN_APPLICATION_STATUS.NEW]: 'Incomplete',
  [LOAN_APPLICATION_STATUS.PENDING]: 'In Review',
  [LOAN_APPLICATION_STATUS.ERROR]: 'Error',
  [LOAN_APPLICATION_STATUS.PROCESSING]: 'Processing',
  [LOAN_APPLICATION_STATUS.AUTHORIZED]: 'Authorized',
  [LOAN_APPLICATION_STATUS.APPROVED]: 'Approved',
  [LOAN_APPLICATION_STATUS.CANCELED]: 'Canceled',
  [LOAN_APPLICATION_STATUS.REJECTED]: 'Rejected',
  [LOAN_APPLICATION_STATUS.CLOSED]: 'Closed',
}

export const REPAYMENT_BY = {
  SUPPLIER: 'supplier',
}

export type RepaymentBy = typeof REPAYMENT_BY[keyof typeof REPAYMENT_BY]

export const sections = [
  'businessInfo',
  'businessOwnership',
  'businessOwner',
  'personalInfo',
  'coOwnerInfo',
  'businessFinance',
  'bankInformation',
]

export const describeYourselfList = [
  // Dont change the order
  {
    value: 'General Contractor',
    next: 'businessInfo.legalName',
  },
  {
    value: 'Sub Contractor',
    next: 'businessInfo.trade',
  },
  {
    value: 'Developer',
    next: 'businessInfo.legalName',
  },
  {
    value: 'Contractor and Developer',
    next: 'businessInfo.legalName',
  },
  {
    value: 'Distributor',
    next: 'businessInfo.legalName',
  },
  {
    value: 'Dealer',
    next: 'businessInfo.legalName',
  },
  {
    value: 'Supplier',
    next: 'businessInfo.legalName',
  },
  {
    value: 'Other',
    next: 'businessInfo.legalName',
  },
]

export const roles = [
  'CEO',
  'CFO',
  'COO',
  'President',
  'Managing Partner',
  'Partner',
  'Manager',
  'Accountant',
  'Head of Finance',
  'Director',
  'Other',
]

export const checkbox = [
  'Appliance Installation',
  'Ceramic and Mosaic Tile',
  'Countertop Installation',
  'Concrete and Foundation',
  'Drywall and Plaster',
  'Electrical, Low Voltage',
  'Elevator',
  'Finish Carpentry, Cabinet and Millwork',
  'Flooring and Floor Covering',
  'Framing and Rough Carpentry Contractor',
  'Glazing, Glass and Mirror',
  'Heating, Ventilating and Air-Conditioning',
  'Insulation and Acoustical',
  'Lathing and Plastering',
  'Landscaping, Paving, Earthwork',
  'Masonry',
  'Painting and Decorating',
  'Plumbing, Pipeline, and Fire Protection',
  'Roofing',
  'Structural Steel, Reinforcing Steel, and Sheet Metal',
  'Solar',
  'Swimming Pool',
  'Other',
]

export const accountingSoftware = [
  'Quickbooks',
  'SAP',
  'FreshBook',
  'NetSuite',
  'Xero',
  'Wave',
  'Viewpoint',
  'Sage 300',
  'Sage 100c',
  'Foundation',
  'Other',
  "I don't use one",
]
