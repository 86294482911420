import { get, post } from '../helpers/caller'

export const transactions = {
  domain: 'transactions',
  transactions(id: string) {
    return post(transactions.domain, transactions.transactions, { id })
  },
  allTransactions(params?: any) {
    return get(transactions.domain, transactions.transactions, { ...params })
  },
}
