import { useToggleState } from './useToggleState'

export const useFocusedErrorProps = (error = '') => {
  const [focused, onFocus, onBlur, setFocus] = useToggleState()
  return {
    focused,
    onFocus,
    onBlur,
    error: !focused && error,
    setFocus,
  }
}
