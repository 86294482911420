export const agingCode = {
  PastDue30: 'PASTDUE30',
  PastDue60: 'PASTDUE60',
  PastDue90: 'PASTDUE90',
  PastDue120: 'PASTDUE120',
  PastDue150: 'PASTDUE150',
  PastDue180: 'PASTDUE180',
  PastDue180Plus: 'PASTDUE180PLUS',
  Processing: 'PROCESSING',
} as const

export type AgingCode = typeof agingCode[keyof typeof agingCode]
