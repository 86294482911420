import i18n, { InitOptions } from 'i18next'
import LocizeBackend from 'i18next-locize-backend'
import { initReactI18next } from 'react-i18next'
import { locale } from '@linqpal/models'
import axios from 'axios'
import get from 'lodash/get'

const hasApiKey = !!process.env.REACT_APP_LOCIZE_API_KEY
i18n.use(initReactI18next)
if (hasApiKey) {
  i18n.use(LocizeBackend)
}

function missingKeyHandler(lngs, ns, key, fallbackValue, updateMissing, o) {
  const locizeApi = axios.create({ baseURL: 'https://api.locize.app' })
  const version = locale.backend.version
  const bundle = i18n.getResource('en', ns, key)
  const value = get(locale.resources.en[ns], key)
  console.log(key)
  console.log(bundle)
  console.log(fallbackValue)
  console.log(value)
  console.log(updateMissing)
  console.log(o)
  let command = ''
  if (!updateMissing) {
    command = 'missing'
  } else if (bundle !== value) {
    command = 'update'
  }

  if (command) {
    locizeApi.post(
      `/${command}/${locale.backend.projectId}/${version}/en/${ns}`,
      { [key]: value || fallbackValue },
      {
        headers: {
          authorization: `${process.env.REACT_APP_LOCIZE_API_KEY}`,
        },
      },
    )
  }
}

export const initLocalization = async () => {
  if (!i18n.isInitialized) {
    const options: InitOptions = {
      lng: 'en',
      fallbackLng: 'en',
      defaultNS: locale.defaultNS,
      ns: locale.namespaces,
      saveMissing: hasApiKey && process.env.REACT_APP_LINQPAL_MODE !== 'prod',
      updateMissing: hasApiKey && process.env.REACT_APP_LINQPAL_MODE !== 'prod',
      missingKeyHandler,
      appendNamespaceToMissingKey: true,
      parseMissingKeyHandler: (key) => {
        const [ns, k] = key.split(':')
        console.log(key, ns, k)
        return get(locale.resources.en[ns], k)
      },
      debug:
        process.env.REACT_APP_LINQPAL_MODE !== 'prod' &&
        process.env.NODE_ENV !== 'test',
      backend: hasApiKey
        ? {
            ...locale.backend,
            apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
          }
        : undefined,
      react: { useSuspense: false },
      resources: !hasApiKey ? locale.resources : undefined,
    }
    await i18n.init(options)
  }
}
export default i18n
