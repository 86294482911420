export const TradeCreditStatus = {
  NotApplied: 'Not Applied',
  IncompleteApplication: 'Incomplete Application',
  GoodStanding: 'Good Standing',
  Approved: 'Approved',
  PendingReview: 'Pending Review',
  BankDisconnected: 'Bank Disconnected',
  BankDataMissing: 'Bank Data Missing',
  AccountOnHold: 'Account On Hold',
  PastDue: 'Past Due',
  Denied: 'Denied',
} as const
