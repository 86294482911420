import { get } from '../helpers/caller'

export const settlements = {
  domain: 'settlements',
  achCardSettlements(params: any) {
    return get(settlements.domain, settlements.achCardSettlements, {
      ...params,
    })
  },
  tradeCreditSettlements(params: any) {
    return get(settlements.domain, settlements.tradeCreditSettlements, {
      ...params,
    })
  },
  arAdvanceSettlements(params: any) {
    return get(settlements.domain, settlements.arAdvanceSettlements, {
      ...params,
    })
  },
  checkSettlementsExist() {
    return get(settlements.domain, settlements.checkSettlementsExist)
  },
}
