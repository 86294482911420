import { Instance, types } from 'mobx-state-tree'
import { dictionaries } from '../..'
import { invoiceStatus } from '../dictionaries'
import { Operation } from './Operation'

const SenderReceiverModel = types.model('SenderReceiverModel', {
  user_id: '',
  company_id: '',
})

const InvoiceModel = types.model('InvoiceModel', {
  status: types.optional(types.string, invoiceStatus.draft),
  expiration_date: types.maybeNull(types.string),
})

export const Notification = types.model('Notification', {
  id: '',
  sender: types.maybeNull(SenderReceiverModel),
  receiver: types.maybeNull(SenderReceiverModel),
  type: types.optional(
    types.enumeration(Object.values(dictionaries.notificationTypes)),
    'INVOICES',
  ),
  title: '',
  content: '',
  isRead: types.optional(types.boolean, false),
  metadata: types.maybeNull(types.frozen()),
  updatedAt: '',
  createdAt: '',
  date: '',
  isViewed: types.optional(types.boolean, false),
})

export const NotificationWithInvoice = types.compose(
  Notification,
  types.model({
    _id: types.string,
    invoice: types.maybeNull(InvoiceModel),
    operation: types.maybeNull(Operation),
  }),
)

export interface INotificationModel
  extends Omit<Instance<typeof Notification>, 'sender' | 'receiver'> {
  sender: Partial<ISenderReceiverModel>
  receiver: Partial<ISenderReceiverModel>
}

export interface ISenderReceiverModel
  extends Instance<typeof SenderReceiverModel> {}
