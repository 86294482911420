export const INITIAL = 'Manual'

export enum EAccountType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export const CARD_ERROR_TYPE = {
  INVALID_CARD: 'invalid_card',
  ADDRESS_MISMATCH: 'address_mismatch',
  CVV_ERROR: 'cvv_error',
  NETWORK_ERROR: 'network_error',
}

export type CardErrorType = typeof CARD_ERROR_TYPE[keyof typeof CARD_ERROR_TYPE]

export const PAY_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export type PayStatus = typeof PAY_STATUS[keyof typeof PAY_STATUS]
