import { get, post } from '../helpers/caller'

export const notification = {
  domain: 'notification',
  list(filter: { isRead: boolean }) {
    return post(notification.domain, notification.list, filter)
  },
  markAsRead(filter = {}) {
    return post(notification.domain, notification.markAsRead, filter)
  },
  markAllAsRead(filter = {}) {
    return get(notification.domain, notification.markAllAsRead, filter)
  },
  markAllAsViewed(filter = {}) {
    return get(notification.domain, notification.markAllAsViewed, filter)
  },
  markAsViewed(filter = {}) {
    return post(notification.domain, notification.markAsViewed, filter)
  },
  markAsViewedById(notificationId: string) {
    return post(notification.domain, notification.markAsViewedById, {
      notificationId,
    })
  },
}
