import { post } from '../helpers/caller'

export const quotes = {
  domain: 'quotes',

  postTransaction(quoteId: string, amount: number) {
    return post(quotes.domain, quotes.postTransaction, { quoteId, amount })
  },

  updateAuthorization(quoteId: string, authorizationDeadline: string) {
    return post(quotes.domain, quotes.updateAuthorization, {
      quoteId,
      authorizationDeadline,
    })
  },
}
