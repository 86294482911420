/**
 * The Medium through which the referral link was shared.
 */
export const referralMedium = {
  queryName: 'referralMedium',
  mobile: 'sms',
  email: 'email',
} as const

export const referralModeUrlAdder = (
  invitationString = '',
  medium: 'sms' | 'email' = referralMedium.email,
) => {
  const separator = invitationString.includes('?') ? '&' : '?'
  return `${invitationString}${separator}${referralMedium.queryName}=${medium}`
}
