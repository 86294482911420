import moment from 'moment'

/**
 * Z Formatted date.
 */
export const currentZDate = () => moment().toDate()

export const formatDate = (
  value: string | Date | null | undefined,
  format = 'MM/DD/YYYY',
) => {
  return value ? moment(value).format(format) : ''
}
