import React from 'react'
import CInput, { CurrencyInputProps } from 'react-currency-input-field'

interface Props extends CurrencyInputProps {
  onChangeText?: (value) => void
}

export default ({
  onChangeText,
  prefix = '$ ',
  decimalScale = 2,
  style,
  ...rest
}: Props) => {
  const _style = { ...style, borderWidth: 0, backgroundColor: 'transparent' }
  return (
    <CInput
      onValueChange={onChangeText}
      prefix={prefix}
      decimalScale={decimalScale}
      style={_style}
      {...rest}
    />
  )
}
