export function fileDownloader(
  url = '',
  header = {},
  fileName = '',
  type = 'application/pdf',
) {
  fetch(url, header)
    .then((r) => r.blob())
    .then((res) => downloadFile(res, fileName, type))
}

export function downloadFile(blob, fileName, type) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type })

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = fileName
  link.click()
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}
