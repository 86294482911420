// eslint-disable-next-line import/no-extraneous-dependencies
import { observer } from 'mobx-react'
import React, { ReactNode } from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'
import { composeStyle } from '../helpers'
import { commonColors } from '../theme'

export const RadioButton = ({
  checked,
  onPress,
  label,
  disabled = false,
  labelLeft = false,
  containerStyle,
  radioProps,
  children,
  testID = '',
}: {
  checked: boolean
  onPress?: () => void
  label?: string | React.ReactNode
  disabled?: boolean
  labelLeft?: boolean
  containerStyle?: ViewStyle
  children?: ReactNode
  radioProps?: RadioProps
  testID?: string
}) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      style={composeStyle(
        { flexDirection: 'row', alignItems: 'center', paddingVertical: 10 },
        containerStyle,
      )}
      onPress={onPress}
      testID={testID}
    >
      {labelLeft && (
        <Text style={{ marginRight: 10, fontSize: 16 }}>{label}</Text>
      )}
      <Radio onPress={onPress} checked={checked} {...radioProps} />
      {!labelLeft}
      <Text style={{ marginLeft: 10 }}>{label}</Text>
      {children}
    </TouchableOpacity>
  )
}
export default RadioButton

type RadioProps = {
  checked?: boolean
  onPress?: () => void
  style?: ViewStyle
  innerStyle?: ViewStyle
  checkedColor?: string
  uncheckedColor?: string
}

export const Radio = ({
  checked,
  onPress,
  style,
  innerStyle,
  checkedColor,
  uncheckedColor,
}: RadioProps) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={composeStyle(
        {
          width: 16,
          height: 16,
          borderRadius: 8,
          borderWidth: 2,
          borderColor: checked
            ? checkedColor || commonColors.primary
            : uncheckedColor || commonColors.primary,
          alignItems: 'center',
          justifyContent: 'center',
        },
        style,
      )}
    >
      {checked && (
        <View
          style={composeStyle(
            {
              backgroundColor: checkedColor || commonColors.accentText,
              height: 8,
              width: 8,
              borderRadius: 4,
            },
            innerStyle,
          )}
        />
      )}
    </TouchableOpacity>
  )
}

export const RadioGroup = observer(
  ({ model, name, options, value, onChange }) => {
    const setValue = (e) => {
      if (onChange) {
        onChange(e)
      }
      if (model && name) {
        model.setValue(name, e)
      }
    }
    if (model && name) {
      value = model[name]
    }
    return (
      <View>
        {options.map((option) => (
          <View key={option.value}>
            <RadioButton
              checked={option.value === value}
              label={option.label}
              onPress={() => setValue(option.value)}
            />
          </View>
        ))}
      </View>
    )
  },
)
