const links = {
  prefixes: ['https://*.bluetape.com', 'http://localhost:3000'],
  config: {
    screens: {},
  },
}
const paths = {
  Auth: {
    _self: '_self',
    SignIn: 'login',
    SignInPassword: 'login-password',
    SignInVerify: 'login/verify',
    SignInForgotPassword: 'forgot-password',
    SignInForgotFinish: 'forgot-send-email',
    SignUpHome: 'signup',
    SignUp: 'signup/login',
    SignUpPassword: 'signup/password',
    SignUpVerify: 'signup/verify',
    SignUpForm: 'signup/form',
  },
  IntegrationPayment: 'integration-payment',
  Tutorial: 'tutorial',
  Home: {
    _self: '_self',
    Dashboard: 'home',
  },
  Console: {
    _self: '_self',

    creditTransactions: {
      _self: 'transactions/credit',
      home: '/',
      draw: 'draw',
    },
    draws: {
      _self: 'draws',
      home: '/',
      draw: 'draw',
    },

    Receivables: {
      _self: 'receivables',
      ReceivableList: {
        _self: 'list',
        Invoices: 'invoices',
        SalesOrders: 'sales-orders',
        Quotes: 'quotes',
      },
    },

    ArAdvanceInvoices: {
      _self: 'ar-advance-invoices',
      ReceivableList: {
        _self: 'list',
        Invoices: 'invoices',
        SalesOrders: 'sales-orders',
        Quotes: 'quotes',
      },
    },

    customers: {
      _self: 'customers',
      home: {
        _self: '/',
        TradeCredit: 'trade-credit',
        ArAdvance: 'ar-advance',
      },
    },

    salesTransactions: {
      _self: 'transactions/sales',
      home: '/',
    },
    settlements: {
      _self: 'settlements',
      home: {
        _self: '/',
        achcard: 'achcard',
        bnpl: 'bnpl',
      },
    },
    statements: {
      _self: 'statements',
      home: { _self: '/' },
    },
    settings: {
      _self: 'settings',
      home: '/',
    },
    refer: {
      _self: 'refer',
      home: '/',
    },
    InvoicesToPay: {
      _self: 'pay-invoices',
      Listing: 'list',
      Details: 'details',
      InvoiceForm: 'upload-invoice',
      Receipt: 'receipt',
      AttachmentPreview: 'preview',
      Invoice: 'invoice',
      Pay: 'pay',
      SelectProject: 'choose-project',
    },
    Payables: {
      _self: 'payables',
      home: '/',
      Vendor: 'vendors/details',
      Invoice: 'invoices/details',
      Quote: 'quotes/details',
      Receipt: 'receipt',
      InvoiceForm: 'upload-invoice',
      AttachmentPreview: 'preview',
      Pay: 'pay',
      SelectProject: 'select-project',
    },
    Credit: {
      _self: 'credit',
      CreditHome: '/',
      LoanListing: 'list',
      LoanDetails: 'draw',
      VirtualCard: 'virtual-card',
      Refer: 'refer',
      CreditApplication: 'request-credit',
      CardDetails: 'card-details',
    },
    Notifications: {
      _self: 'notifications',
    },
    Projects: {
      _self: 'projects',
      List: 'list',
      Details: 'details',
      Form: 'form',
      Summary: 'summary',
      Invoices: 'invoices',
    },
    Accounts: { _self: 'accounts' },
    More: {
      _self: 'more',
      MoreHome: '/',
      Account: 'profile',
      Referrals: 'referrals',
      Wallet: 'accounts',
      Banks: 'Banks',
      Cards: 'Cards',
      ListMethods: 'list',
    },
  },

  CreditApplication: 'request-credit',
  GetPaidApplication: 'get-paid',
  InHouseCreditApplication: 'in-house-credit',
  EmailActions: 'email',
  Invitation: 'invitation',
  Kitten: 'ui-kitten',
  Authorize: 'auth',
  DocumentApproval: 'document-approval',
  LinkCard: 'link-card',
  LinkBank: 'link-bank',
  InvoiceLink: {
    _self: 'i',
    Listing: 'l',
    Details: 'r',
    AttachmentPreview: 'preview',
    Pay: 'pay',
    Invoice: 'invoice',
  },
  PaymentCollectedReceipt: 'receipt',
  NewProject: 'new-project',
}

type Primitive = string | number | bigint | boolean | undefined | symbol

export type NavigationStringPath<T, Prefix = ''> = {
  [K in keyof T]: T[K] extends Primitive | Array<any>
    ? `${string & Prefix}${string & K}`
    :
        | `${string & Prefix}${string & K}`
        | NavigationStringPath<T[K], `${string & Prefix}${string & K}.`>
}[keyof T]

export function navigationStringPathFactory<T, R = string>(): (
  path: NavigationStringPath<T>,
) => R {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (path: NavigationStringPath<T>) => path as unknown as R
}

export const pathFactory = navigationStringPathFactory<typeof paths>()

interface IParams {
  [key: string]: any
}

export function dispatcher(path, params?) {
  const route = path.split('.')
  const pathName = paths[route[0]]._self || paths[route[0]]
  const routeObj: IParams = { name: pathName, params: {} }
  if (route.length === 1) {
    routeObj.params = params
  } else {
    function buildNested(routeIndex, currentPath, currentParams) {
      const routeName = route[routeIndex]
      currentParams.screen =
        currentPath[routeName]._self || currentPath[routeName]
      currentParams.params = {}
      if (routeIndex < route.length - 1) {
        buildNested(
          routeIndex + 1,
          currentPath[routeName],
          currentParams.params,
        )
      } else {
        currentParams.params = { ...params }
      }
    }

    buildNested(1, paths[route[0]], routeObj.params)
  }
  return routeObj
}

function buildLinks(ps, screens) {
  Object.keys(ps).forEach((k) => {
    if (ps[k] instanceof Object) {
      const path = ps[k]._self
      if (!screens.hasOwnProperty(path)) {
        screens[path] = { path: path === '_self' ? '' : path, screens: {} }
      }
      buildLinks(ps[k], screens[path].screens)
    } else if (k !== '_self') {
      const path = ps[k]
      screens[path] = path
    }
  })
}

buildLinks(paths, links.config.screens)
export { links, paths }
