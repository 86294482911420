import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native'
import {
  MD2DarkTheme as PaperDarkTheme,
  MD2LightTheme as PaperDefaultTheme,
} from 'react-native-paper'
import { paddings } from './padding'
import { colors, commonColors } from './colors'
import { borderWidths } from './borderWidths'
import { fonts } from './typography'
import { fontSizes, lineHeights } from './typography'

const commonThemeProperties = {
  paddings,
  fonts,
  fontSizes,
  lineHeights,
  borderWidths,
}

const lightDefault = {
  ...NavigationDefaultTheme,
  ...PaperDefaultTheme,
  ...commonThemeProperties,
  colors: {
    ...NavigationDefaultTheme.colors,
    ...PaperDefaultTheme.colors,
  },
}

const darkDefault = {
  ...NavigationDarkTheme,
  ...PaperDarkTheme,
  ...commonThemeProperties,
  colors: {
    ...NavigationDarkTheme.colors,
    ...PaperDarkTheme.colors,
  },
}

export const light = {
  ...lightDefault,
  roundness: 0,
  colors: {
    ...lightDefault.colors,
    ...commonColors,
    logo: colors.primary.dark,
  },
}

export type AppTheme = typeof light

export const dark: AppTheme = {
  ...darkDefault,
  roundness: 0,
  colors: {
    ...darkDefault.colors,
    ...commonColors,
    logo: colors.primary.light,
  },
}
