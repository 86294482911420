import 'react-native-vector-icons/dist/FontAwesome'
import 'react-native-vector-icons/dist/AntDesign'
import 'react-native-vector-icons/dist/MaterialCommunityIcons'
import 'react-native-vector-icons/dist/MaterialIcons'
import 'react-native-vector-icons/dist/SimpleLineIcons'

import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import FontAwesome5_Brands from 'react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf'
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'
import SimpleLineIcons from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf'
import AntDesign from 'react-native-vector-icons/Fonts/AntDesign.ttf'

const style = document.createElement('style')
Object.entries({
  FontAwesome5_Brands,
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  SimpleLineIcons,
  AntDesign,
})
  .forEach(([name, font]) => {
    const font_styles = `@font-face { src: url('${font}'); font-family: '${name}'; format('truetype'); }`

    style.type = 'text/css'
    if (style.styleSheet) {
      style.styleSheet.cssText = font_styles
    } else {
      style.appendChild(document.createTextNode(font_styles))
    }
    // Inject stylesheet
  })

document.head.appendChild(style)

/*
AntDesign.ttf
Entypo.ttf
EvilIcons.ttf
Feather.ttf
FontAwesome.ttf
FontAwesome5_Brands.ttf
FontAwesome5_Regular.ttf
FontAwesome5_Solid.ttf
Foundation.ttf
Ionicons.ttf
MaterialIcons.ttf
MaterialCommunityIcons.ttf
SimpleLineIcons.ttf
Octicons.ttf
Zocial.ttf
*/
