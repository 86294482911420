import { types } from 'mobx-state-tree'

const IdModel = types.model({
  id: '',
})

export const HistoricData = types.model('HistoricData', {
  'log.level': '',
  '@timestamp': '',
  process: types.map(types.model({ pid: '' })),
  host: types.map(types.model({ hostname: '' })),
  module: '',
  subModule: types.maybe(types.string),
  esc: types.map(types.model({ version: '' })),
  message: '',
  transaction: types.maybe(IdModel),
  span: types.maybe(IdModel),
})
