import { useState } from 'react'

/**
 * Helper hook for creating a simple useState toggle hook.
 *
 * @param toggleOne
 * @param toggleTwo
 * @param initial
 * @returns {[undefined, (function(): void), (function(): void), ((value: (((prevState: undefined) => undefined) | undefined)) => void)]} - [value, setPrimary, setSecondary, set]
 */
export const useToggleState = (
  toggleOne = true,
  toggleTwo = false,
  initial = false,
): [
  boolean,
  () => void,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [toggle, setToggle] = useState(initial),
    setOne = () => setToggle(toggleOne),
    setTwo = () => setToggle(toggleTwo)
  return [toggle, setOne, setTwo, setToggle]
}
