import { Instance, types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'
import { AccountingSettingsStatus } from '../dictionaries'
import { validateEmail } from '../helpers/validations'

export const AccountingSettings = types.compose(
  types
    .model('AccountingSettings', {
      id: '',
      company_id: '',
      pdfUrls: types.array(types.string),
      email: '',
      credit_expire_days: 0,
      status: types.optional(
        types.enumeration([
          AccountingSettingsStatus.APPROVED,
          AccountingSettingsStatus.PENDING,
          AccountingSettingsStatus.IN_PROGRESS,
        ]),
        AccountingSettingsStatus.PENDING,
      ),
    })
    .views((self) => ({
      get isValidEmail() {
        return !self.email || validateEmail(self.email)
      },
      get isShowDataPresent() {
        return (!self.email || validateEmail(self.email)) && !!self.email.length
      },
    })),
  editableModel(),
)

export type IAccountingSettingsModel = Instance<typeof AccountingSettings>
