import { post, get } from '../helpers/caller'
import { referralMedium } from '../dictionaries'

export const referral = {
  domain: 'referral',
  addReferral(referralCode: string, referralMode: string) {
    /**
     * referral Mode is weather it's email or sms.
     */
    return post(referral.domain, referral.addReferral, {
      referralCode,
      [referralMedium.queryName]: referralMode,
    })
  },
  getReferral() {
    return get(referral.domain, referral.getReferral)
  },
}
