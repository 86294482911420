import React, { useEffect, useState } from 'react'
import {
  TextInput as NativeInput,
  View,
  GestureResponderEvent,
  ActivityIndicator,
} from 'react-native'
import { List, TextInput } from 'react-native-paper'
import { composeStyle, createEditable, webSpecificStyle } from '../helpers'
import { useDebounce } from '../hooks'
import { useBaseInputStyle } from '../ions/style'
import CollapsableView from './CollapsableView'
import { commonColors } from '../theme'
import { CloseCircle } from './CloseCircle'

type PropTypes = {
  isCustomerModel?: boolean
  style?: any
  value?: string
  apiSearchFn: (value: string) => Promise<{ items: any[] }>
  displayKey: string
  valueKey: string
  onSelection?: (a) => void
  customSettings?: any
  displayFn?: (a) => ''
  options?: any
  onChange?: (a) => void
  collapsableViewHeading?: React.ReactNode
  autoCompleteType?: string
  SearchResultItem?: React.FunctionComponent<{
    option: string
    onPress: (a) => void
  }> | null
  isSearchBar?: boolean
  Search?: React.FC<{ style: any }>
  expanded?: boolean
  labelLowercase?: boolean
  label: string
  setExpanded: (a) => void
  onChangeText?: (v) => void
  nativeProps?: any
  extra?: React.ReactNode
  error?: any
  ifNotFound?: (a, b?) => void
  testID?: string
}

export const AutoCompleteInput = ({
  isCustomerModel = false, // Added this flag to send the customer selected object to customer store
  style,
  value,
  nativeProps,
  options = [],
  apiSearchFn,
  displayKey = 'name',
  displayFn,
  valueKey = 'id',
  onChange,
  onSelection,
  extra,
  error,
  expanded,
  setExpanded,
  ifNotFound,
  labelLowercase = false,
  label,
  isSearchBar = false,
  Search,
  SearchResultItem = null,
  collapsableViewHeading = null,
  autoCompleteType = 'off',
  customSettings,
  onChangeText = () => {},
  ...props
}: PropTypes) => {
  const { input, inputBackgroundColor } = useBaseInputStyle()
  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [showResults, setShowResults] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!inputValue) {
      ifNotFound && ifNotFound(false)
      setShowResults(false)
    } else if (selectedItem && isSearchBar) {
      // even when there is a selected item, show the options if the user searches again
      setShowResults(true)
    }
  }, [inputValue, ifNotFound, isSearchBar, selectedItem])

  useEffect(() => {
    let cancelled = false
    setAutocompleteOptions([])
    if (!value) {
      setSelectedItem(null)
    } else {
      if (options.length > 0) {
        const selectedOption = options.find(
          (option) => option[valueKey] === value,
        )
        setAutocompleteOptions(options)
        setSelectedItem(selectedOption)
      } else {
        apiSearchFn(value).then((res) => {
          if (!cancelled) {
            setAutocompleteOptions(res.items)
            setSelectedItem(res.items[0])
            if (onChange) {
              onChange(res.items?.[0]?.[valueKey])
            }
          }
        })
      }
    }
    return () => {
      cancelled = true
    }
    // with all deps it goes into update loop hence disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    let v = ''
    if (selectedItem) {
      v = selectedItem[displayKey]
    } else {
      if (onSelection) {
        onSelection(null)
      }
    }
    !isSearchBar && setInputValue(v) // if its a search bar then dont show the choosen value in it
  }, [selectedItem, displayKey, isSearchBar, onSelection])

  useEffect(() => {
    setAutocompleteOptions([])
    if (apiSearchFn && debouncedSearchTerm) {
      setLoading(true)
      apiSearchFn(debouncedSearchTerm)
        .then((res) => {
          if (!res.items.length) {
            ifNotFound && ifNotFound(true, debouncedSearchTerm)
          } else {
            ifNotFound && ifNotFound(false, debouncedSearchTerm)
          }
          setAutocompleteOptions(res.items)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    } else if (options.length > 0) {
      setAutocompleteOptions(
        options
          .filter((option) =>
            option[displayKey]
              .toLowerCase()
              .startsWith(debouncedSearchTerm.toLowerCase()),
          )
          .slice(0, 5),
      )
    }
    // with all deps it goes into update loop hence disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, apiSearchFn])

  const onSelect = (option) => {
    setSelectedItem(option)
    if (onChange) {
      // added isCustomerModel condition because of customer screen usage
      if (isCustomerModel) {
        onChange(option)
      } else {
        onChange(option[valueKey])
      }
    }
    if (onSelection) {
      onSelection(option)
    }
  }

  return (
    <View
      style={composeStyle(inputBackgroundColor, {
        marginTop: 15,
        paddingBottom: 1,
      })}
    >
      <TextInput
        underlineColor="transparent"
        onFocus={() => {
          setFocused(true)
          setExpanded(true)
        }}
        onBlur={() => {
          if (!expanded) {
            setFocused(false)
            setExpanded(false)
          }
        }}
        onChangeText={(val) => {
          setInputValue(val)
          onChangeText(val)
        }}
        editable={isSearchBar || !selectedItem}
        value={inputValue}
        style={composeStyle(inputBackgroundColor, { flex: 1 })}
        theme={{ roundness: 4 }}
        dense
        render={(shadowProps) => (
          <>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                height: 55,
                borderColor: error
                  ? commonColors.accent
                  : focused
                  ? commonColors.accentText
                  : '#9BAEBC',
                borderWidth: 1,
                borderRadius: 4,
              }}
            >
              {isSearchBar && (
                <View style={{ marginLeft: 10, marginRight: -5 }}>
                  {Search && <Search style={{ height: 20, width: 20 }} />}
                </View>
              )}
              <NativeInput
                {...shadowProps}
                testID={`${label}_input`}
                style={composeStyle(
                  input,
                  webSpecificStyle({
                    outline: 'none',
                    paddingTop: 0,
                    caretColor: commonColors.accentText,
                  }),
                  { fontSize: 15 },
                )}
                autoComplete={autoCompleteType}
                {...nativeProps}
                placeholder={
                  (focused || value) && !labelLowercase
                    ? label?.toUpperCase()
                    : label
                }
              />
              {!isSearchBar && (
                <CloseCircle
                  onChangeText={(v) => {
                    setInputValue(v)
                    setSelectedItem(null)
                  }}
                  disabled={!inputValue}
                  value={inputValue}
                />
              )}
              {loading ? (
                <ActivityIndicator style={{ marginRight: 10 }} />
              ) : null}
            </View>
            {((!selectedItem && autocompleteOptions.length > 0) ||
              showResults) && (
              <CollapsableView
                style={{
                  borderWidth: 1,
                  borderColor: commonColors.accentText,
                  borderRadius: 4,
                }}
                collapsed={!expanded}
              >
                {autocompleteOptions.length !== 0 && collapsableViewHeading}
                {autocompleteOptions.map((option) => {
                  const onPress = (e?: GestureResponderEvent) => {
                    setInputValue('')
                    e?.preventDefault()
                    e?.stopPropagation()
                    onSelect(option)
                  }
                  return SearchResultItem ? (
                    <SearchResultItem option={option} onPress={onPress} />
                  ) : (
                    <List.Item
                      onPress={onPress}
                      key={option[valueKey]}
                      title={displayFn ? displayFn(option) : option[displayKey]}
                    />
                  )
                })}
                {extra}
              </CollapsableView>
            )}
          </>
        )}
        {...props}
      />
    </View>
  )
}

export default createEditable({ Component: AutoCompleteInput })
