import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { PopupMessageContained } from '../../ui/molecules/PopupMessageContained'
import { useTranslation } from 'react-i18next'
import { SnackBar } from '../../screens/Supplier/Account/Sidebar/AccountSidebarView/AddCustomerPaymentMethod/SnackBar'

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

let MessageProvider

let { Provider } = (MessageProvider = React.createContext())

export const useMessage = () => React.useContext(MessageProvider)

export default ({ children }) => {
  const { t } = useTranslation('errors')
  const [msgObject, setMsgObject] = useState({ type: 'info', message: '' })
  const [customPopup, setCustomPopup] = useState(false)

  const setMessage = useCallback(
    (type, message) => {
      setMsgObject({ type, message })
    },
    [setMsgObject],
  )

  const setCustom = useCallback(
    (comp) => setCustomPopup(comp),
    [setCustomPopup],
  )

  useEffect(() => {
    const index = axios.interceptors.response.use(undefined, (error) => {
      if (error.code && !error.silent) {
        if (error.code !== 'request-cancelled')
          setMessage(MESSAGE_TYPE.ERROR, t(error.code, error))
      } else if (error.message && !error.silent) {
        setMessage(MESSAGE_TYPE.ERROR, error.message)
      } else {
        const { response, config, message, silent } = error
        if (message) {
          !silent && setMessage(MESSAGE_TYPE.ERROR, message)
          return Promise.reject(error)
        }
        if (!response) {
          !silent && setMessage(MESSAGE_TYPE.ERROR, t('network-error'))
          return Promise.reject(error)
        }
        if (!config?.params?.silent) {
          const { data } = response
          const message = (data && data.message) || error.message || error
          if (message && response.status !== 460) {
            setMessage(MESSAGE_TYPE.ERROR, message.toString())
          }
        }
      }
      return Promise.reject(error)
    })

    return () => axios.interceptors.response.eject(index)
  }, [])

  const value = useMemo(
    () => ({ setMessage, msgObject, setCustom }),
    [setMessage, msgObject],
  )

  return (
    <Provider value={value}>
      <>
        {children}
        {customPopup ? (
          <SnackBar
            visible={!!msgObject.message}
            type={msgObject.type}
            message={msgObject.message}
            onDismiss={() => {
              setMessage({ type: MESSAGE_TYPE.INFO, message: '' })
              setCustom(false)
            }}
          />
        ) : (
          <PopupMessageContained
            msgObject={msgObject}
            setMessage={setMsgObject}
          />
        )}
      </>
    </Provider>
  )
}
